import React, { useState } from 'react';

import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const InfoIcon = styled.div`
  position: relative;
  cursor: pointer;
  top: 1px;
  left: 3px;
`;

const InfoPopup = styled(Styled.FlexColumn)`
  color: ${Colors.GRAY_TWO};
  position: relative;
  top: 30px;
  right: 160px;
  width: 300px;
  background-color: white;
  box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.25);
  font-weight: 300;
  padding: 20px 20px 15px 20px;
  z-index: 10;
  ${TypeStyles.LABEL_TEXT}
`;

const Triangle = styled.div`
background-color: white;
position: relative
top: 40px;
right: 20px;
  height: 20px;
  width: 20px;
  box-shadow: -1px -3px 10px -2px rgba(0, 0, 0, 0.25);
  transform: rotate(45deg);
`;

const Warning = styled.div`
  position: relative;
  top: 5px;
  left: 2px;
`;

export type Position = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

type Props = {
  color?: string;
  warning?: boolean;
  position?: Position;
  children?: React.ReactNode;
};

const Tooltip = ({ color, warning, position, children }: Props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <InfoIcon
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {warning ? (
          <Warning>
            <Styled.WarningIcon />
          </Warning>
        ) : (
          <Styled.InfoIcon color={color} />
        )}
      </InfoIcon>
      {isShown && (
        <div
          style={{
            position: 'relative',
            top: `${position?.top}`,
            bottom: `${position?.bottom}`,
            left: `${position?.left}`,
            right: `${position?.right}`,
          }}
        >
          <Triangle />
          <InfoPopup>{children}</InfoPopup>
        </div>
      )}
    </>
  );
};

export default Tooltip;
