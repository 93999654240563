import {
  EventsMenu,
  MachinesMenu,
  PrioritiesMenu,
} from '../components/ui/FilterMenu';
import Functions, { apiFilterOptions, filter } from '../util/UtilityFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { TimeMenu, VerificationMenu } from '../components/ui/RadioMenu';

import Error from './Error';
import EventList from '../components/event/EventList';
import Options from '../util/Options';
import { PAGE_LENGTH } from '../components/ui/ShowingText';
import Styled from '../styles/Styles';
import { fetchAllMachines } from '../api/machine';
import { fetchClinicIds } from '../api/patient';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

type Props = {
  machineNumber?: string;
  highPriority?: boolean;
  timeFilter?: string;
  unverified?: boolean;
};

const { TimeFilterOptions } = Options;

const Events = ({
  machineNumber,
  highPriority,
  timeFilter,
  unverified,
}: Props) => {
  const navigate = useNavigate();
  const { data, loading, error } = usePromise(fetchAllMachines, [navigate]);

  const machines = data?.machines?.map((event) => event.machineName);
  // const machineOptions = machineData.error
  //   ? dummyMachineOptions
  //   : machines && Options.getOptions(machines);
  const machineOptions = machines && Options.getOptions(machines);

  const resetPage = JSON.parse(localStorage.getItem('resetPage'));
  const savedPageNumber =
    !resetPage && Number(localStorage.getItem('eventsPage'));
  const [pageNumber, setPageNumber] = useState(savedPageNumber || 0);
  const [pageLength, setPageLength] = useState(
    JSON.parse(localStorage.getItem('eventsPageLength')) || PAGE_LENGTH[0]
  );
  if (resetPage) {
    localStorage.setItem('resetPage', 'false');
  }

  const savedEventsTimeFilter = machineNumber
    ? TimeFilterOptions[timeFilter]
    : highPriority || unverified
    ? undefined
    : Number(localStorage.getItem('eventsTimeFilter')) !== undefined
    ? Number(localStorage.getItem('eventsTimeFilter'))
    : 1;
  const savedApiFilter: filter = JSON.parse(
    localStorage.getItem('eventsFilter')
  );
  if (savedApiFilter?.eventTime) {
    savedApiFilter.eventAfter = savedApiFilter.eventTime;
  }
  if (
    savedApiFilter &&
    Functions.timeFilterToApiDate(savedEventsTimeFilter) !==
      savedApiFilter?.eventAfter &&
    !(savedApiFilter?.eventBefore && savedApiFilter?.eventAfter)
  ) {
    savedApiFilter.eventAfter = Functions.timeFilterToApiDate(
      savedEventsTimeFilter
    );
  }
  const defaultApiFilter: filter = {
    verified: false,
    sortDescending: true,
    sortBy: 'priority',
    priority: ['high', 'med', 'low'],
    eventAfter: Functions.timeFilterToApiDate(TimeFilterOptions.PAST_WEEK),
  };
  const initialApiFilter: filter = machineNumber
    ? {
        ...defaultApiFilter,
        machine: [machineNumber],
        eventAfter: Functions.timeFilterToApiDate(
          TimeFilterOptions[timeFilter]
        ),
      }
    : highPriority
    ? { ...defaultApiFilter, priority: ['high'], eventAfter: undefined }
    : unverified
    ? { ...defaultApiFilter, eventAfter: undefined }
    : savedApiFilter || defaultApiFilter;
  const savedMappingSearchTerm = localStorage.getItem(
    'eventsMappingSearchTerm'
  );
  const initialSavedMappingSearchTerm = savedMappingSearchTerm
    ? [savedMappingSearchTerm]
    : undefined;

  const defaultOptions: apiFilterOptions = {
    verified: 2,
    priority: Options.priorityOptions.map(() => true),
    time: 1,
    machine: [],
    event: Options.eventOptions.map(() => true),
  };
  let initialApiFilterOptions: apiFilterOptions =
    (machineOptions !== undefined &&
      Functions.getInitialApiFilterOptions(
        initialApiFilter,
        machineOptions,
        savedEventsTimeFilter
      )) ||
    defaultOptions;
  const [apiFilter, setApiFilter] = useState(initialApiFilter);

  const [searchTerm, setSearchTerm] = useState(savedMappingSearchTerm || '');
  const [mappingSearchTerm, setMappingSearchTerm] = useState<string[]>(
    initialSavedMappingSearchTerm
  );
  const {
    data: clinicIds,
    loading: clinicIdsLoading,
    error: clinicIdsError,
  } = usePromise(fetchClinicIds, [mappingSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearch = () => {
    const mrns = Functions.extractMRNs(searchTerm);
    setMappingSearchTerm(mrns || (searchTerm ? [searchTerm] : undefined));
    if (mrns) {
      setSearchTerm(String(mrns));
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (clinicIds !== undefined && !isFirstRun.current) {
      const list = clinicIds.join();
      const newFilter = {
        ...apiFilter,
        clinicPatientId: list,
      };
      if (!mappingSearchTerm && !searchTerm) {
        delete newFilter.clinicPatientId;
      }
      setApiFilter(newFilter);
    }
  }, [clinicIds]);
  const handleVerifiedChange = (checked: number) => {
    const newFilter: filter = Functions.handleVerifiedChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
  };
  const handlePriorityChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handlePriorityChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
  };
  const isCustom = initialApiFilter.eventAfter && initialApiFilter.eventBefore;
  const initialCustomStartDate = isCustom
    ? moment(initialApiFilter.eventAfter, 'YYYY-MM-DD').toDate()
    : new Date();
  const [customStartDate, setCustomStartDate] = useState(
    initialCustomStartDate
  );
  const initialCustomEndDate = isCustom
    ? moment(initialApiFilter.eventBefore, 'YYYY-MM-DD').toDate()
    : new Date();
  const [customEndDate, setCustomEndDate] = useState(initialCustomEndDate);
  const [timeFilterOption, setTimeFilterOption] = useState(
    savedEventsTimeFilter
  );
  const handleTimeChange = (checked: number) => {
    setTimeFilterOption(checked);
    localStorage.setItem('eventsTimeFilter', String(checked));
    const newFilter: filter = Functions.handleTimeChange(
      apiFilter,
      checked,
      customStartDate,
      customEndDate
    );
    setApiFilter({ ...newFilter });
  };
  const handleMachineChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handleMachineChange(
      apiFilter,
      checked,
      machineOptions
    );
    setApiFilter({ ...newFilter });
  };
  const handleEventChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handleEventChange(apiFilter, checked);
    setApiFilter({ ...newFilter });
  };
  const handleSetSortBy = (sortBy: string) => {
    const newFilter: filter = Functions.handleSetSortBy(apiFilter, sortBy);
    setApiFilter({ ...newFilter });
  };

  const handleEventRefresh = () => {
    setApiFilter({ ...apiFilter });
  };
  useEffect(() => {
    if (timeFilterOption === TimeFilterOptions.CUSTOM) {
      handleTimeChange(TimeFilterOptions.CUSTOM);
    }
  }, [customStartDate, customEndDate]);
  useEffect(() => {
    if (!isFirstRun.current) {
      setPageNumber(0);
    }
    localStorage.setItem('eventsFilter', JSON.stringify(apiFilter));
  }, [apiFilter]);

  useEffect(() => {
    localStorage.setItem(
      'eventsMappingSearchTerm',
      mappingSearchTerm?.join() || ''
    );
    if (!mappingSearchTerm && !searchTerm) {
      const newFilter: filter = apiFilter;
      delete newFilter.clinicPatientId;
      setApiFilter({ ...newFilter });
    }
  }, [mappingSearchTerm]);

  useEffect(() => {
    if (machineOptions !== undefined) {
      initialApiFilterOptions = Functions.getInitialApiFilterOptions(
        initialApiFilter,
        machineOptions,
        savedEventsTimeFilter
      );
      setTimeFilterOption(initialApiFilterOptions.time);
    }
  }, [machineOptions]);

  useEffect(() => {
    localStorage.setItem('eventsPage', String(pageNumber));
  }, [pageNumber]);

  const { verifiedOptions, priorityOptions, timeOptions, eventOptions } =
    Options;

  useEffect(() => {
    if (isFirstRun.current && machineOptions) {
      isFirstRun.current = false;
      return;
    }
  });

  const handlePageLengthChange = (num: number) => {
    setPageLength(num);
    localStorage.setItem('eventsPageLength', String(num));
    setPageNumber(0);
  };

  return (
    <>
      {machineOptions && (
        <EventList
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageLength={pageLength}
          apiFilter={apiFilter}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          handleSearch={handleSearch}
          handleSetSortBy={handleSetSortBy}
          handleEventRefresh={handleEventRefresh}
          handlePageLengthChange={(num) => handlePageLengthChange(num)}
        >
          <VerificationMenu
            options={verifiedOptions}
            isVerified
            onChange={handleVerifiedChange}
            initialFilter={initialApiFilterOptions.verified}
          />
          <PrioritiesMenu
            options={priorityOptions}
            onChange={handlePriorityChange}
            initialFilter={initialApiFilterOptions.priority}
          />
          <TimeMenu
            options={timeOptions}
            onChange={handleTimeChange}
            initialFilter={initialApiFilterOptions.time}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
          />
          <MachinesMenu
            options={machineOptions}
            onChange={handleMachineChange}
            initialFilter={initialApiFilterOptions.machine}
          />
          <EventsMenu
            options={eventOptions}
            onChange={handleEventChange}
            initialFilter={initialApiFilterOptions.event}
          />
        </EventList>
      )}
      {loading && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
      {error && <Error />}
    </>
  );
};

export default Events;
