import React, { useState } from 'react';

import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import { RealTimeData } from '../../types/RealTime';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import { fetchFeatureFlags } from '../../api/auth';
import moment from 'moment';
import styled from 'styled-components';
import { usePromise } from '../../util/api';

const CardBorder = styled(Styled.BorderBox)`
  width: 500px;
  margin: 20px 5px 5px 5px;
  background-color: ${Colors.GRAY_EIGHT};
  ${TypeStyles.LABEL_TEXT}
`;

const TitleBlock = styled(Styled.FlexRow)`
  background-color: ${Colors.REAL_TIME_ORANGE};
  color: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  ${TypeStyles.HEADING_TWO}
`;

const PatientTreatmentBlock = styled(Styled.FlexRow)`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  ${TypeStyles.HEADING_TWO}
`;

const RealTimeBlock = styled(Styled.FlexRow)`
  justify-content: space-between;
`;

const RealTimeItem = styled(Styled.FlexColumn)`
  justify-content: center;
  align-items: center;
  margin: 40px 20px 0px 20px;
`;

const RealTimePosition = styled(Styled.FlexRow)<{ withinTolerance?: boolean }>`
  color: ${(props) => (props.withinTolerance ? 'green' : 'red')};
  font-weight: 500;
  ${TypeStyles.HEADING_TWO}
`;

const LastUpdated = styled(Styled.FlexRow)`
  justify-content: flex-end;
  color: ${Colors.GRAY_FOUR};
  font-weight: 500;
  margin: 10px 5px 5px 5px;
`;

const CouchPositionNames = ['vrt', 'lat', 'lng'];

type Props = {
  realTimeState: RealTimeData;
  patientInfo?: PatientNameAndMrn;
};

const RealTimeCard = ({ realTimeState, patientInfo }: Props) => {
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];
  const { machineName, siteName, couch, timestamp } = realTimeState;
  // look into adding lastUpdated as state and removing justNow - test to see if there are any performance differences
  const [justNow, setJustNow] = useState(new Date());
  const lastUpdated = Functions.getLastRealTimeUpdated(timestamp, justNow);
  let interval;
  const start = moment(timestamp);
  const end = moment(justNow);
  const lessThanMinute = end.diff(start, 'minutes') < 1;
  const lessThanHour =
    end.diff(start, 'minutes') > 0 && end.diff(start, 'hours') < 1;
  const moreThanHour = end.diff(start, 'hours') > 0;

  if (lessThanMinute) {
    clearInterval(interval);
    interval = setInterval(() => {
      setJustNow(new Date());
    }, 1000);
  }
  if (lessThanHour) {
    clearInterval(interval);
    interval = setInterval(() => {
      setJustNow(new Date());
    }, 60000);
  }
  if (moreThanHour) {
    clearInterval(interval);
    interval = setInterval(() => {
      setJustNow(new Date());
    }, 3600000);
  }

  return (
    <CardBorder>
      <TitleBlock>{machineName}</TitleBlock>
      <PatientTreatmentBlock>
        {disableMappingService
          ? 'Smith, John'
          : patientInfo
          ? `${patientInfo.lastName || '-'}, ${`${
              patientInfo.firstName || '-'
            }${patientInfo.middleName ? ` ${patientInfo.middleName}` : ''}`}`
          : '-, -'}{' '}
        <Styled.Divider /> {siteName}
      </PatientTreatmentBlock>
      <RealTimeBlock>
        {CouchPositionNames.map((name) => (
          <RealTimeItem>
            <Styled.FlexRow>{name.toUpperCase()}</Styled.FlexRow>
            <RealTimePosition withinTolerance={couch?.[name].withinTolerance}>
              {couch?.[name].value}
            </RealTimePosition>
            <Styled.FlexRow style={{ color: `${Colors.GRAY_FOUR}` }}>
              {couch?.[name].lowerBound !== null &&
              couch?.[name].lowerBound !== undefined
                ? couch?.[name].lowerBound
                : '-'}
              {' | '}
              {couch?.[name].upperBound !== null &&
              couch?.[name].upperBound !== undefined
                ? couch?.[name].upperBound
                : '-'}
            </Styled.FlexRow>
          </RealTimeItem>
        ))}
      </RealTimeBlock>
      <LastUpdated>{lastUpdated}</LastUpdated>
    </CardBorder>
  );
};

export default RealTimeCard;
