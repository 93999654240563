import React, { useState } from 'react';
import { handleSaveComment, handleVerify } from '../../api/event';

import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import CommentModal from '../ui/CommentModal';
import { Event } from '../../types/Event';
import Functions from '../../util/UtilityFunctions';
import Options from '../../util/Options';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import PriorityBubble from '../ui/PriorityBubble';
import Styled from '../../styles/Styles';
import { reverse } from 'named-urls';
import routes from '../../routes';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Bold = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

type Props = {
  event: Event;
  includePatient?: boolean;
  eventsPageButtons?: boolean;
  isChecked?: boolean;
  checkboxClick?: () => void;
  viewButtonClick?: () => void;
  handleEventRefresh?: () => void;
  patientInfo?: PatientNameAndMrn;
  disableMappingService?: boolean;
  handleNotesClick?: (id: string, date: Date, index: number) => void;
  isHighlighted?: boolean;
  index?: number;
};

const EventRow = ({
  event,
  includePatient,
  eventsPageButtons,
  isChecked,
  checkboxClick,
  viewButtonClick,
  handleEventRefresh,
  patientInfo,
  disableMappingService,
  handleNotesClick,
  isHighlighted,
  index,
}: Props) => {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const { ruleOptions } = Options;

  const handleVerifyEvent = () => {
    handleVerify([`${event.id}`], handleEventRefresh, event.verified);
  };

  const [isModalShown, setIsModalShown] = useState(false);
  const handleModalClose = () => {
    setIsModalShown(false);
  };
  const handleSave = (comment: string) => {
    const afterSave = () => {
      setIsModalShown(false);
      handleEventRefresh();
    };
    handleSaveComment(`${event.id}`, comment, afterSave, navigate);
  };

  return (
    <>
      <Styled.StyledLink
        to={
          !buttonHover &&
          eventsPageButtons &&
          `${reverse(`${routes.treatments}`, {
            patientID: event.patientId,
            rtPlanID: event.rtPlanId,
            eventID: event.id,
          })}`
        }
      >
        <Styled.Row
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          onClick={() =>
            !buttonHover && !eventsPageButtons && viewButtonClick()
          }
          style={{
            minWidth: `${eventsPageButtons ? '1730px' : '1420px'}`,
            backgroundColor: `${
              isHighlighted || isShown ? Colors.HOVER_SECONDARY : 'white'
            }`,
          }}
        >
          <Styled.ItemType
            theme={{ flexBasis: '45px' }}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
          >
            <Checkbox isChecked={isChecked} onClick={checkboxClick} />
          </Styled.ItemType>
          <Styled.ItemType theme={{ flexBasis: '70px' }}>
            <PriorityBubble priority={event.priority} />
          </Styled.ItemType>
          {includePatient && (
            <>
              <Styled.ItemType
                theme={{ flexBasis: '200px' }}
                style={{ wordBreak: 'break-all' }}
              >
                {disableMappingService
                  ? `${event.lastName}, ${event.firstName}`
                  : Functions.formatPatientName(patientInfo)}
              </Styled.ItemType>
              <Styled.ItemType theme={{ flexBasis: '80px' }}>
                {disableMappingService
                  ? event.mrn
                  : patientInfo
                  ? patientInfo.mrn
                  : '-'}
              </Styled.ItemType>
            </>
          )}
          <Styled.ItemType theme={{ flexBasis: '100px' }}>
            {event.machineName}
          </Styled.ItemType>
          <Styled.ItemType theme={{ flexBasis: '200px' }}>
            {Functions.dateToString(event.eventTime)}
          </Styled.ItemType>
          <Styled.ItemType theme={{ flexBasis: '200px' }}>
            {ruleOptions[event.ruleType].name}
          </Styled.ItemType>
          <Styled.DescriptionItemType
            isShown={isShown}
            eventsPageButtons={eventsPageButtons}
          >
            {event.ruleType.includes('Couch') ? (
              <>
                {Functions.boldDescription(event.description).map((word) => {
                  return word[1] ? (
                    <Bold>{word[0]}&nbsp;</Bold>
                  ) : (
                    <span>{word[0]}&nbsp;</span>
                  );
                })}
              </>
            ) : (
              event.description
            )}
            {event.note ? (
              <Styled.FlexRow>
                <br />
                <Styled.DescriptionItemType style={{ fontStyle: 'italic' }}>
                  Notes: {event.note}
                </Styled.DescriptionItemType>
              </Styled.FlexRow>
            ) : (
              event.additionalDetails?.map((note) => (
                <Styled.FlexRow>
                  <br />
                  <Styled.DescriptionItemType style={{ fontStyle: 'italic' }}>
                    {note}
                  </Styled.DescriptionItemType>
                </Styled.FlexRow>
              ))
            )}
          </Styled.DescriptionItemType>

          <Styled.CommentItemType
            isShown={isShown}
            eventsPageButtons={eventsPageButtons}
          >
            {event.comment}
          </Styled.CommentItemType>

          {isShown &&
            (eventsPageButtons ? (
              <Styled.FlexRow
                style={{
                  position: 'sticky',
                  right: '.5px',
                  height: '55px',
                  alignItems: 'center',
                  backgroundColor: Colors.MOUSEOVER_BLUE,
                }}
              >
                <Styled.FlexRow
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <Styled.ButtonBox onClick={() => setIsModalShown(true)}>
                    <Styled.CommentIcon />
                  </Styled.ButtonBox>
                </Styled.FlexRow>
                <Styled.FlexRow
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <Styled.StyledLink
                    to={`${reverse(`${routes.treatments}`, {
                      patientID: event.patientId,
                    })}?events=true`}
                  >
                    <Styled.ButtonBox>Patient</Styled.ButtonBox>
                  </Styled.StyledLink>
                  <Styled.ButtonBox
                    onClick={() => {
                      handleNotesClick(
                        event?.clinicPatientId,
                        event?.eventTime,
                        index
                      );
                    }}
                  >
                    Notes
                  </Styled.ButtonBox>
                </Styled.FlexRow>
                <Styled.FlexRow
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <Styled.VerifyButton
                    style={{ height: '28px', width: '64px' }}
                    onClick={handleVerifyEvent}
                    verified={event.verified}
                  >
                    {event.verified ? 'Unverify' : 'Verify'}
                  </Styled.VerifyButton>
                </Styled.FlexRow>
              </Styled.FlexRow>
            ) : (
              <></>
            ))}
          {event.verified && (
            <Styled.FlexRow style={{ height: '52px', alignItems: 'center' }}>
              <Styled.CheckCircle />
            </Styled.FlexRow>
          )}
        </Styled.Row>
      </Styled.StyledLink>
      {isModalShown && (
        <CommentModal
          currentComment={event.comment}
          onCloseClick={handleModalClose}
          onSaveClick={handleSave}
        />
      )}
    </>
  );
};

export default EventRow;
