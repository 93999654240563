import styled, { css } from 'styled-components';

import Colors from '../styles/Colors';
import { Link } from 'react-router-dom';
/* eslint-disable react/no-unknown-property */
import React from 'react';
import { ToastOptions } from 'react-toastify';
import TypeStyles from '../styles/TypeStyles';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Bubble = styled(FlexRow as any)`
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box
  min-width: 22px;
  padding: 0px 3px 0px 3px;
  height: 22px;
  color: white;
  background-color: ${(props) => props.color || 'white'};
  margin-right: 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const BorderBox = styled.a`
  box-sizing: border-box;
  border: 1px solid ${Colors.GRAY_SIX};
  display: flex;
  flex-direction: column;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 17px 79px);
`;

const ArrowUp = () => (
  <svg width="6px" height="11px" viewBox="0 0 6 11" version="1.1">
    <g
      id="Screens"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="style-guide"
        transform="translate(-537.000000, -5507.000000)"
        fill={Colors.BLUE_ONE}
      >
        <g id="Group-49" transform="translate(100.000000, 5439.000000)">
          <g id="Group-47" transform="translate(360.000000, 39.000000)">
            <g
              id="Icon-/-Sort"
              transform="translate(80.000000, 34.500000) scale(1, -1) translate(-80.000000, -34.500000) translate(77.000000, 29.000000)"
            >
              <path
                d="M3.78228468,7.44020536 L5.45993338,7.44020536 C5.63534384,7.44020536 5.77754211,7.58240363 5.77754211,7.75781409 C5.77754211,7.83790139 5.74728635,7.91503225 5.69283409,7.97375982 L3.23155423,10.628287 C3.11229069,10.7569145 2.9113353,10.7645055 2.78270779,10.645242 C2.77684473,10.6398058 2.77118904,10.6341501 2.76575281,10.628287 L0.304472948,7.97375982 C0.185209412,7.84513231 0.192800414,7.64417691 0.321427924,7.52491338 C0.380155498,7.47046112 0.457286356,7.44020536 0.537373658,7.44020536 L2.21502181,7.44020536 C2.2148541,7.43353382 2.21476974,7.42684157 2.21476974,7.42012962 L2.21476974,1.0438835 C2.21476974,0.610956597 2.56572634,0.26 2.99865324,0.26 C3.43158015,0.26 3.78253674,0.610956597 3.78253674,1.0438835 L3.78253674,7.42012962 C3.78253674,7.42684157 3.78245239,7.43353382 3.78228468,7.44020536 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ArrowDown = () => (
  <svg width="6px" height="11px" viewBox="0 0 6 11" version="1.1">
    <g
      id="Screens"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="style-guide"
        transform="translate(-357.000000, -5507.000000)"
        fill={Colors.BLUE_ONE}
      >
        <g id="Group-49" transform="translate(100.000000, 5439.000000)">
          <g id="Group-47" transform="translate(180.000000, 39.000000)">
            <g id="Icon-/-Sort" transform="translate(77.000000, 29.000000)">
              <path
                d="M3.78228468,7.44020536 L5.45993338,7.44020536 C5.63534384,7.44020536 5.77754211,7.58240363 5.77754211,7.75781409 C5.77754211,7.83790139 5.74728635,7.91503225 5.69283409,7.97375982 L3.23155423,10.628287 C3.11229069,10.7569145 2.9113353,10.7645055 2.78270779,10.645242 C2.77684473,10.6398058 2.77118904,10.6341501 2.76575281,10.628287 L0.304472948,7.97375982 C0.185209412,7.84513231 0.192800414,7.64417691 0.321427924,7.52491338 C0.380155498,7.47046112 0.457286356,7.44020536 0.537373658,7.44020536 L2.21502181,7.44020536 C2.2148541,7.43353382 2.21476974,7.42684157 2.21476974,7.42012962 L2.21476974,1.0438835 C2.21476974,0.610956597 2.56572634,0.26 2.99865324,0.26 C3.43158015,0.26 3.78253674,0.610956597 3.78253674,1.0438835 L3.78253674,7.42012962 C3.78253674,7.42684157 3.78245239,7.43353382 3.78228468,7.44020536 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const svgDefaultProps = { height: '24', viewBox: '0 0 24 24', width: '24' };

const ChevronDown = (props?: any) => (
  <svg
    {...svgDefaultProps}
    {...props}
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

const Triangle = () => (
  <svg height="20" width="20">
    <polygon
      points="0,20 20,20 10,10"
      style={{
        fill: 'white',
      }}
    />
  </svg>
);

const SearchIcon = () => (
  <svg
    {...svgDefaultProps}
    fill="none"
    stroke={Colors.ADDITIONAL_BLUE_GRAY}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
  </svg>
);

type CloseIconProps = {
  color?: string;
};

const CloseIcon = ({ color }: CloseIconProps) => (
  <svg {...svgDefaultProps}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
      fill={color || 'black'}
    />
  </svg>
);

const Line = styled.div`
  background-color: ${(props) => props.color || Colors.GRAY_FIVE};
  height: 1px;
`;

const Divider = styled.div`
  background-color: ${Colors.GRAY_FIVE};
  width: 1px;
  height: 30px;
  margin: 0px 12px 0px 12px;
`;

const Row = styled(FlexRow as any)`
  min-height: 56px;
  padding: 0px 20px 0px 20px;
  align-items: center;
  box-sizing: border-box;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  border-top: 1px solid ${Colors.GRAY_SIX};

  &:hover {
    background-color: ${Colors.HOVER_SECONDARY};
  }

  &:first-child {
    border-top: none;
  }
`;

const ItemType = styled(FlexRow as any)<{ inactive?: boolean }>`
  width: ${(props) => props.theme.width || '150px'};
  flex-basis: ${(props) => props.theme.flexBasis || '150px'};
  flex-grow: ${(props) => props.theme.flexGrow || '0'};
  flex-shrink: ${(props) => props.theme.flexShrink || '0'};
  color: ${(props) => (props.inactive ? Colors.GRAY_FOUR : Colors.GRAY_TWO)};
  ${TypeStyles.LABEL_TEXT}
  font-style: ${(props) => (props.inactive ? 'italic' : 'normal')};
  justify-content: ${(props) => props.theme.justifyContent};
  text-overflow: ellipsis;
  margin-right: 10px;
  overflow-wrap: anywhere;
  align-items: center;
  overflow: hidden;
  max-height: 56px;
  display: ${(props) => props.theme.display};
`;

const DescriptionItemType = styled(ItemType as any)<{
  isShown?: boolean;
  eventsPageButtons?: boolean;
}>`
  flex-basis: 475px;
  display: inline-block;
  @media (max-width: 1760px) {
    flex-shrink: ${(props) =>
      props.isShown && props.eventsPageButtons ? '1' : '0'};
  }
`;

const CommentItemType = styled(ItemType as any)<{
  isShown?: boolean;
  eventsPageButtons?: boolean;
}>`
  flex-basis: 400px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;

  @media (max-width: 1760px) {
    display: ${(props) =>
      props.isShown && props.eventsPageButtons ? 'none' : 'inline-block'};
  }
`;

const OverlayBox = styled.div`
  width: 100%;
  height: 60%;
  min-height: 100px;
  max-height: 500px;
  position: absolute;
  top: 188px;
  left: 0px;
  background-color: white;
  opacity: 0.67;
  z-index: 5000;
  text-align: center;
  display: flex;
  align-items: center;
`;

const Spinner = styled.div`
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid ${Colors.BLUE_THREE};
  border-top-color: transparent;
  border-radius: 50%;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ViewButton = styled.button`
  border: 1px solid ${Colors.BLUE_TWO};
  border-radius: 4px;
  background-color: ${Colors.BLUE_TWO};
  color: white;
  font-weight: 600;
  ${TypeStyles.LABEL_TEXT}
  cursor: pointer;
  height: 28px;
  justify-content: center;
  margin-left: auto;
  font-family: Open Sans;

  &:hover {
    background-color: ${Colors.HOVER_PRIMARY};
  }
`;

const UncheckedBox = styled.div`
  width: 14.5px;
  height: 14.5px;
  border: 2px solid ${Colors.GRAY_FIVE};
  border-radius: 2px;
  background-color: white;
`;

const CheckedBox = () => (
  <svg
    {...svgDefaultProps}
    style={{ position: 'relative', top: '2px', right: '3px' }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill={Colors.BLUE_TWO}
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29c-.39.39-1.02.39-1.41 0L5.71 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.58 7.59z"
    />
  </svg>
);

type ChevronProps = {
  color?: string;
  disabled?: boolean;
};

const ChevronLeft = ({ color, disabled }: ChevronProps) => (
  <svg
    {...svgDefaultProps}
    fill={color || Colors.GRAY_FIVE}
    style={{
      cursor: `${!disabled && 'pointer'}`,
      opacity: `${disabled && '.4'}`,
    }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
    <path d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z" />
  </svg>
);

const ChevronRight = ({ color, disabled }: ChevronProps) => (
  <svg
    {...svgDefaultProps}
    fill={color || Colors.GRAY_FIVE}
    style={{
      cursor: `${!disabled && 'pointer'}`,
      opacity: `${disabled && '.4'}`,
    }}
  >
    <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
    <path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z" />
  </svg>
);

const TitleRow = styled(FlexRow as any)`
  margin: 30px 0px 12px 20px;
  height: 20px;
  box-sizing: border-box;
`;

const TitleRowType = styled(FlexRow as any)`
  margin-right: 10px;
  align-items: center;
  width: ${(props) => props.theme.width || '150px'};
  flex-basis: ${(props) => props.theme.flexBasis || '150px'};
  flex-grow: ${(props) => props.theme.flexGrow || '0'};
  flex-shrink: ${(props) => props.theme.flexShrink || '0'};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  ${TypeStyles.TITLE_ROW_TEXT};
`;

const RadioButtonUnchecked = () => (
  <svg
    {...svgDefaultProps}
    fill={Colors.GRAY_FIVE}
    style={{ position: 'relative', top: '2px', right: '3px' }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
);

const RadioButtonChecked = () => (
  <svg
    {...svgDefaultProps}
    fill={Colors.BLUE_TWO}
    style={{ position: 'relative', top: '2px', right: '3px' }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    <circle cx="12" cy="12" r="5" />
  </svg>
);

const BackArrow = () => (
  <svg
    {...svgDefaultProps}
    fill="none"
    stroke={Colors.BLUE_TWO}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <line x1="19" y1="12" x2="5" y2="12" />
    <polyline points="12 19 5 12 12 5" />
  </svg>
);

const VerticalLine = styled.div`
  background-color: ${Colors.GRAY_FIVE};
  width: 1px;
`;

type InfoIconProps = {
  color?: string;
  size?: string;
};

const InfoIcon = ({ color, size }: InfoIconProps) => (
  <svg
    {...svgDefaultProps}
    transform={size || 'scale(.75)'}
    fill={color || Colors.ADDITIONAL_SLATE}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);

type CheckIconProps = {
  size?: string;
};

const CheckCircle = ({ size }: CheckIconProps) => (
  <svg {...svgDefaultProps} fill={Colors.GREEN} transform={size || 'scale(1)'}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
  </svg>
);

const MonetizationIcon = () => (
  <svg {...svgDefaultProps} fill={Colors.ADDITIONAL_SLATE}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09v.58c0 .73-.6 1.33-1.33 1.33h-.01c-.73 0-1.33-.6-1.33-1.33v-.6c-1.33-.28-2.51-1.01-3.01-2.24-.23-.55.2-1.16.8-1.16h.24c.37 0 .67.25.81.6.29.75 1.05 1.27 2.51 1.27 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21v-.6c0-.73.6-1.33 1.33-1.33h.01c.73 0 1.33.6 1.33 1.33v.62c1.38.34 2.25 1.2 2.63 2.26.2.55-.22 1.13-.81 1.13h-.26c-.37 0-.67-.26-.77-.62-.23-.76-.86-1.25-2.12-1.25-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.02 1.83-1.39 2.83-3.13 3.16z" />
  </svg>
);

const CommentIcon = () => (
  <svg
    {...svgDefaultProps}
    fill={Colors.BLUE_TWO}
    transform="scale(.65,.65)"
    style={{ position: 'relative', top: '2px' }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
  </svg>
);

const ButtonBox = styled(FlexRow as any)`
  height: 28px;
  border: 1px solid ${Colors.BLUE_TWO};
  border-radius: 4px;
  padding: 4px 12px 5px 12px;
  color: ${Colors.BLUE_TWO};
  text-align: center;
  align-items: center;
  font-weight: 600;
  margin-left: 5px;
  box-sizing: border-box;
  cursor: pointer;
  ${TypeStyles.LABEL_TEXT}

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

const VerifyButton = styled(FlexRow as any)<{ verified?: boolean }>`
  box-sizing: border-box;
  background-color: ${(props) =>
    props.verified ? 'transparent' : Colors.GREEN};
  border-radius: 4px;
  font-weight: 600;
  color: ${(props) => (props.verified ? Colors.GREEN : 'white')};
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 100px;
  cursor: pointer;
  margin-left: 5px;
  border: 1px solid ${Colors.GREEN};
  ${TypeStyles.LABEL_TEXT}

  &:hover {
    background-color: ${(props) =>
      props.verified ? Colors.HOVER_UNVERIFY : Colors.HOVER_VERIFY};
  }
`;

const IndeterminateCheckbox = () => (
  <svg
    {...svgDefaultProps}
    fill={Colors.BLUE_TWO}
    style={{ position: 'relative', top: '2px', right: '3px' }}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 10H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z" />
  </svg>
);

const ToastRow = styled(FlexRow as any)`
  align-items: center;
`;

const SuccessToast = (props) => {
  const { closeToast, message } = props;
  return (
    <ToastRow onClick={closeToast}>
      <svg {...svgDefaultProps} fill="white">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z" />
      </svg>{' '}
      {message || 'Success!'}
    </ToastRow>
  );
};

const ErrorToast = (props) => {
  const { closeToast, message } = props;
  return (
    <ToastRow onClick={closeToast}>
      <svg {...svgDefaultProps} fill="white">
        <path d="M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-3h-2v-2h2v2z" />
      </svg>{' '}
      {message || 'Oops! Something went wrong...'}
    </ToastRow>
  );
};

const ToastProps: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

const ActionButton = styled.div<{ enabled?: boolean }>`
  box-sizing: border-box;
  width: 100px;
  height: 34px;
  font-weight: 600;
  text-align: center;
  line-height: 2;
  border-radius: 4px;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
`;

const CancelButton = styled(ActionButton as any)`
  color: ${Colors.ADDITIONAL_SLATE}
  background-color: white;
  border: 1px solid ${Colors.ADDITIONAL_BLUE_GRAY};
  ${(props) => !props.enabled && 'opacity: .4'}

  ${(props) =>
    props.enabled &&
    css`
      &:hover {
        background-color: ${Colors.HOVER_TERTIARY};
      }
    `}
`;

const SaveButton = styled(ActionButton as any)`
  color: white;
  background-color: ${Colors.BLUE_TWO};
  margin-left: 10px;
  ${(props) => !props.enabled && 'opacity: .4'}
  ${(props) =>
    props.enabled &&
    css`
      &:hover {
        background-color: ${Colors.HOVER_PRIMARY};
      }
    `};
`;

const LogOutButton = styled(ActionButton as any)`
  color: white;
  background-color: ${Colors.BLUE_TWO};
  margin: 20px auto 0px auto;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.HOVER_PRIMARY};
  }
`;

const ButtonRow = styled(FlexRow as any)`
  margin-top: 20px;
  justify-content: flex-end;
`;

type LinkProps = {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  resetPage?: boolean;
  newWindow?: boolean;
};

const StyledLink = ({
  to,
  children,
  style,
  resetPage,
  newWindow,
}: LinkProps) => {
  if (resetPage) {
    localStorage.setItem('resetPage', 'true');
  }
  return (
    <Link
      to={to}
      style={{ textDecoration: 'none', ...style }}
      target={newWindow && '_blank'}
    >
      {children}
    </Link>
  );
};

const NoItemsAvailable = styled(FlexRow as any)`
  justify-content: center;
  padding-top: 100px;
`;

const ImagingStatusIcon = (props) => {
  const color: string = props.color || 'transparent';
  const partial: boolean = props.type?.includes('Partial') || false;
  return (
    <svg
      viewBox="0 0 24 24"
      stroke={color}
      width="18px"
      height="18px"
      x={props.x || 0}
      y={props.y || 0}
    >
      <path
        d="M 12 12 z m 0 -10 l 0 10 l 0 10 C 6.45 17.12 4 13.38 4 10.2 C 4 5.22 7.8 2 12 2 z"
        fill={color}
      />
      <path
        d="M 12 12 z m 0 -10 c 4.2 0 8 3.22 8 8.2 c 0 3.18 -2.45 6.92 -8 11.8 z"
        fill={partial ? 'transparent' : color}
      />
    </svg>
  );
};

const SettingsIcon = () => (
  <svg enable-background="new 0 0 24 24" fill="black" {...svgDefaultProps}>
    <rect fill="none" height="24" width="24" />
    <path d="M19.5,12c0-0.23-0.01-0.45-0.03-0.68l1.86-1.41c0.4-0.3,0.51-0.86,0.26-1.3l-1.87-3.23c-0.25-0.44-0.79-0.62-1.25-0.42 l-2.15,0.91c-0.37-0.26-0.76-0.49-1.17-0.68l-0.29-2.31C14.8,2.38,14.37,2,13.87,2h-3.73C9.63,2,9.2,2.38,9.14,2.88L8.85,5.19 c-0.41,0.19-0.8,0.42-1.17,0.68L5.53,4.96c-0.46-0.2-1-0.02-1.25,0.42L2.41,8.62c-0.25,0.44-0.14,0.99,0.26,1.3l1.86,1.41 C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-1.86,1.41c-0.4,0.3-0.51,0.86-0.26,1.3l1.87,3.23c0.25,0.44,0.79,0.62,1.25,0.42 l2.15-0.91c0.37,0.26,0.76,0.49,1.17,0.68l0.29,2.31C9.2,21.62,9.63,22,10.13,22h3.73c0.5,0,0.93-0.38,0.99-0.88l0.29-2.31 c0.41-0.19,0.8-0.42,1.17-0.68l2.15,0.91c0.46,0.2,1,0.02,1.25-0.42l1.87-3.23c0.25-0.44,0.14-0.99-0.26-1.3l-1.86-1.41 C19.49,12.45,19.5,12.23,19.5,12z M12.04,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.97,15.5,12.04,15.5z" />
  </svg>
);

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  z-index: 11;
  padding: 20px;
  transform: translate(-50%, -50%);
`;

const OptimizationText = styled.div`
  position: relative;
  left: 120px;
  top: 20px;
  font-style: italic;
  width: 800px;
`;

const WarningIcon = () => (
  <svg {...svgDefaultProps}>
    <path
      d="M.5 16h17L9 1 .5 16zm9.5-2H8v-2h2v2zm0-3H8V7h2v4z"
      fill={Colors.WARNING}
    />
  </svg>
);

const Reload = styled.div`
  color: blue;
  cursor: pointer;
`;

const RefreshIcon = () => (
  <svg {...svgDefaultProps} fill={Colors.ADDITIONAL_BLUE_GRAY}>
    <path d="M17.65 6.35c-1.63-1.63-3.94-2.57-6.48-2.31-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20c3.19 0 5.93-1.87 7.21-4.56.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53-1.13 2.43-3.84 3.97-6.8 3.31-2.22-.49-4.01-2.3-4.48-4.52C5.31 9.44 8.26 6 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z" />
  </svg>
);

const ActiveRuleRow = styled(FlexRow as any)`
  justify-content: flex-start;
  margin-bottom: 10px;
  color: ${Colors.GRAY_TWO};
  align-items: center;
  height: 20px;
`;

const CardBorder = styled(BorderBox as any)`
  min-height: 533px;
  margin-top: 20px;
`;

const CardOverlayBox = styled(FlexColumn as any)`
  width: 100%;
  background-color: white;
  opacity: 0.67;
  z-index: 5000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`;

const RefreshBorder = styled.div`
  cursor: pointer;
  border: 0px solid ${Colors.ADDITIONAL_BLUE_GRAY};
  border-radius: 4px;
  align-items: center;
  box-sizing: border-box;
  height: 34px;
  width: 34px;
  padding: 4px 0px 0px 4px;

  &:hover {
    background-color: ${Colors.GRAY_SIX};
  }
`;

const SideBar = styled(FlexColumn as any)`
  border-right: 1px solid ${Colors.GRAY_FIVE};
`;

const SideBarHeader = styled.div`
  color: ${Colors.GRAY_TWO};
  background-color: ${Colors.GRAY_SEVEN};
  width: 280px;
  height: 31px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 15px;
  padding: 8px 0px 8px 20px;
  box-sizing: border-box;
`;

const SideBarCard = styled.div<{ height?: string }>`
  height: ${(props) => props.height || '85px'};
  box-sizing: border-box;
  padding: 12px 0px 0px 20px;
  border-top: 1px solid ${Colors.GRAY_FIVE};
  background-color: ${(props) => props.color || 'white'};
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${Colors.ADDITIONAL_LIGHT_BLUE};
  }
`;

const SideBarActiveBar = styled.div<{ height?: string }>`
  height: ${(props) => props.height || '85px'};
  width: 4px;
  background-color: ${Colors.BLUE_TWO};
  position: relative;
  right: 20px;
  bottom: 12px;
`;

const CardTitle = styled(FlexRow as any)`
  height: 34px;
  margin: 20px 0px 0px 20px;
  justify-content: space-between;
`;

const CardTitleText = styled.div`
  ${TypeStyles.CARD_TITLE_TEXT}
`;

const HelpIcon = () => (
  <svg {...svgDefaultProps} fill="black">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z" />
  </svg>
);

const FilterBar = styled(FlexRow as any)`
  margin: 15px 20px 30px 20px;
  height: 34px;
  min-width: 1690px;
`;

const EventDetailButton = styled(FlexRow as any)`
  font-weight: 600;
  color: ${Colors.BLUE_ONE};
  border: 1px solid ${Colors.BLUE_ONE};
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  justify-content: center;
  ${TypeStyles.LABEL_TEXT}

  &:hover {
    color: white;
    background-color: ${Colors.BLUE_ONE};
  }
`;

const Styled = {
  Bubble,
  FlexRow,
  FlexColumn,
  BorderBox,
  GridBox,
  ArrowUp,
  ArrowDown,
  ChevronDown,
  Triangle,
  SearchIcon,
  CloseIcon,
  Line,
  Divider,
  Row,
  ItemType,
  ViewButton,
  UncheckedBox,
  CheckedBox,
  ChevronLeft,
  ChevronRight,
  TitleRow,
  TitleRowType,
  OverlayBox,
  Spinner,
  RadioButtonUnchecked,
  RadioButtonChecked,
  BackArrow,
  VerticalLine,
  InfoIcon,
  CheckCircle,
  MonetizationIcon,
  CommentIcon,
  ButtonBox,
  VerifyButton,
  IndeterminateCheckbox,
  SuccessToast,
  ErrorToast,
  ToastProps,
  CancelButton,
  SaveButton,
  ButtonRow,
  StyledLink,
  NoItemsAvailable,
  ImagingStatusIcon,
  DescriptionItemType,
  CommentItemType,
  SettingsIcon,
  ModalOverlay,
  Modal,
  LogOutButton,
  OptimizationText,
  WarningIcon,
  Reload,
  RefreshIcon,
  ActiveRuleRow,
  CardBorder,
  CardOverlayBox,
  RefreshBorder,
  SideBar,
  SideBarHeader,
  SideBarCard,
  SideBarActiveBar,
  CardTitle,
  CardTitleText,
  HelpIcon,
  FilterBar,
  EventDetailButton,
};

export default Styled;
