import Cookies from 'js-cookie';

import { createWorkerFactory } from '@shopify/react-web-worker';

const createWorker = createWorkerFactory(() => import('./pages/apiWorker.js'));

const options = {
  path: '/',
  expires: 1 / 24,
  domain: `${process.env.REACT_APP_DOMAIN}`,
  sameSite: 'strict', // Always keep set to strict for now
  secure: process.env.REACT_APP_IS_LOCAL ? false : true, // This SHOULD BE true in every deployed environment (i.e. when not testing locally)
};

export class CustomStore {
  #worker = createWorker();
  // private class members
  #keyValueStore = {};
  // Cookie options. The domain will at least need to be set with environment variables
  #options = options;

  storageProp;

  storageType() {
    return 'CustomStore';
  }

  constructor() {
    this.storageProp = 'storageProp';
  }

  // if value is formatted like a token, then only store the middle part (the payload)
  setItem(key, value) {
    if (value.match(/^\w+\.\w+\.\w+$/g) || key.match(/token/gim)) {
      const tokenParts = value.split('.');
      if (tokenParts.length === 3) {
        Cookies.set(key, `header.${tokenParts[1]}.signature`, this.#options);
        if (key.match(/idToken/gim)) {
          this.#worker.onmessage(value);
        }
      }
    } else {
      Cookies.set(key, value, this.#options);
    }

    if (!key.toLowerCase().includes('refresh')) {
      this.#keyValueStore[key] = value;
    }

    return this.#keyValueStore[key] || Cookies.get(key) || value;
  }

  getItem(key) {
    return this.#keyValueStore[key] || Cookies.get(key);
  }

  removeItem(key) {
    if (Object.prototype.hasOwnProperty.call(this.#keyValueStore, key)) {
      const success = delete this.#keyValueStore[key];
      return success;
    }
    return Cookies.remove(key, this.#options);
  }

  clear() {
    this.#keyValueStore = {};
    clearCookies();
    return {};
  }
}

export const clearCookies = (prefix?: string) => {
  const cookies = Cookies.get();
  Object.keys(cookies)
    .filter((c) => !prefix || c.toLowerCase().startsWith(prefix))
    .forEach((c) => Cookies.remove(c, options));
};
