import React, { useRef, useState } from 'react';

import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const ShowingTextBox = styled(Styled.FlexRow)`
  align-self: flex-end;
  margin-left: auto;
`;

const ShowingNumber = styled.div`
  margin: 0px 5px 0px 5px;
`;

const Chevron = styled.a`
  margin: 0px 5px 0px 5px;
`;

const Divider = styled.div`
  position: relative;
  bottom: 2px;
  margin-right: 5px;
`;

const Settings = styled.a`
  cursor: pointer;
`;

const SettingsPopUp = styled.div`
  background-color: white;
  width: 200px;
  font-size: 12px;
  line-height: 17px;
  padding: 13px 13px 13px 13px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  position: relative;
  top: 50px;
  right: 80px;
  z-index: 10;
`;

const Triangle = styled.div`
  position: relative;
  top: 50px;
  left: 85px;
  height: 20px;
  width: 0px;
  justify-content: center;
  background-color: transparent;
  box-shadow: 9px 15px 10px 5px rgba(0, 0, 0, 0.25);
`;

const PageSizeItem = styled.div<{ inactive?: boolean }>`
  cursor: ${props => (props.inactive ? 'default' : 'pointer')};
  margin: 0 5px 0 5px;
  color: ${props => (props.inactive ? 'black' : Colors.BLUE_TWO)};
`;

export const PAGE_LENGTH = [10, 20, 30];

type Props = {
  minNumber: number;
  maxNumber: number;
  totalNumber: number;
  pageNumber: number;
  maxPageNumber: number;
  onLeftClick: () => void;
  onRightClick: () => void;
  patients?: boolean;
  handlePageLengthChange?: (num) => void;
  pageLength?: number;
  treatments?: boolean;
  checklist?: boolean;
  notes?: boolean;
};

const ShowingText = ({
  minNumber,
  maxNumber,
  totalNumber,
  pageNumber,
  maxPageNumber,
  onLeftClick,
  onRightClick,
  patients,
  handlePageLengthChange,
  pageLength,
  checklist,
  notes,
}: Props) => {
  const chevronLeftDisabled = pageNumber === 0 || !totalNumber;
  const chevronRightDisabled = pageNumber === maxPageNumber || !totalNumber;

  const [isShown, setIsShown] = useState(false);
  const ref = useRef();
  Functions.useOnClickOutside(ref, () => setIsShown(false));

  return (
    <ShowingTextBox>
      <ShowingNumber>
        {totalNumber ? `${minNumber}-${maxNumber} ` : '0-0'}
      </ShowingNumber>
      of
      <ShowingNumber>{totalNumber ? totalNumber : '0'}</ShowingNumber>
      {patients || checklist ? 'Patients' : notes ? 'Notes' : 'Events'}
      <Chevron onClick={onLeftClick}>
        <Styled.ChevronLeft
          color={!chevronLeftDisabled && Colors.BLUE_TWO}
          disabled={chevronLeftDisabled}
        />
      </Chevron>
      <Chevron onClick={onRightClick}>
        <Styled.ChevronRight
          color={!chevronRightDisabled && Colors.BLUE_TWO}
          disabled={chevronRightDisabled}
        />
      </Chevron>
      <Divider>
        <Styled.Divider />
      </Divider>
      <Settings onClick={() => setIsShown(!isShown)}>
        <Styled.SettingsIcon />
      </Settings>
      {isShown && (
        <div
          ref={ref}
          style={{
            position: 'relative',
            bottom: '30px',
            right: '107px',
            width: '0px',
            height: '0px',
          }}
        >
          <Triangle>
            <Styled.Triangle />
          </Triangle>
          <SettingsPopUp>
            <Styled.FlexRow>
              {patients || checklist ? 'Patients' : notes ? 'Notes' : 'Events'}{' '}
              / page&nbsp;
              {PAGE_LENGTH.map((p, i) => {
                return (
                  <>
                    <PageSizeItem
                      inactive={pageLength === p}
                      onClick={() =>
                        pageLength !== p && handlePageLengthChange(p)
                      }
                    >
                      {p}
                    </PageSizeItem>
                    {i < PAGE_LENGTH.length - 1 && '|'}
                  </>
                );
              })}
            </Styled.FlexRow>
          </SettingsPopUp>
        </div>
      )}
    </ShowingTextBox>
  );
};

export default ShowingText;
