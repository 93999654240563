import React, { useMemo } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  RectangleProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x + width / 2}
      y1={y + height}
      x2={x + width / 2}
      y2={y}
      stroke={'rgb(128,128,128)'}
      strokeWidth={1}
    />
  );
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height, stroke } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      x1={x}
      y1={y}
      x2={x + width}
      y2={y}
      stroke={stroke || 'rgb(128,128,128)'}
      strokeWidth={1}
    />
  );
};

export type BoxPlot = {
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  name: string;
};

type BoxPlotData = {
  min: number;
  bottomWhisker: number;
  bottomBox?: number;
  topBox?: number;
  bottomRedBox?: number;
  topRedBox?: number;
  topWhisker: number;
  name: string;
};

const useBoxPlot = (boxPlots: BoxPlot[], threshold: number): BoxPlotData[] => {
  const data = useMemo(
    () =>
      boxPlots.map(v => {
        return v.max > threshold
          ? {
              min: v.min,
              bottomWhisker: v.lowerQuartile - v.min,
              bottomRedBox: v.median - v.lowerQuartile,
              topRedBox: v.upperQuartile - v.median,
              topWhisker: v.max - v.upperQuartile,
              name: v.name,
            }
          : {
              min: v.min,
              bottomWhisker: v.lowerQuartile - v.min,
              bottomBox: v.median - v.lowerQuartile,
              topBox: v.upperQuartile - v.median,
              topWhisker: v.max - v.upperQuartile,
              name: v.name,
            };
      }),
    [boxPlots]
  );

  return data;
};

type Props = {
  plotData: BoxPlot[];
  threshold: number;
};

const BoxPlotChart = ({ plotData: plot, threshold }: Props) => {
  const plotData =
    plot?.map(point => ({
      ...point,
      name: Functions.insightDateToMonth(point.name),
    })) || [];
  const data = useBoxPlot(plotData, threshold);
  const CustomTooltip = props => {
    if (!props.active || !props.payload) {
      return null;
    }
    const plotLabels = ['max', '75%', 'median', '25%', 'min'];
    const plotKeys = ['max', 'upperQuartile', 'median', 'lowerQuartile', 'min'];
    const newPayload = props.payload.map((point, index) => ({
      ...point,
      name: plotLabels[index],
      value: plotData.find(set => set.name === point.payload.name)[
        plotKeys[index]
      ],
      color: 'black',
      unit: 'mm',
    }));

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <ResponsiveContainer width={'100%'} minHeight={'500px'}>
      <ComposedChart data={data}>
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: 'Couch Shift (mm)',
            angle: -90,
            position: 'insideLeft',
          }}
          domain={[0, threshold]}
          tickFormatter={tick => parseFloat(tick.toFixed(4))}
          allowDataOverflow
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar barSize={50} stackId={'a'} dataKey={'min'} fill={'none'} />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'bar'}
          shape={<HorizonBar />}
        />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'bottomWhisker'}
          shape={<DotBar />}
        />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'bottomBox'}
          fill={Colors.REAL_TIME_ORANGE}
        />
        <Bar barSize={50} stackId={'a'} dataKey={'bottomRedBox'} fill={'red'} />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'bar'}
          shape={<HorizonBar stroke={'black'} />}
        />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'topBox'}
          fill={Colors.REAL_TIME_ORANGE}
        />
        <Bar barSize={50} stackId={'a'} dataKey={'topRedBox'} fill={'red'} />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'topWhisker'}
          shape={<DotBar />}
        />
        <Bar
          barSize={50}
          stackId={'a'}
          dataKey={'bar'}
          shape={<HorizonBar />}
        />
        <Tooltip cursor={false} content={CustomTooltip} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BoxPlotChart;
