import React, { useEffect } from 'react';

import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const SearchIcon = styled.div`
  position: relative;
  left: -30px;
  top: 6px;
  cursor: pointer;
`;

const selectText = () => {
  const input = document.getElementById('search-box') as HTMLInputElement;
  input.select();
};

type Props = {
  value: string;
  onChange: (event) => void;
  handleSearch?: () => void;
  admin?: boolean;
};

const SearchBar = ({ value, onChange, handleSearch, admin }: Props) => {
  const placeholder = admin ? 'Event Type' : 'Patient Name or MRN';
  useEffect(() => selectText(), []);
  return (
    <>
      <input
        size={30}
        placeholder={placeholder}
        results={0}
        value={value}
        onChange={onChange}
        style={{
          borderRadius: '4px',
          border: `1px solid ${Colors.GRAY_FIVE}`,
          fontSize: '14px',
          lineHeight: '19px',
          height: '34px',
          fontFamily: 'Open Sans',
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSearch();
            selectText();
          }
        }}
        id="search-box"
      />
      <SearchIcon
        onClick={() => {
          handleSearch();
          selectText();
        }}
      >
        <Styled.SearchIcon />
      </SearchIcon>
    </>
  );
};

export default SearchBar;
