import Functions, { filter } from '../../util/UtilityFunctions';
import React, { useEffect, useState } from 'react';

import Colors from '../../styles/Colors';
import { Machine } from '../../types/Machine';
import MachineRow from './MachineRow';
import Options from '../../util/Options';
import RadioMenu from '../ui/RadioMenu';
import SortArrow from '../ui/SortArrow';
import Styled from '../../styles/Styles';
import { fetchAllMachines } from '../../api/machine';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const { timeOptions, TimeFilterOptions } = Options;

type Props = {
  count: number;
  setCountsFilter?: (num: number) => void;
  children?: React.ReactNode;
};

const MachineCard = ({ count, setCountsFilter, children }: Props) => {
  const navigate = useNavigate();
  const savedFilter: filter = JSON.parse(
    localStorage.getItem('dashboardFilter')
  );
  const defaultFilter: filter = {
    sortDescending: true,
    sortBy: 'events',
    timeFilter: 0,
  };

  const [filter, setFilter] = useState<filter>(savedFilter || defaultFilter);
  const sortFunction = Functions.sortFunction(
    filter.sortDescending,
    filter.sortBy
  );

  const eventTime =
    filter.timeFilter !== TimeFilterOptions.ALL_TIME &&
    Functions.timeFilterToApiDate(filter.timeFilter);

  useEffect(() => {
    localStorage.setItem('dashboardFilter', JSON.stringify(filter));
  }, [filter]);

  const { data, loading, error } = usePromise(fetchAllMachines, [
    navigate,
    eventTime,
    null,
    null,
    count,
  ]);
  // const machines = error ? machineList : data?.machines;
  const machines = data?.machines;

  return (
    <Styled.CardBorder>
      <div style={{ margin: '10px' }}>
        <RadioMenu
          title="Time"
          options={timeOptions.slice(0, 4)}
          onChange={(num) => {
            setFilter({ ...filter, timeFilter: num });
            setCountsFilter(num);
          }}
          initialFilter={filter.timeFilter}
        />
      </div>
      {children}
      <Styled.CardTitle>
        <Styled.CardTitleText>Events by Machines</Styled.CardTitleText>
      </Styled.CardTitle>
      <Styled.TitleRow>
        <Styled.TitleRowType
          onClick={() => {
            setFilter(Functions.handleSetSortBy(filter, 'name'));
          }}
        >
          MACHINE&nbsp;
          <SortArrow
            sortDescending={filter.sortDescending}
            isShown={filter.sortBy === 'name'}
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType
          onClick={() => {
            setFilter(Functions.handleSetSortBy(filter, 'events'));
          }}
        >
          UNVERIFIED EVENTS&nbsp;
          <SortArrow
            sortDescending={filter.sortDescending}
            isShown={filter.sortBy === 'events'}
          />
        </Styled.TitleRowType>
      </Styled.TitleRow>
      <Styled.Line color={Colors.ADDITIONAL_BLUE_GRAY} />
      <div>
        {machines?.sort(sortFunction).map((machine: Machine) => (
          <MachineRow
            machine={machine}
            timeFilter={TimeFilterOptions[filter.timeFilter]}
          />
        ))}
      </div>
      {loading && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
    </Styled.CardBorder>
  );
};

export default MachineCard;
