import React from 'react';
import Styled from '../../styles/Styles';

type Props = {
  sortDescending: boolean;
  isShown: boolean;
};

const SortArrow = ({ sortDescending, isShown }: Props) => {
  return (
    <>
      {isShown && (sortDescending ? <Styled.ArrowDown /> : <Styled.ArrowUp />)}
    </>
  );
};

export default SortArrow;
