import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { Auth } from '@aws-amplify/auth';
import { CustomStore } from './auth';
import React from 'react';
import ReactDOM from 'react-dom';

// const onRedirectCallback = appState => {
//   // eslint-disable-next-line
//   history.push(
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

localStorage.setItem('debug', 'chart-alert-app:*');

Auth.configure({
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    redirectSignIn: process.env.REACT_APP_AUTH_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_AUTH_SIGN_OUT_URL,
    responseType: 'code',
  },
  storage: new CustomStore(),
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
