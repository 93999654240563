import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import { toMachines } from '../util/convertToTypes';
import { toast } from 'react-toastify';

export const fetchAllMachines = async (
  navigate?: NavigateFunction,
  eventTime?: string,
  patientId?: string,
  planId?: string,
  count?: number
) => {
  try {
    const params = {
      ...(eventTime ? { timeRange: eventTime } : {}),
      ...{ patientId },
      ...{ planId },
    };
    const state =
      count !== 0 &&
      (await Functions.apiCall({
        route: 'machines_all',
        paramsOrData: params,
        navigate,
      }));
    return toMachines(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'machine.ts');
  }
};

export const fetchMachinesWithOverrides = async (
  navigate: NavigateFunction
) => {
  try {
    const state = await Functions.apiCall({
      route: 'machines_with_overrides',
      navigate,
    });
    return state?.data;
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'machine.ts');
  }
};
