import { FinalPatient } from '../../types/Checklist';
import Functions from '../../util/UtilityFunctions';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import React, { useState } from 'react';
import Styled from '../../styles/Styles';
import { reverse } from 'named-urls';
import routes from '../../routes';
import AriaReportButton from '../patient/AriaReportButton';

type Props = {
  patient: FinalPatient;
  patientInfo: PatientNameAndMrn;
};

const ChecklistRow = ({ patient, patientInfo }: Props) => {
  const [isShown, setIsShown] = useState(false);
  const [_generateReportButton, setGenerateReportButton] = useState(false);

  return (
    <Styled.StyledLink
      to={`${reverse(`${routes.treatmentsPatients}`, {
        patientID: patient?.id,
      })}?checklist=true`}
    >
      <Styled.Row
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          justifyContent: 'left',
          cursor: 'pointer',
        }}
      >
        {isShown && (
          <Styled.FlexRow style={{ position: 'relative' }}>
            <Styled.FlexRow
              style={{
                position: 'absolute',
                width: '170px',
                top: '-14px',
                left: '100px',
              }}
              onMouseEnter={() => setGenerateReportButton(true)}
              onMouseLeave={() => setGenerateReportButton(false)}
            >
              <AriaReportButton patientId={patient.id} />
            </Styled.FlexRow>
          </Styled.FlexRow>
        )}
        <Styled.ItemType theme={{ flexBasis: '30px' }}>
          {(patient?.finalTreatment || patient?.weeklyTreatment) ===
            'Completed' &&
            (patient.hasUnverifiedEvents ? (
              <>
                <Styled.InfoIcon />
                &nbsp;
              </>
            ) : (
              <>
                <Styled.CheckCircle size={'scale(.75)'} />
                &nbsp;
              </>
            ))}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '270px' }}>
          {Functions.formatPatientName(patientInfo)}
        </Styled.ItemType>
        <Styled.ItemType
          theme={{ flexBasis: '100px' }}
          style={{ wordBreak: 'break-all' }}
        >
          {patientInfo?.mrn}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '230px' }}>
          {Functions.formatChecklistFractions(patient)}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '150px' }}>
          {patient?.finalTreatment || patient?.weeklyTreatment}
        </Styled.ItemType>
      </Styled.Row>
    </Styled.StyledLink>
  );
};

export default ChecklistRow;
