import React, { useState } from 'react';

import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const CloseIcon = styled.div`
  cursor: pointer;
  height: 26px;

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

const Title = styled(Styled.FlexRow)`
  margin-bottom: 30px;
  justify-content: space-between;
`;

const DoNotShowAgain = styled(Styled.FlexRow)`
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`;

const DoNotShowText = styled.div`
  position: relative;
  top: 2px;
`;

const CheckboxContainer = styled.div`
  height: 20px;
  width: 20px;
`;

type Props = {
  logout: () => void;
  onCloseClick: () => void;
};

const LogoutModal = ({ logout, onCloseClick }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const setDoNotShow = () => {
    localStorage.setItem('doNotShowLogOutModal', 'true');
  };

  const LogOut = () => {
    if (isChecked) {
      setDoNotShow();
    }
    localStorage.removeItem('email');
    localStorage.removeItem('idp');
    logout();
  };

  return (
    <Styled.ModalOverlay>
      <Styled.Modal>
        <Title>
          Logout Confirmation
          <CloseIcon onClick={onCloseClick}>
            <Styled.CloseIcon />
          </CloseIcon>
        </Title>
        Log out from ChartAlert?
        <DoNotShowAgain>
          <Styled.FlexRow>
            <CheckboxContainer>
              <Checkbox
                isChecked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
              />
            </CheckboxContainer>
            <DoNotShowText>Do Not Show This Message Again</DoNotShowText>
          </Styled.FlexRow>
        </DoNotShowAgain>
        <Styled.FlexColumn>
          <Styled.LogOutButton onClick={() => LogOut()}>
            Log Out
          </Styled.LogOutButton>
        </Styled.FlexColumn>
      </Styled.Modal>
    </Styled.ModalOverlay>
  );
};

export default LogoutModal;
