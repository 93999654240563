import React, { useState } from 'react';

import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import Options from '../../util/Options';
import RadioMenu from '../ui/RadioMenu';
import { Rule } from '../../types/Admin';
import Styled from '../../styles/Styles';
import ToggleSwitch from '../../components/ui/ToggleSwitch';
import TypeStyles from '../../styles/TypeStyles';
import { fetchNoteTypes } from '../../api/note';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const Text = styled(Styled.FlexRow)`
  margin-right: 12px;
  color: ${Colors.GRAY_TWO};
  ${TypeStyles.LABEL_TEXT}
`;

const BreadCrumb = styled(Text)`
  color: ${Colors.BLUE_TWO};
  cursor: pointer;
  margin-right: 8px;
`;

const TitleText = styled.div`
  margin: 10px 0px 10px 0px;
  ${TypeStyles.TITLE_TEXT}
`;

const Toggle = styled(Styled.FlexRow)`
  align-items: center;
  margin: 30px 0px 30px 0px;
`;

const ruleConfig = {
  delivered_dose: {
    ruleToText: 'Dose',
    ruleToUnit: 'cGy',
    ruleToDefaultTolerance: 0,
  },
  couch_shift_couch_lat: {
    ruleToText: 'Shift',
    ruleToUnit: 'cm',
    ruleToDefaultTolerance: 3,
  },
  couch_shift_couch_vrt: {
    ruleToText: 'Shift',
    ruleToUnit: 'cm',
    ruleToDefaultTolerance: 1,
  },
  couch_shift_couch_lng: {
    ruleToText: 'Shift',
    ruleToUnit: 'cm',
    ruleToDefaultTolerance: 3,
  },
  override_collimator_angle: {
    ruleToText: 'Override',
    ruleToUnit: 'deg',
    ruleToDefaultTolerance: 1,
  },
};

type Props = {
  option: Rule;
  handleClose: () => void;
  handleSave: (
    active: boolean,
    priority: number,
    customTolerance: number | string,
    noteTypes: string[]
  ) => void;
};

const EventTypeDetails = ({ option, handleClose, handleSave }: Props) => {
  const navigate = useNavigate();
  const optionConfigKey = `${option.ruleType}${
    option.parameter ? `_${option.parameter}` : ''
  }`;
  const [active, setActive] = useState(option.enabled);
  const [priority, setPriority] = useState(option.priority);
  const [customTolerance, setCustomTolerance] = useState<number | string>(
    option.customTolerance
  );
  const [enableCustomTolerance, setEnableCustomTolerance] = useState(
    option.customTolerance !== null
  );
  const onChange = (event) => {
    setCustomTolerance(event.target.value);
  };

  const handlePriorityChange = (prior: number) => {
    setPriority(prior + 1);
  };
  const [activeNoteTypes, setActiveNoteTypes] = useState(
    option?.noteTypes || []
  );
  const buttonsEnabled =
    ((active !== option.enabled ||
      priority !== option.priority ||
      customTolerance !== option.customTolerance ||
      (!enableCustomTolerance && customTolerance !== null)) &&
      !(enableCustomTolerance && customTolerance === null)) ||
    activeNoteTypes !== option?.noteTypes;

  const { data } = usePromise(fetchNoteTypes, [navigate]);

  const handleNoteClick = (type: string) => {
    const newNoteTypes = [...activeNoteTypes];
    if (activeNoteTypes.includes(type)) {
      newNoteTypes.splice(newNoteTypes.indexOf(type, 1));
    } else {
      newNoteTypes.push(type);
    }
    setActiveNoteTypes(newNoteTypes);
  };

  const enableNoteTypes = option.ruleType === 'note_addition';
  return (
    <Styled.FlexColumn style={{ margin: '20px', width: '90%' }}>
      <Text>
        <BreadCrumb onClick={handleClose}>Events</BreadCrumb>{' '}
        {' / Event Details'}
      </Text>
      <Styled.FlexRow>
        <Styled.FlexColumn>
          <TitleText>{option.description}</TitleText>
          <Text>{option.description}</Text>
          <Toggle>
            <Text>Active</Text>
            <ToggleSwitch checked={active} onClick={() => setActive(!active)} />
          </Toggle>
          <RadioMenu
            title="Priorities"
            options={Options.priorityOptions}
            onChange={handlePriorityChange}
            initialFilter={priority - 1}
          />
        </Styled.FlexColumn>
        {ruleConfig[optionConfigKey] !== undefined && (
          <Styled.FlexColumn style={{ marginLeft: '200px' }}>
            <TitleText>Custom Tolerance</TitleText>
            <Styled.FlexRow style={{ alignItems: 'center' }}>
              <div style={{ width: '30px' }}>
                <Checkbox
                  isChecked={enableCustomTolerance}
                  onClick={() => {
                    if (enableCustomTolerance) {
                      setCustomTolerance(null);
                    } else {
                      setCustomTolerance(customTolerance);
                    }
                    setEnableCustomTolerance(!enableCustomTolerance);
                  }}
                />
              </div>
              Enable {ruleConfig[optionConfigKey]?.ruleToText} Tolerance&nbsp;
              <input
                size={15}
                placeholder={
                  ruleConfig[optionConfigKey]?.ruleToDefaultTolerance
                }
                results={0}
                value={customTolerance}
                onChange={onChange}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                  textAlign: 'right',
                }}
                disabled={!enableCustomTolerance}
                id="search-box"
              />
              <div
                style={{
                  color: `${!enableCustomTolerance ? Colors.GRAY : 'black'}`,
                }}
              >
                {ruleConfig[optionConfigKey]?.ruleToUnit}
              </div>
            </Styled.FlexRow>
          </Styled.FlexColumn>
        )}
        {enableNoteTypes && (
          <Styled.FlexColumn style={{ marginLeft: '100px' }}>
            <TitleText>Note Types</TitleText>
            {data?.noteTypes.map((type) => (
              <Styled.FlexRow
                style={{
                  justifyContent: 'space-between',
                  height: '20px',
                  width: '200px',
                  alignItems: 'center',
                  fontWeight: 'normal',
                }}
              >
                <Checkbox
                  isChecked={activeNoteTypes?.includes(type)}
                  onClick={() => handleNoteClick(type)}
                />{' '}
                {type}
              </Styled.FlexRow>
            ))}
          </Styled.FlexColumn>
        )}
      </Styled.FlexRow>
      <Styled.ButtonRow>
        <Styled.CancelButton
          onClick={buttonsEnabled && handleClose}
          enabled={buttonsEnabled}
        >
          Cancel
        </Styled.CancelButton>
        <Styled.SaveButton
          onClick={() =>
            buttonsEnabled &&
            handleSave(
              active,
              priority,
              enableCustomTolerance
                ? customTolerance === ''
                  ? null
                  : customTolerance
                : null,
              enableNoteTypes ? activeNoteTypes : []
            )
          }
          enabled={buttonsEnabled}
        >
          Save
        </Styled.SaveButton>
      </Styled.ButtonRow>
    </Styled.FlexColumn>
  );
};

export default EventTypeDetails;
