import styled from 'styled-components';
import Styled from '../../styles/Styles';
import Colors from '../../styles/Colors';
import React from 'react';
import { generateReport } from '../../api/report';
import { usePromise } from '../../util/api';
import { fetchFeatureFlags } from '../../api/auth';

const ReportButton = styled(Styled.ViewButton)`
  border: 1px solid ${Colors.REAL_TIME_ORANGE};
  background-color: ${Colors.REAL_TIME_ORANGE};
  &:hover {
    background-color: ${Colors.ORANGE_HOVER};
  }
`;

type Props = {
  patientId: number;
};

const AriaReportButton = ({ patientId }: Props) => {
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const enableAriaReportGeneration =
    featureFlags?.['enableAriaReportGeneration'];
  return enableAriaReportGeneration ? (
    <ReportButton
      onClick={(e) => {
        e.preventDefault();
        generateReport(patientId, new Date());
      }}
    >
      Generate Report
    </ReportButton>
  ) : null;
};

export default AriaReportButton;
