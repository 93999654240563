import { fetchInitialUserAndClinics } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import RouteComponent from '../ui/RouteComponent';
import routes from '../../routes';
import { usePromise } from '../../util/api';

const RouteComponentWrapper = () => {
  const navigate = useNavigate();

  const currentClinic = localStorage.getItem('userClinicId');
  const { data, loading } = usePromise(fetchInitialUserAndClinics, [
    navigate,
    currentClinic,
  ]);

  const forceSelection = (data as any)?.forceSelection;

  useEffect(() => {
    if (forceSelection) {
      navigate(routes.selectClinic);
    }
  }, [forceSelection]);

  return <RouteComponent loading={loading} />;
};

export default RouteComponentWrapper;
