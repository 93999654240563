import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Card = styled(Styled.FlexRow)<{ isActive: boolean }>`
  width: 280px;
  height: 40px;
  color: ${props => (props.isActive ? Colors.BLUE_TWO : Colors.GRAY_THREE)}
  background-color: ${props =>
    props.isActive ? Colors.ADDITIONAL_LIGHT_BLUE : 'white'}
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  ${TypeStyles.LABEL_TEXT}

  &:hover{
      background-color: ${Colors.ADDITIONAL_LIGHT_BLUE}
  }
`;

const ActiveBar = styled.div`
  height: 40px;
  width: 4px;
  background-color: ${Colors.BLUE_TWO};
`;

const OptionText = styled.div`
  position: absolute;
  left: 20px;
`;

type Props = {
  optionName: string;
  isActive: boolean;
  onClick: (option: string) => void;
};

const ConfigOptionCard = ({ optionName, isActive, onClick }: Props) => {
  return (
    <Card onClick={() => onClick(optionName)} isActive={isActive}>
      {isActive && <ActiveBar />}
      <OptionText>{optionName}</OptionText>
    </Card>
  );
};

export default ConfigOptionCard;
