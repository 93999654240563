import React, { useState } from 'react';

import EventNotifications from '../components/ui/EventNotifications';
import Header from '../components/ui/Header';
import SettingsColumn from '../components/ui/SettingsColumn';
import Styled from '../styles/Styles';

const settingsOptions = ['Event Notifications', 'General'];

const Settings = () => {
  // const { user } = useAuth();
  // const enableEventNotifications =
  //   user[`${process.env.REACT_APP_CLAIMS_URL}/enableEventNotifications`];
  // if (!enableEventNotifications) {
  //   settingsOptions.splice(settingsOptions.indexOf('Event Notifications'), 1);
  // }
  const [activeTab, setActiveTab] = useState(settingsOptions?.[0]);

  return (
    <>
      <Header settings />
      <Styled.FlexRow>
        <SettingsColumn
          options={settingsOptions}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === 'Event Notifications' && <EventNotifications />}
      </Styled.FlexRow>
    </>
  );
};

export default Settings;
