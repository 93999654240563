import ColorBubble from '../ui/ColorBubble';
import Colors from '../../styles/Colors';
import { Machine } from '../../types/Machine';
import React from 'react';
import Styled from '../../styles/Styles';
import routes from '../../routes';

type Props = {
  machine: Machine;
  timeFilter: string;
};

const MachineRow = ({ machine, timeFilter }: Props) => {
  const { machineName, highEvents, medEvents, lowEvents } = machine;
  const totalEvents =
    Number(highEvents) + Number(medEvents) + Number(lowEvents);

  const paramString = new URLSearchParams();
  paramString.append('machineNumber', `${machine.machineName}`);
  paramString.append('timeFilter', `${timeFilter}`);
  return (
    <Styled.Row
      onClick={() => {
        localStorage.setItem('resetPage', 'true');
        window.location.href = `${
          routes.eventsMachine
        }?${paramString.toString()}`;
      }}
    >
      <Styled.ItemType>{machineName}</Styled.ItemType>
      {totalEvents ? (
        <Styled.ItemType theme={{ flexGrow: '1' }}>
          <ColorBubble color={Colors.RED} number={highEvents} />
          <ColorBubble color={Colors.YELLOW} number={medEvents} />
          <ColorBubble color={Colors.GREEN} number={lowEvents} />
          {`${totalEvents} Total`}
        </Styled.ItemType>
      ) : (
        <Styled.ItemType
          theme={{
            width: '200px',
            justifyContent: 'center',
          }}
        >
          <Styled.CheckCircle />
        </Styled.ItemType>
      )}
    </Styled.Row>
  );
};

export default MachineRow;
