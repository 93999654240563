import React from 'react';
import RealTime from '../../pages/RealTime';
import { io } from 'socket.io-client';

const RealTimeWrapper = () => {
  const token = '';
  const socket =
    token &&
    io(process.env.REACT_APP_API_BASE_URL, {
      auth: {
        token,
      },
      transports: ['websocket'],
    });
  return socket ? <RealTime socket={socket} /> : <></>;
};

export default RealTimeWrapper;
