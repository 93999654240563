import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import { Rule, UserWhitelist } from '../types/Admin';
import Styled from '../styles/Styles';
import { toConfig, toUserWhitelist } from '../util/convertToTypes';
import { toast } from 'react-toastify';

export const fetchRules = async (count: number, navigate: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({ route: '/rules', navigate });
    return toConfig(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'admin.ts');
  }
};

export const saveRules = async (rules: Rule[], navigate: NavigateFunction) => {
  const data = { configs: rules };
  const state = await Functions.apiCall({
    route: '/save_rules',
    isPostRequest: true,
    paramsOrData: data,
    navigate,
  });
  return state;
};

export const handleSaveRules = async (
  rules: Rule[],
  afterSave: () => void,
  navigate: NavigateFunction
) => {
  try {
    await saveRules(rules, navigate);
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(`Admin Rules Saved: ${JSON.stringify(rules)}`, 'admin.ts');
    afterSave();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'admin.ts');
  }
};

export const fetchUserWhitelist = async (
  count: number,
  navigate: NavigateFunction
) => {
  try {
    const state = await Functions.apiCall({
      route: '/user_whitelist',
      navigate,
    });
    return toUserWhitelist(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'admin.ts');
  }
};

export const saveUserWhitelist = async (
  whitelist: UserWhitelist,
  afterSave: () => void,
  navigate: NavigateFunction
) => {
  try {
    const state = await Functions.apiCall({
      route: '/save_user_whitelist',
      isPostRequest: true,
      paramsOrData: { whitelist },
      navigate,
    });
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    afterSave();
    return state;
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'admin.ts');
  }
};
