import React, { useState } from 'react';

import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Nav = styled.div`
  margin: 5px 25px 5px 25px;
  font-weight: 600;
  color: ${props => props.color || Colors.BLUE_FOUR};
  ${TypeStyles.LABEL_TEXT}
  cursor: pointer;
`;

const Triangle = styled.div`
  text-align: center;
`;

type Props = {
  children: React.ReactNode;
  active: boolean;
  onClick?: () => any;
};

const NavItem = ({ onClick, active, children }: Props) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <Nav
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={onClick}
      color={(isShown || active) && 'white'}
    >
      {children}
      {(isShown || active) && (
        <Triangle>
          <Styled.Triangle />
        </Triangle>
      )}
    </Nav>
  );
};

export default NavItem;
