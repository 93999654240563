import {
  CouchShiftOptions,
  FullCouchShiftPlotResponse,
  MachineOverrideRates,
  OverridePercentiles,
} from '../types/Insight';
import {
  Event,
  Events,
  NoteResponse,
  NoteTextResponse,
  PlotData,
} from '../types/Event';
import { FinalPatients, Hospitals } from '../types/Checklist';
import { NoteTypeResponse, Rule, UserWhitelistResponse } from '../types/Admin';
import { Patient, Patients, UnreviewedImagePatients } from '../types/Patient';

import { ClinicStatus } from '../types/ActiveRule';
import { Counts } from '../types/Counts';
import { EventNotifyConfig } from '../types/Settings';
import { Machines } from '../types/Machine';
import { PatientInfo } from '../types/PatientInfo';
import { RealTimeResponse } from '../types/RealTime';
import { GenerateReportResponse } from '../types/Report';

export const toEvent = (response: any) => response as Event;

export const toEvents = (response: any) => response as Events;

export const toPlot = (response: any) => response?.plot as PlotData[];

export const toPatient = (response: any) => response?.patient as Patient;

export const toPatients = (response: any) => response as Patients;

export const toMachines = (response: any) => response as Machines;

export const toCounts = (response: any) => response as Counts;

export const toConfig = (response: any) => response as Rule[];

export const toClinicStatus = (response: any) => response as ClinicStatus;

export const toPatientInfo = (response: any) => response as PatientInfo;

export const toRealTimeResponse = (response: any) =>
  response as RealTimeResponse;

export const toOverrideRates = (response: any) =>
  response as MachineOverrideRates[];

export const toOverridePercentiles = (response: any) =>
  response as OverridePercentiles;

export const toEventNotifyConfig = (response: any) =>
  response as EventNotifyConfig;

export const toHospitals = (response: any) => response as Hospitals;

export const toFinalPatients = (response: any) => response as FinalPatients;

export const toWeeklyPatients = (response: any) => response as FinalPatients;

export const toCouchShiftOptions = (response: any) =>
  response as CouchShiftOptions;

export const toCouchShiftPlotResponse = (response: any) =>
  response as FullCouchShiftPlotResponse;

export const toUnreviewedImagePatients = (response: any) =>
  response as UnreviewedImagePatients;

export const toNoteResponse = (response: any) => response as NoteResponse;

export const toNoteTextResponse = (response: any) =>
  response as NoteTextResponse;

export const toNoteTypeConfig = (response: any) => response as NoteTypeResponse;

export const toUserWhitelist = (response: any) =>
  response as UserWhitelistResponse;

export const toGenerateReportResponse = (response: any) =>
  response as GenerateReportResponse;
