import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import Functions from '../../util/UtilityFunctions';
import MenuComponents from '../../styles/MenuComponents';
import RadioButton from '../ui/RadioButton';
import Styled from '../../styles/Styles';
import { TimeFilterOptions } from '../../util/Options';
import moment from 'moment';

type Option = {
  value: number;
  label: string | JSX.Element;
};

type Props = {
  options: Option[];
  title?: string;
  isVerified?: boolean;
  onChange?: (checked: number) => void;
  isDisabled?: boolean;
  initialFilter?: number;
  customStartDate?: Date;
  customEndDate?: Date;
  setCustomStartDate?: (date: Date) => void;
  setCustomEndDate?: (date: Date) => void;
  disableCustom?: boolean;
};

const {
  SelectedBorder,
  UnselectedBorder,
  Menu,
  Chevron,
  CloseIcon,
  MenuTitle,
  OptionRow,
  Selected,
} = MenuComponents;

const RadioMenu = ({
  title,
  options,
  isVerified,
  onChange,
  isDisabled,
  initialFilter,
  customStartDate,
  customEndDate,
  setCustomStartDate,
  setCustomEndDate,
}: Props) => {
  const [menuShown, setMenuShown] = useState(false);
  const [checked, setChecked] = useState(initialFilter || 0);
  const ref = useRef();
  Functions.useOnClickOutside(ref, () => setMenuShown(false));

  const handleCheck = value => {
    setChecked(value);
    onChange(value);
  };

  let menuBorder = (
    <UnselectedBorder
      id={`${title}Filter`}
      disabled={isDisabled}
      onClick={() => setMenuShown(!menuShown)}
    >
      <Selected>{options[checked].label}</Selected>
      <Chevron>
        <Styled.ChevronDown height="18" width="18" />
      </Chevron>
    </UnselectedBorder>
  );

  if (isVerified) {
    menuBorder = (
      <SelectedBorder
        id={`${title}Filter`}
        disabled={isDisabled}
        onClick={() => setMenuShown(!menuShown)}
      >
        <Selected>{options[checked].label}</Selected>
        <Chevron>
          <Styled.ChevronDown height="18" width="18" />
        </Chevron>
      </SelectedBorder>
    );
  }

  const isCustom =
    customStartDate &&
    customEndDate &&
    setCustomStartDate &&
    setCustomEndDate &&
    checked === TimeFilterOptions.CUSTOM;

  const [error, setError] = useState(false);
  const handleError = () => {
    setError(true);
    setTimeout(() => setError(false), 5000);
  };
  return (
    <Styled.FlexColumn style={{ position: 'relative' }}>
      <div>{menuBorder}</div>
      <div ref={ref}>
        {menuShown && (
          <Menu>
            <div>
              <MenuTitle>
                {title}
                <CloseIcon onClick={() => setMenuShown(false)}>
                  <Styled.CloseIcon />
                </CloseIcon>
              </MenuTitle>
            </div>
            {options.map((option: Option, index) => (
              <OptionRow
                id={`option${index}`}
                onClick={() => handleCheck(option.value)}
              >
                <RadioButton isChecked={Number(checked) === option.value} />
                {option.label}
              </OptionRow>
            ))}
            {isCustom && (
              <OptionRow>
                <DatePicker
                  selected={customStartDate}
                  onChange={date => {
                    if (moment(date).isSameOrBefore(moment(customEndDate))) {
                      setCustomStartDate(date);
                      setError(false);
                    } else {
                      handleError();
                    }
                  }}
                />
                &nbsp;to&nbsp;
                <DatePicker
                  selected={customEndDate}
                  onChange={date => {
                    if (moment(date).isSameOrAfter(moment(customStartDate))) {
                      setCustomEndDate(date);
                      setError(false);
                    } else {
                      handleError();
                    }
                  }}
                />
              </OptionRow>
            )}
            {error && (
              <OptionRow style={{ color: 'red' }}>
                To date must come after from date.
              </OptionRow>
            )}
          </Menu>
        )}
      </div>
    </Styled.FlexColumn>
  );
};

const VerificationMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <RadioMenu
      title="Verification"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const TimeMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
  customStartDate,
  customEndDate,
  setCustomStartDate,
  setCustomEndDate,
  disableCustom,
}: Props) => {
  return (
    <RadioMenu
      title="Time"
      options={
        disableCustom
          ? options.filter(option => option.value !== TimeFilterOptions.CUSTOM)
          : options
      }
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
      customStartDate={customStartDate}
      customEndDate={customEndDate}
      setCustomStartDate={setCustomStartDate}
      setCustomEndDate={setCustomEndDate}
    />
  );
};

const MetricsMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <RadioMenu
      title="Metrics"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const InstitutionsMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <RadioMenu
      title="Institutions"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const SitesMenu = ({ options, onChange, isDisabled, initialFilter }: Props) => {
  return (
    <RadioMenu
      title="Sites"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

export { VerificationMenu, TimeMenu, MetricsMenu, InstitutionsMenu, SitesMenu };

export default RadioMenu;
