import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const CardBorder = styled(Styled.BorderBox)`
  height: 97px;
  width: 250px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background-color: ${Colors.GRAY_EIGHT};
  cursor: pointer;
`;

const NumberContainer = styled(Styled.FlexColumn)`
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => props.color || 'black'};
`;

const LabelContainer = styled(Styled.FlexColumn)`
  color: ${Colors.GRAY_TWO};
  ${TypeStyles.LABEL_TEXT}
`;

type Props = {
  color?: string;
  number: string;
  label: string;
  onClick?: () => void;
  id?: string;
};

const DashboardCard = ({ color, number, label, onClick, id }: Props) => (
  <CardBorder onClick={onClick} id={id}>
    <NumberContainer color={color}>{number || '0'}</NumberContainer>
    <LabelContainer>{label}</LabelContainer>
  </CardBorder>
);

export default DashboardCard;
