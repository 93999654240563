import React, { useRef, useState } from 'react';
import { fetchEmail, fetchIsAdmin, signOut } from '../../api/auth';

import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import LogoutModal from '../ui/LogoutModal';
import Options from '../../util/Options';
import PriorityBubble from '../ui/PriorityBubble';
import RulesChecked from '../../pages/RulesChecked';
import Styled from '../../styles/Styles';
import Tooltip from '../ui/Tooltip';
import TypeStyles from '../../styles/TypeStyles';
import { fetchActiveRules } from '../../api/event';
import logo from '../../assets/logo.png';
import routes from '../../routes';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const TitleBlock = styled(Styled.FlexRow)`
  height: 54px;
  background-color: ${Colors.BLUE_ONE};
  padding: 15px 15px 15px 15px;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 27px;
  min-width: 1700px;
`;

const ClinicTextBlock = styled.div`
  font-size: 14px;
  position: relative;
  left: 20px;
  top: 2px;
`;

const Logo = styled.div`
  width: 138px;
  height: 18px;
  position: relative;
  top: 4px;
`;

const User = styled(Styled.FlexRow)`
  height: 25px;
  align-items: flex-end;
  ${TypeStyles.LABEL_TEXT}
`;

const UserPopup = styled.div`
  color: ${Colors.GRAY_TWO};
  background-color: white;
  width: 170px;
  font-size: 12px;
  line-height: 17px;
  padding: 13px 13px 13px 13px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
`;

const UserItem = styled(Styled.FlexRow)`
  cursor: pointer;
  color: ${Colors.GRAY_TWO};
  align-items: center;
  height: 20px;
`;

const Triangle = styled.div`
  position: relative;
  top: 7px;
  left: 75px;
  z-index: 1;
  width: 30px;
`;

const TooltipContainer = styled.a`
  margin-right: 20px;
  width: 150px;
  padding-top: 2px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

const TooltipText = styled(Styled.FlexRow)`
  position: absolute;
  text-decoration: underline;
  font: bold;
  font-size: 12px;
  line-height: 17px;
  margin-top: 5px;
  margin-left: 25px;
`;

const LastUpdated = styled.div`
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
`;

type Props = {
  admin?: boolean;
  settings?: boolean;
};

const Header = ({ admin, settings }: Props) => {
  const navigate = useNavigate();

  const [isShown, setIsShown] = useState(false);
  const ref = useRef();
  Functions.useOnClickOutside(ref, () => setIsShown(false));

  const { data: isAdmin } = usePromise(fetchIsAdmin, []);
  const { data: email } = usePromise(fetchEmail, []);
  const userClinicId = localStorage.getItem('userClinicId');
  const userClinicName = localStorage.getItem('userClinicName');

  const { data: status } = usePromise(fetchActiveRules, [navigate]);
  const activeRules = status?.rules;
  const { lastUpdated, warning } = Functions.getLastUpdated(status?.lastUpdate);

  const numRules =
    activeRules === undefined ? '-' : String(activeRules?.length);

  const [rulesCheckedShown, setRulesCheckedShown] = useState(false);
  const [logoutModalShown, setLogoutModalShown] = useState(false);

  const doNotShow = Boolean(localStorage.getItem('doNotShowLogOutModal'));

  const { ruleOptions } = Options;

  return (
    <>
      <TitleBlock>
        <Styled.FlexRow>
          <Styled.StyledLink to={routes.home} style={{ height: '30px' }}>
            <Logo>
              <img
                src={logo}
                alt="logo"
                style={{ width: '100%', height: '100%' }}
              />
            </Logo>
          </Styled.StyledLink>
          <Styled.Divider />
          Chartalert
          {userClinicName && (
            <ClinicTextBlock>@ {userClinicName}</ClinicTextBlock>
          )}
        </Styled.FlexRow>
        <Styled.FlexRow>
          <TooltipContainer
            id="rulesChecked"
            onClick={() => setRulesCheckedShown(true)}
          >
            <Tooltip color={Colors.GREEN} warning={warning}>
              {' '}
              {activeRules
                ?.sort((a, b) => a.priority - b.priority)
                .map((rule) => (
                  <Styled.ActiveRuleRow>
                    <PriorityBubble priority={rule.priority} />
                    {ruleOptions?.[rule.name]?.name}
                  </Styled.ActiveRuleRow>
                ))}
              <LastUpdated>{lastUpdated}</LastUpdated>
            </Tooltip>
            <TooltipText>{numRules} Checking Rules</TooltipText>
          </TooltipContainer>
          <Styled.FlexColumn
            style={{
              height: '200px',
              alignItems: 'flex-end',
              minWidth: '250px',
            }}
          >
            <User
              style={{ cursor: 'pointer' }}
              onClick={() => setIsShown(!isShown)}
            >
              {email}
              <Styled.ChevronDown height="18" width="18" />
            </User>
            {isShown && (
              <div ref={ref} style={{ zIndex: 5 }}>
                <Triangle>
                  <Styled.Triangle />
                </Triangle>
                <UserPopup style={{ float: 'right' }}>
                  {isAdmin && (
                    <>
                      <Styled.StyledLink
                        to={admin ? routes.home : routes.admin}
                      >
                        <UserItem>
                          Switch to {admin ? 'Provider' : 'Admin'} View
                        </UserItem>
                      </Styled.StyledLink>
                      <Styled.Line
                        style={{ marginTop: '13px', marginBottom: '13px' }}
                      />
                    </>
                  )}
                  {userClinicId && (
                    <>
                      <Styled.StyledLink to={routes.selectClinic}>
                        <UserItem>Switch Clinic</UserItem>
                      </Styled.StyledLink>
                      <Styled.Line
                        style={{ marginTop: '13px', marginBottom: '13px' }}
                      />
                    </>
                  )}
                  <>
                    <Styled.StyledLink
                      to={settings ? routes.home : routes.settings}
                    >
                      <UserItem>
                        {settings ? (
                          <>Back to home</>
                        ) : (
                          <>
                            <Styled.SettingsIcon />
                            &nbsp; Settings
                          </>
                        )}
                      </UserItem>
                    </Styled.StyledLink>
                    <Styled.Line
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    />
                  </>
                  <>
                    <UserItem
                      onClick={() => {
                        window.location.href =
                          'https://docs.google.com/document/d/e/2PACX-1vTGuToPwxIPlPNASRXU-DKZXze_gg6M4KmkErqBRNIiqChGxVJbh68Lznd_jK1zexMWWkjPj-RDxuUg/pub';
                        return null;
                      }}
                    >
                      <>
                        <Styled.HelpIcon />
                        &nbsp; Help
                      </>
                    </UserItem>
                    <Styled.Line
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    />
                  </>
                  <UserItem
                    onClick={() =>
                      doNotShow ? signOut(navigate) : setLogoutModalShown(true)
                    }
                  >
                    Log Out
                  </UserItem>
                </UserPopup>
              </div>
            )}
          </Styled.FlexColumn>
        </Styled.FlexRow>
      </TitleBlock>
      {rulesCheckedShown && (
        <RulesChecked onCloseClick={() => setRulesCheckedShown(false)} />
      )}
      {logoutModalShown && (
        <LogoutModal
          logout={() => {
            signOut(navigate);
          }}
          onCloseClick={() => setLogoutModalShown(false)}
        />
      )}
    </>
  );
};

export default Header;
