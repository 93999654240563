import React, { useEffect, useState } from 'react';
import {
  fetchFinalPatients,
  fetchHospitals,
  fetchWeeklyPatients,
} from '../api/checklist';

import Checklist from '../components/checklist/Checklist';
import { PAGE_LENGTH } from '../components/ui/ShowingText';
import Styled from '../styles/Styles';
import { fetchFeatureFlags } from '../api/auth';
import { fetchPatientInfo } from '../api/patient';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const FinalChecklist = () => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];
  const { data: hospitalData } = usePromise(fetchHospitals, [navigate]);
  const initialHospitalOptions = [{ value: 0, label: 'No Hospitals' }];
  const [hospitalOptions, setHospitalOptions] = useState(
    initialHospitalOptions
  );
  const initialHospital = localStorage.getItem('finalHospital');
  const initialWeeklyHospital = localStorage.getItem('weeklyHospital');
  const [hospital, setHospital] = useState(initialHospital || '');
  const [weeklyHospital, setWeeklyHospital] = useState(
    initialWeeklyHospital || ''
  );
  const savedPageNumber = Number(localStorage.getItem('checklistPage'));
  const savedWeeklyPageNumber = Number(
    localStorage.getItem('weeklyChecklistPage')
  );
  const [pageNumber, setPageNumber] = useState(savedPageNumber || 0);
  const [weeklyPageNumber, setWeeklyPageNumber] = useState(
    savedWeeklyPageNumber || 0
  );
  const [pageLength, setPageLength] = useState(
    JSON.parse(localStorage.getItem('checklistPageLength')) || PAGE_LENGTH[0]
  );
  const [weeklyPageLength, setWeeklyPageLength] = useState(
    JSON.parse(localStorage.getItem('weeklyChecklistPageLength')) ||
      PAGE_LENGTH[0]
  );
  const [today, setToday] = useState(new Date());
  const [sortDescending, setSortDescending] = useState(true);
  const [weeklySortDescending, setWeeklySortDescending] = useState(true);

  const { data: patientData, loading: patientDataLoading } = usePromise(
    fetchFinalPatients,
    [pageNumber, pageLength, today, hospital, sortDescending, navigate]
  );
  const patients = patientData?.patients;
  const [clinicPatientIds, setClinicPatientIds] = useState([]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [
    clinicPatientIds,
    disableMappingService,
  ]);
  const patientInfo = patientInfoResponse?.data;
  const { data: weeklyPatientData, loading: weeklyPatientDataLoading } =
    usePromise(fetchWeeklyPatients, [
      weeklyPageNumber,
      weeklyPageLength,
      today,
      weeklyHospital,
      weeklySortDescending,
      navigate,
    ]);
  const weeklyPatients = weeklyPatientData?.patients;
  const [weeklyClinicPatientIds, setWeeklyClinicPatientIds] = useState([]);
  const weeklyPatientInfoResponse = usePromise(fetchPatientInfo, [
    weeklyClinicPatientIds,
    disableMappingService,
  ]);
  const weeklyPatientInfo = weeklyPatientInfoResponse?.data;
  const handleHospitalChange = (checked: number) => {
    setHospital(hospitalData.hospitals[checked]);
  };
  const handleWeeklyHospitalChange = (checked: number) => {
    setWeeklyHospital(hospitalData.hospitals[checked]);
  };

  const handlePageLengthChange = (num: number) => {
    setPageLength(num);
    localStorage.setItem('checklistPageLength', String(num));
    setPageNumber(0);
  };
  const handleWeeklyPageLengthChange = (num: number) => {
    setWeeklyPageLength(num);
    localStorage.setItem('weeklyChecklistPageLength', String(num));
    setWeeklyPageNumber(0);
  };

  useEffect(() => {
    setClinicPatientIds(patients?.map((patient) => patient.clinicPatientId));
  }, [patients]);
  useEffect(() => {
    setWeeklyClinicPatientIds(
      weeklyPatients?.map((patient) => patient.clinicPatientId)
    );
  }, [weeklyPatients]);

  useEffect(() => {
    if (hospitalData?.hospitals?.filter(Boolean).length) {
      const newHospitalOptions = hospitalData?.hospitals?.map(
        (institution, index) => ({
          value: index,
          label: institution,
        })
      );
      if (!initialHospital) {
        setHospital(hospitalData.hospitals[0]);
      }
      if (!initialWeeklyHospital) {
        setWeeklyHospital(hospitalData.hospitals[0]);
      }
      setHospitalOptions(newHospitalOptions);
    }
  }, [hospitalData]);
  useEffect(() => localStorage.setItem('finalHospital', hospital), [hospital]);
  useEffect(
    () => localStorage.setItem('weeklyHospital', weeklyHospital),
    [weeklyHospital]
  );

  useEffect(() => {
    localStorage.setItem('checklistPage', String(pageNumber));
  }, [pageNumber]);
  useEffect(() => {
    localStorage.setItem('weeklyChecklistPage', String(pageNumber));
  }, [weeklyPageNumber]);

  const refresh = () => {
    const newToday = new Date();
    setToday(newToday);
  };
  const initialHospitalIndex = hospitalOptions?.findIndex(
    (hos) => hos?.label === hospital
  );
  const initialWeeklyHospitalIndex = hospitalOptions?.findIndex(
    (hos) => hos?.label === weeklyHospital
  );

  return (
    <Styled.FlexRow>
      {hospitalOptions?.[0]?.label !== 'No Hospitals' && (
        <>
          <Checklist
            hospitalOptions={hospitalOptions}
            initialOption={
              initialHospitalIndex !== -1 ? initialHospitalIndex : 0
            }
            handleHospitalChange={handleHospitalChange}
            totalPatients={patientData?.totalPatients}
            pageNumber={pageNumber}
            maxPageNumber={patientData?.pages - 1}
            setPageNumber={setPageNumber}
            handlePageLengthChange={handlePageLengthChange}
            pageLength={pageLength}
            refresh={refresh}
            sortDescending={sortDescending}
            setSortDescending={setSortDescending}
            patients={patients}
            patientInfo={patientInfo}
            patientDataLoading={patientDataLoading}
            final
          />
          <Checklist
            hospitalOptions={hospitalOptions}
            initialOption={
              initialWeeklyHospitalIndex !== -1 ? initialWeeklyHospitalIndex : 0
            }
            handleHospitalChange={handleWeeklyHospitalChange}
            totalPatients={weeklyPatientData?.totalPatients}
            pageNumber={weeklyPageNumber}
            maxPageNumber={weeklyPatientData?.pages - 1}
            setPageNumber={setWeeklyPageNumber}
            handlePageLengthChange={handleWeeklyPageLengthChange}
            pageLength={weeklyPageLength}
            refresh={refresh}
            sortDescending={weeklySortDescending}
            setSortDescending={setWeeklySortDescending}
            patients={weeklyPatients}
            patientInfo={weeklyPatientInfo}
            patientDataLoading={weeklyPatientDataLoading}
          />
        </>
      )}
    </Styled.FlexRow>
  );
};

export default FinalChecklist;
