import React, { useEffect, useRef, useState } from 'react';
import { fetchClinicIds, fetchPatientInfo } from '../api/patient';
import {
  fetchNoteText,
  fetchNoteTypes,
  fetchNotes,
  handleVerifyNote,
} from '../api/note';

import Checkbox from '../components/ui/Checkbox';
import Colors from '../styles/Colors';
import Functions from '../util/UtilityFunctions';
import ListFooter from '../components/event/ListFooter';
import NotesFooter from '../components/notes/NotesFooter';
import { NotesMenu } from '../components/ui/FilterMenu';
import NotesRow from '../components/notes/NotesRow';
import Options from '../util/Options';
import SearchBar from '../components/ui/SearchBar';
import ShowingText from '../components/ui/ShowingText';
import Styled from '../styles/Styles';
import { TimeMenu } from '../components/ui/RadioMenu';
import { fetchFeatureFlags } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const { timeOptions, getOptions } = Options;

const Notes = () => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];
  const savedNotesPage = Number(localStorage.getItem('notesPage'));
  const [pageNumber, setPageNumber] = useState(savedNotesPage || 0);
  const savedNotesSize = Number(localStorage.getItem('notesSize'));
  const [pageSize, setPageSize] = useState(savedNotesSize || 10);

  const savedTimeFilter = Number(localStorage.getItem('notesTimeFilter'));
  const [timeFilter, setTimeFilter] = useState(savedTimeFilter || 0);
  const handleTimeChange = (checked: number) => {
    setTimeFilter(checked);
  };
  const day = Functions.timeFilterToApiDate(Number(timeFilter));
  const savedSearchedIds = JSON.parse(localStorage.getItem('notesSearchedIds'));
  const [activeIds, setActiveIds] = useState(savedSearchedIds || []);

  const [activeNoteIndex, setActiveNoteIndex] = useState(0);

  const { data: noteTypeData } = usePromise(fetchNoteTypes, [navigate]);
  const types = noteTypeData?.noteTypes;
  const savedNoteFilter = JSON.parse(localStorage.getItem('notesTypeFilter'));
  const initialNoteFilter = savedNoteFilter?.length
    ? types?.map((type) => savedNoteFilter.includes(type))
    : types?.map(() => true);
  const [activeTypes, setActiveTypes] = useState(savedNoteFilter || []);

  const savedMappingSearchTerm = localStorage.getItem('notesMappingSearchTerm');
  const initialSavedMappingSearchTerm = savedMappingSearchTerm
    ? [savedMappingSearchTerm]
    : undefined;
  const [searchTerm, setSearchTerm] = useState(savedMappingSearchTerm || '');
  const [mappingSearchTerm, setMappingSearchTerm] = useState<string[]>(
    initialSavedMappingSearchTerm
  );
  const { data: clinicIds } = usePromise(fetchClinicIds, [mappingSearchTerm]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearch = () => {
    const mrns = Functions.extractMRNs(searchTerm);
    setMappingSearchTerm(mrns || (searchTerm ? [searchTerm] : undefined));
    if (mrns) {
      setSearchTerm(String(mrns));
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (clinicIds !== undefined && !isFirstRun.current) {
      setActiveIds(clinicIds);
      localStorage.setItem('notesSearchedIds', JSON.stringify(clinicIds));
    }
  }, [clinicIds]);
  const [count, setCount] = useState(0);
  const handleRefresh = () => {
    setCount(count === 10 ? 0 : count + 1);
  };
  const noteTypeOptions = types && getOptions(types);
  const { data: noteData } = usePromise(fetchNotes, [
    pageNumber,
    pageSize,
    JSON.stringify(activeIds),
    day,
    undefined,
    JSON.stringify(activeTypes),
    true,
    count,
    navigate,
  ]);
  const pages: number = noteData?.pages;
  const totalNotes: number = noteData?.totalNotes;

  const minNoteNumber: number = pageNumber * pageSize + 1;
  const maxPageNumber: number = pages - 1;
  const maxNoteNumber: number =
    pageNumber === maxPageNumber ? totalNotes : minNoteNumber + pageSize - 1;

  const noneSelected =
    noteData &&
    Object.fromEntries(noteData?.notes.map((note) => [note.id, false]));
  const [selectedNotes, setSelectedNotes] = useState(noneSelected);
  const noSelectedNotes: boolean =
    selectedNotes &&
    Object.values(selectedNotes).every((selected) => !selected);
  const handleSelectAll = () => {
    const selected = Object.fromEntries(
      noteData?.notes.map((note) => [note.id, true])
    );
    setSelectedNotes(selected);
  };
  const handleClearAll = () => {
    setSelectedNotes(noneSelected);
  };
  const handleSelectNote = (index) => {
    const newSelectedNotes = selectedNotes;
    newSelectedNotes[index] = !selectedNotes[index];
    setSelectedNotes({ ...newSelectedNotes });
  };
  const numberSelectedNotes: number = selectedNotes
    ? Object.values(selectedNotes).reduce(
        (a: number, note) => a + (note ? 1 : 0),
        0
      )
    : 0;
  const handleVerifyNotes = (isUnverify: boolean) => {
    const noteIds: string[] = Object.keys(selectedNotes).filter(
      (key) => selectedNotes[key]
    );
    const afterVerify = () => {
      setSelectedNotes(noneSelected);
      handleRefresh();
    };
    handleVerifyNote(noteIds, afterVerify, isUnverify);
  };
  const selectedKeys =
    selectedNotes &&
    Object.keys(selectedNotes).filter((key) => selectedNotes[key]);
  const isUnverify: boolean =
    Boolean(selectedKeys?.length) &&
    noteData?.notes
      ?.filter((note) => selectedKeys.includes(String(note.id)))
      .every((note) => !!note.verifiedAt);

  const noteKeys = noteData?.notes?.map((note) => note.clinicNoteId) || [];
  const { data: noteTextData, loading: noteTextLoading } = usePromise(
    fetchNoteText,
    [JSON.stringify(noteKeys), disableMappingService]
  );
  const notes = noteTextData;
  const noteObject = noteData?.notes.map((note) => ({
    ...note,
    noteText: notes?.[String(note.clinicNoteId)],
  }));

  const handleTypeChange = (checked: boolean[]) => {
    const newActiveTypes = [];
    if (!checked.every((check) => check)) {
      checked.forEach((check, index) => {
        if (check) {
          newActiveTypes.push(types[index]);
        }
      });
    }
    setActiveTypes(newActiveTypes);
  };

  const [notesActive, setNotesActive] = useState(false);
  const [clinicPatientIds, setClinicPatientIds] = useState([]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [
    clinicPatientIds,
    disableMappingService,
  ]);
  const patientInfo = patientInfoResponse?.data;

  useEffect(() => {
    localStorage.setItem('notesTimeFilter', String(timeFilter));
    setPageNumber(0);
  }, [timeFilter]);
  useEffect(() => {
    localStorage.setItem('notesPage', String(pageNumber));
  }, [pageNumber]);
  useEffect(() => {
    localStorage.setItem('notesSize', String(pageSize));
  }, [pageSize]);
  useEffect(() => {
    localStorage.setItem('notesTypeFilter', JSON.stringify(activeTypes));
    setPageNumber(0);
  }, [activeTypes]);
  useEffect(() => {
    setClinicPatientIds(noteData?.notes?.map((note) => note.clinicPatientId));
    handleClearAll();
  }, [noteData]);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });
  useEffect(() => {
    localStorage.setItem(
      'notesMappingSearchTerm',
      mappingSearchTerm?.join() || ''
    );
    if (!mappingSearchTerm && !searchTerm) {
      if (!isFirstRun.current || initialSavedMappingSearchTerm) {
        setActiveIds([]);
      }
    }
    if (!isFirstRun.current) {
      setPageNumber(0);
    }
  }, [mappingSearchTerm]);
  return (
    <>
      <Styled.FilterBar>
        <SearchBar
          value={searchTerm}
          onChange={handleSearchChange}
          handleSearch={handleSearch}
        />
        <TimeMenu
          options={timeOptions}
          onChange={handleTimeChange}
          initialFilter={savedTimeFilter || 0}
          isDisabled={false}
          disableCustom
        />
        {noteTypeOptions && (
          <NotesMenu
            options={noteTypeOptions}
            onChange={handleTypeChange}
            initialFilter={initialNoteFilter}
            isDisabled={false}
          />
        )}
        <ShowingText
          minNumber={minNoteNumber}
          maxNumber={maxNoteNumber}
          totalNumber={totalNotes}
          pageNumber={pageNumber}
          maxPageNumber={maxPageNumber}
          onLeftClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
          onRightClick={() =>
            pageNumber < maxPageNumber && setPageNumber(pageNumber + 1)
          }
          handlePageLengthChange={(num) => {
            setPageSize(num);
            setPageNumber(0);
          }}
          pageLength={pageSize}
          notes
        />
      </Styled.FilterBar>
      {!!numberSelectedNotes && (
        <ListFooter
          number={numberSelectedNotes}
          onClearClick={handleClearAll}
          onClick={() => handleVerifyNotes(isUnverify)}
          isOppositeClick={isUnverify}
          notes
        />
      )}
      <Styled.TitleRow>
        <Styled.TitleRowType theme={{ flexBasis: '45px' }}>
          <Checkbox
            isChecked={selectedNotes && !noSelectedNotes}
            onClick={noSelectedNotes ? handleSelectAll : handleClearAll}
            isSelectAll
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
          NAME&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '80px' }}>
          MRN&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
          LAST UPDATED
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '150px' }}>
          TYPE
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '250px' }}>
          VERIFIED
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexGrow: '1', flexShrink: '1' }}>
          NOTE
        </Styled.TitleRowType>
      </Styled.TitleRow>
      <Styled.Line
        color={Colors.ADDITIONAL_BLUE_GRAY}
        style={{ minWidth: '1730px' }}
      />
      <div>
        {noteTextData ? (
          noteObject?.map((obj, index) => (
            <NotesRow
              note={obj}
              disableMappingService={disableMappingService}
              onClick={() => {
                if (!notesActive) {
                  setNotesActive(true);
                } else if (index === activeNoteIndex) {
                  setNotesActive(false);
                }
                setActiveNoteIndex(index);
              }}
              patientInfo={patientInfo?.[obj.clinicPatientId]}
              isHighlighted={index === activeNoteIndex && notesActive}
              handleNoteRefresh={handleRefresh}
              checkboxClick={() => handleSelectNote(obj.id)}
              isChecked={selectedNotes?.[obj.id]}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      {noteData && noteTextData && notesActive ? (
        <NotesFooter
          notes={[noteObject?.[activeNoteIndex]]}
          loading={noteTextLoading}
          disableMappingService={disableMappingService}
          handleClose={() => {
            setNotesActive(false);
          }}
          activeId={activeIds?.[0]}
          patientInfo={
            patientInfo?.[noteData?.notes[activeNoteIndex]?.clinicPatientId]
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Notes;
