import Functions, { filter } from '../util/UtilityFunctions';
import React, { useEffect, useRef, useState } from 'react';
import ShowingText, { PAGE_LENGTH } from '../components/ui/ShowingText';
import { TimeMenu, VerificationMenu } from '../components/ui/RadioMenu';
import {
  fetchClinicIds,
  fetchPatientInfo,
  fetchPatients,
} from '../api/patient';

import Colors from '../styles/Colors';
import Error from '../pages/Error';
import Log from '../Log';
import Options from '../util/Options';
import { Patient } from '../types/Patient';
import PatientRow from '../components/patient/PatientRow';
import { PrioritiesMenu } from '../components/ui/FilterMenu';
import SearchBar from '../components/ui/SearchBar';
import SortArrow from '../components/ui/SortArrow';
import Styled from '../styles/Styles';
import ToggleSwitch from '../components/ui/ToggleSwitch';
import TypeStyles from '../styles/TypeStyles';
import { fetchFeatureFlags } from '../api/auth';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const Divider = styled.div`
  position: relative;
  top: 4px;
`;

const BillingSwitch = styled(Styled.FlexRow)`
  align-self: center;
`;

const BillingSwitchText = styled.div`
  margin: 0px 12px 0px 12px;
  align-self: center;
  color: ${Colors.GRAY_TWO};
  ${TypeStyles.LABEL_TEXT};
`;

const { verifiedOptions, priorityOptions, timeOptions } = Options;

type Props = {
  billing?: boolean;
  unverified?: boolean;
};

const Patients = ({ billing, unverified }: Props) => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];
  console.log(featureFlags);

  const savedPageNumber = Number(localStorage.getItem('patientsPage'));
  const [pageNumber, setPageNumber] = useState(savedPageNumber || 0);
  const [pageLength, setPageLength] = useState(
    JSON.parse(localStorage.getItem('patientsPageLength')) || PAGE_LENGTH[0]
  );

  const savedPatientsTimeFilter =
    unverified || billing
      ? 3
      : Number(localStorage.getItem('patientsTimeFilter')) !== undefined
      ? Number(localStorage.getItem('patientsTimeFilter'))
      : 3;
  const savedApiFilter: filter = JSON.parse(
    localStorage.getItem('patientsFilter')
  );
  const defaultApiFilter: filter = {
    verified: false,
    sortDescending: true,
    sortBy: 'priority',
    priority: ['high', 'med', 'low'],
  };
  const initialApiFilter = unverified
    ? defaultApiFilter
    : billing
    ? { ...defaultApiFilter, verified: true, priority: undefined }
    : savedApiFilter || defaultApiFilter;
  const [initialApiFilterOptions, setInitialApiFilterOptions] = useState(
    Functions.getInitialApiFilterOptions(
      initialApiFilter,
      [],
      savedPatientsTimeFilter
    )
  );
  const savedMappingSearchTerm = localStorage.getItem(
    'patientsMappingSearchTerm'
  );
  const initialSavedMappingSearchTerm = savedMappingSearchTerm
    ? savedMappingSearchTerm.split(',')
    : undefined;

  const [apiFilter, setApiFilter] = useState(initialApiFilter);
  const [searchTerm, setSearchTerm] = useState(savedMappingSearchTerm || '');
  const [readyForBilling, setReadyForBilling] = useState(billing);
  const [verified, setVerified] = useState(initialApiFilterOptions.verified);
  const [priorities, setPriorities] = useState(
    initialApiFilterOptions.priority
  );
  const [time, setTime] = useState(initialApiFilterOptions.time);

  if (
    (savedApiFilter &&
      Functions.timeFilterToApiDate(savedPatientsTimeFilter) !==
        savedApiFilter?.eventAfter) ||
    Functions.timeFilterToApiDate(time) !== apiFilter.eventAfter
  ) {
    initialApiFilter.eventAfter = Functions.timeFilterToApiDate(
      savedPatientsTimeFilter
    );
  }

  const [mappingSearchTerm, setMappingSearchTerm] = useState<string[]>(
    initialSavedMappingSearchTerm
  );
  const {
    data: clinicIds,
    loading: clinicIdsLoading,
    error: clinicIdsError,
  } = usePromise(fetchClinicIds, [mappingSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearch = () => {
    const mrns = Functions.extractMRNs(searchTerm);
    setMappingSearchTerm(mrns || (searchTerm ? [searchTerm] : undefined));
    if (mrns) {
      setSearchTerm(String(mrns));
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (clinicIds !== undefined && !isFirstRun.current) {
      const list = clinicIds.join();
      const newFilter = {
        ...apiFilter,
        clinicPatientId: list,
      };
      if (!mappingSearchTerm && !searchTerm) {
        delete newFilter.clinicPatientId;
      }
      setApiFilter(newFilter);
    }
  }, [clinicIds]);

  const handleVerifiedChange = (checked: number) => {
    setVerified(checked);
    const newFilter: filter = Functions.handleVerifiedChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
    setInitialApiFilterOptions(
      Functions.getInitialApiFilterOptions({ ...newFilter }, [])
    );
  };

  const handlePriorityChange = (checked: boolean[]) => {
    setPriorities(checked);
    const newFilter: filter = Functions.handlePriorityChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
    setInitialApiFilterOptions(
      Functions.getInitialApiFilterOptions({ ...newFilter }, [])
    );
  };

  const handleTimeChange = (checked: number) => {
    setTime(checked);
    const newFilter: filter = Functions.handleTimeChange(apiFilter, checked);
    setApiFilter({ ...newFilter });
    setInitialApiFilterOptions(
      Functions.getInitialApiFilterOptions({ ...newFilter }, [])
    );
  };

  const handleSetSortBy = (sortBy: string) => {
    const newFilter: filter = Functions.handleSetSortBy(apiFilter, sortBy);
    setApiFilter({ ...newFilter });
  };

  const handleReadyForBillingChange = () => {
    setReadyForBilling(!readyForBilling);
    const newFilter: filter = readyForBilling
      ? Functions.getPatientsFilter(apiFilter, verified, priorities, time)
      : {
          ...apiFilter,
          verified: true,
          priority: undefined,
          eventAfter: undefined,
        };
    setApiFilter({ ...newFilter });
  };

  delete apiFilter.nameOrMRN;
  const { data, loading, error } = usePromise(fetchPatients, [
    pageNumber,
    pageLength,
    apiFilter,
    navigate,
  ]);
  // const patients: Patient[] = error ? patientList : data?.patients;
  const patients: Patient[] = data?.patients;
  const [clinicPatientIds, setClinicPatientIds] = useState([]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [
    clinicPatientIds,
    disableMappingService,
  ]);
  const patientInfo = patientInfoResponse?.data;
  const pages: number = data?.pages;
  const totalPatients: number = data?.totalPatients;

  const minPatientNumber: number = pageNumber * pageLength + 1;
  const maxPageNumber: number = pages - 1;
  const maxPatientNumber: number =
    pageNumber === maxPageNumber
      ? totalPatients
      : minPatientNumber + pageLength - 1;

  useEffect(() => {
    if (!isFirstRun.current) {
      setPageNumber(0);
    }
    localStorage.setItem('patientsFilter', JSON.stringify(apiFilter));
    localStorage.setItem('patientsTimeFilter', String(time));
  }, [apiFilter]);

  useEffect(() => {
    localStorage.setItem(
      'patientsMappingSearchTerm',
      mappingSearchTerm?.join() || ''
    );
    if (!mappingSearchTerm && !searchTerm) {
      const newFilter: filter = apiFilter;
      delete newFilter.clinicPatientId;
      if (!isFirstRun.current || initialSavedMappingSearchTerm) {
        setApiFilter({ ...newFilter });
      }
    }
  }, [mappingSearchTerm]);

  useEffect(() => {
    setClinicPatientIds(patients?.map((patient) => patient.clinicPatientId));
  }, [patients]);

  useEffect(() => {
    localStorage.setItem('patientsPage', String(pageNumber));
  }, [pageNumber]);

  Log.trace(`API Filter: ${JSON.stringify(apiFilter)}`, 'Patients.tsx');
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  const handlePageLengthChange = (num: number) => {
    setPageLength(num);
    localStorage.setItem('patientsPageLength', String(num));
    setPageNumber(0);
  };

  return (
    <>
      <>
        {' '}
        <Styled.FilterBar>
          <SearchBar
            value={searchTerm}
            onChange={handleSearchChange}
            handleSearch={handleSearch}
          />
          <VerificationMenu
            options={verifiedOptions}
            onChange={handleVerifiedChange}
            isVerified
            isDisabled={readyForBilling}
            initialFilter={initialApiFilterOptions.verified}
          />
          <PrioritiesMenu
            options={priorityOptions}
            onChange={handlePriorityChange}
            isDisabled={readyForBilling}
            initialFilter={initialApiFilterOptions.priority}
          />
          <TimeMenu
            options={timeOptions}
            onChange={handleTimeChange}
            initialFilter={initialApiFilterOptions.time}
            isDisabled={readyForBilling}
            disableCustom
          />
          <Divider>
            <Styled.Divider />
          </Divider>
          <BillingSwitch>
            <BillingSwitchText>No Pending Events</BillingSwitchText>
            <ToggleSwitch
              onClick={() => handleReadyForBillingChange()}
              checked={readyForBilling}
            />
          </BillingSwitch>
          <ShowingText
            minNumber={minPatientNumber}
            maxNumber={maxPatientNumber}
            totalNumber={totalPatients}
            pageNumber={pageNumber}
            maxPageNumber={maxPageNumber}
            onLeftClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
            onRightClick={() =>
              pageNumber < maxPageNumber && setPageNumber(pageNumber + 1)
            }
            patients
            pageLength={pageLength}
            handlePageLengthChange={(num) => handlePageLengthChange(num)}
          />
        </Styled.FilterBar>
        <Styled.TitleRow>
          <Styled.TitleRowType
            theme={{ flexBasis: '100px' }}
            onClick={() => handleSetSortBy('priority')}
          >
            PRIORITY&nbsp;
            <SortArrow
              sortDescending={apiFilter.sortDescending}
              isShown={apiFilter.sortBy === 'priority'}
            />
          </Styled.TitleRowType>
          <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
            NAME&nbsp;
          </Styled.TitleRowType>
          <Styled.TitleRowType theme={{ flexBasis: '80px' }}>
            MRN&nbsp;
          </Styled.TitleRowType>
          <Styled.TitleRowType theme={{ flexBasis: '250px' }}>
            LATEST TREATMENT PLAN
          </Styled.TitleRowType>
          <Styled.TitleRowType theme={{ flexGrow: '1', flexShrink: '1' }}>
            UNVERIFIED EVENTS
          </Styled.TitleRowType>
        </Styled.TitleRow>
        <Styled.Line
          color={Colors.ADDITIONAL_BLUE_GRAY}
          style={{ minWidth: '1730px' }}
        />
        <div>
          {patients?.map((patient: Patient) => (
            <PatientRow
              patient={patient}
              readyForBilling={readyForBilling}
              patientInfo={patientInfo?.[patient.clinicPatientId]}
              disableMappingService={disableMappingService}
              timeFilter={time}
            />
          ))}
        </div>
        {!patients?.length && !loading && (
          <Styled.NoItemsAvailable>
            No Patients Available
          </Styled.NoItemsAvailable>
        )}
      </>

      {loading && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
      {error && <Error />}
    </>
  );
};

export default Patients;
