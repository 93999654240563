import Colors from '../styles/Colors';
import Dashboard from '../pages/Dashboard';
import Events from '../pages/Events';
import FinalChecklist from '../pages/FinalChecklist';
import Header from '../components/ui/Header';
import Insight from '../pages/Insight';
import NavItem from '../components/ui/NavItem';
import Notes from '../pages/Notes';
import Patients from '../pages/Patients';
import React from 'react';
import RealTimeWrapper from '../components/realTime/RealTimeWrapper';
import Styled from '../styles/Styles';
import { fetchFeatureFlags } from '../api/auth';
import routes from '../routes';
import styled from 'styled-components';
import { usePromise } from '../util/api';
import { useSearchParams } from 'react-router-dom';

const NavContainer = styled(Styled.FlexRow)`
  height: 44px;
  background-color: ${Colors.BLUE_ONE};
  min-width: 1730px;
`;

type Props = {
  startTab?: string;
  billing?: boolean;
  machineNumber?: string;
  highPriority?: boolean;
  timeFilter?: string;
  eventsUnverified?: boolean;
  patientsUnverified?: boolean;
};

const Home = ({
  startTab,
  billing,
  highPriority,
  eventsUnverified,
  patientsUnverified,
}: Props) => {
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const enableRealTime = featureFlags?.['enableRealTime'];

  const [searchParams] = useSearchParams();
  const machineNumber = searchParams.get('machineNumber');
  const timeFilter = searchParams.get('timeFilter');

  return (
    <>
      <Header />
      <NavContainer>
        <Styled.StyledLink to={routes.home}>
          <NavItem active={startTab === 'dashboard'}>DASHBOARD</NavItem>
        </Styled.StyledLink>
        <Styled.StyledLink to={routes.patients}>
          <NavItem active={startTab === 'patients'}>PATIENTS</NavItem>
        </Styled.StyledLink>
        <Styled.StyledLink to={routes.events}>
          <NavItem active={startTab === 'events'}>EVENTS</NavItem>
        </Styled.StyledLink>
        <Styled.StyledLink to={routes.finalChecklist}>
          <NavItem active={startTab === 'finalChecklist'}>
            FINAL/WEEKLY CHECK
          </NavItem>
        </Styled.StyledLink>
        {enableRealTime && (
          <Styled.StyledLink to={routes.realTime}>
            <NavItem active={startTab === 'realTime'}>
              REAL TIME CHECKING
            </NavItem>
          </Styled.StyledLink>
        )}
        <Styled.StyledLink to={routes.insight}>
          <NavItem active={startTab === 'insight'}>INSIGHT</NavItem>
        </Styled.StyledLink>
        <Styled.StyledLink to={routes.notes}>
          <NavItem active={startTab === 'notes'}>NOTES</NavItem>
        </Styled.StyledLink>
      </NavContainer>
      {startTab === 'dashboard' && <Dashboard />}
      {startTab === 'patients' && (
        <Patients billing={billing} unverified={patientsUnverified} />
      )}
      {startTab === 'events' && (
        <Events
          machineNumber={machineNumber}
          highPriority={highPriority}
          timeFilter={timeFilter}
          unverified={eventsUnverified}
        />
      )}
      {startTab === 'realTime' &&
        (enableRealTime ? (
          <RealTimeWrapper />
        ) : (
          <Styled.NoItemsAvailable>
            No Real Time Available
          </Styled.NoItemsAvailable>
        ))}
      {startTab === 'insight' && <Insight />}
      {startTab === 'finalChecklist' && <FinalChecklist />}
      {startTab === 'notes' && <Notes />}
    </>
  );
};

export default Home;
