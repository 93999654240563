import React, { useEffect, useState } from 'react';
import { fetchPatient, fetchPatientInfo } from '../api/patient';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Functions from '../util/UtilityFunctions';
import Header from '../components/ui/Header';
import Styled from '../styles/Styles';
import TreatmentCard from '../components/patient/TreatmentCard';
import TreatmentEvents from '../components/patient/TreatmentEvents';
import TypeStyles from '../styles/TypeStyles';
import { fetchFeatureFlags } from '../api/auth';
import routes from '../routes';
import styled from 'styled-components';
import { usePromise } from '../util/api';

const Title = styled(Styled.FlexRow)`
  margin: 15px;
  align-items: center;
  text-align: center;
  margin: 15px 0px 0px 16px;
  ${TypeStyles.TITLE_TEXT}
`;

const BackArrow = styled(Styled.FlexColumn)`
  margin-right: 12px;
  align-items: flex-end;
`;

const InfoBar = styled(Styled.FlexRow)`
  margin: 8px 0px 16px 54px;
`;

const InfoType = styled.div`
  ${TypeStyles.TITLE_ROW_TEXT}
  color: #617082
`;

const InfoColumn = styled(Styled.FlexColumn)`
  margin-right: 40px;
`;

const Info = styled.div`
  ${TypeStyles.LABEL_TEXT}
`;

type Props = {
  plot?: boolean;
};

const Treatments = ({ plot }: Props) => {
  const { patientID, rtPlanID, eventID } = useParams();
  const [searchParams] = useSearchParams();
  const timeFilter = Number(searchParams.get('timeFilter'));
  const checklist = Boolean(searchParams.get('checklist'));
  const events = Boolean(searchParams.get('events'));

  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];

  const { data } = usePromise(fetchPatient, [patientID, 0, 10, navigate]);
  const [clinicPatientId, setClinicPatientId] = useState([]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [clinicPatientId]);
  const patientInfo = patientInfoResponse?.data?.[clinicPatientId?.[0]];

  const treatmentPlans = data?.treatmentPlans;
  const initialUnverifiedEvents =
    treatmentPlans &&
    Object.fromEntries(
      treatmentPlans?.map((plan) => [`${plan.id}`, plan.totalUnverifiedEvents])
    );
  const [unverifiedEvents, setUnverifiedEvents] = useState(
    initialUnverifiedEvents
  );
  const latestTreatmentPlan = data?.treatmentPlans[0];
  const treatmentPlanName = latestTreatmentPlan?.name;
  const treatedFractions = latestTreatmentPlan?.treatedFractions;
  const totalFractions = latestTreatmentPlan?.totalFractions;
  const startDate = Functions.dateToShortMonth(latestTreatmentPlan?.startDate);
  const endDate =
    latestTreatmentPlan?.endDate &&
    Functions.dateToShortMonth(latestTreatmentPlan?.endDate);
  const duration =
    endDate &&
    Functions.getDuration(
      latestTreatmentPlan?.startDate,
      latestTreatmentPlan?.endDate
    );

  const initialTreatmentPlan = rtPlanID
    ? treatmentPlans?.findIndex(
        (treatmentPlan) => Number(treatmentPlan.id) === Number(rtPlanID)
      )
    : 0;
  const [activeTreatmentPlan, setActiveTreatmentPlan] =
    useState(initialTreatmentPlan);

  useEffect(() => {
    setActiveTreatmentPlan(initialTreatmentPlan);
    setUnverifiedEvents(initialUnverifiedEvents);
  }, [treatmentPlans]);

  useEffect(() => {
    setClinicPatientId([data?.clinicPatientId]);
  }, [data]);

  const handleTreatmentCardRefresh = (num: number) => {
    const treatmentPlanId = String(treatmentPlans[activeTreatmentPlan].id);
    const newUnverifiedEvents = unverifiedEvents;
    newUnverifiedEvents[treatmentPlanId] = num;
    setUnverifiedEvents({ ...newUnverifiedEvents });
  };
  return (
    <>
      <Header />
      {data ? (
        <Styled.FlexColumn>
          <Title>
            <Styled.StyledLink
              to={
                (eventID && !plot) || events
                  ? routes.events
                  : checklist
                  ? routes.finalChecklist
                  : routes.patients
              }
            >
              <BackArrow>
                <Styled.BackArrow />
              </BackArrow>
            </Styled.StyledLink>
            {disableMappingService
              ? `${data?.lastName}, ${data?.firstName}`
              : patientInfo
              ? `${patientInfo?.lastName}, ${patientInfo?.firstName}`
              : '-, -'}
          </Title>
          <InfoBar>
            <InfoColumn>
              <InfoType>MRN</InfoType>
              <Info>
                {disableMappingService
                  ? data?.mrn
                  : patientInfo
                  ? patientInfo.mrn
                  : '-'}
              </Info>
            </InfoColumn>
            <InfoColumn>
              <InfoType>Latest Treatment Plan</InfoType>
              <Info>{`${treatmentPlanName} - ${treatedFractions}/${totalFractions}`}</Info>
            </InfoColumn>
            <InfoColumn>
              <InfoType>Duration</InfoType>
              <Info>
                {endDate ? `${duration} (${startDate} - ${endDate})` : '-'}
              </Info>
            </InfoColumn>
          </InfoBar>
          <Styled.Line style={{ minWidth: '1730px' }} />
          <Styled.FlexRow>
            <Styled.SideBar>
              <Styled.SideBarHeader>TREATMENT PLANS</Styled.SideBarHeader>
              {unverifiedEvents &&
                treatmentPlans?.map((treatmentPlan, index) => (
                  <TreatmentCard
                    treatmentPlan={treatmentPlan}
                    isActive={index === activeTreatmentPlan}
                    onClick={() => setActiveTreatmentPlan(index)}
                    unverifiedEvents={unverifiedEvents}
                  />
                ))}
            </Styled.SideBar>
            {treatmentPlans &&
              activeTreatmentPlan !== null &&
              activeTreatmentPlan !== undefined && (
                <TreatmentEvents
                  eventID={Number(eventID)}
                  patientID={Number(patientID)}
                  rtPlanID={treatmentPlans?.[activeTreatmentPlan]?.id}
                  treatmentSite={treatmentPlans?.[activeTreatmentPlan]?.name}
                  handleTreatmentCardRefresh={handleTreatmentCardRefresh}
                  timeFilter={timeFilter}
                  patientInfo={patientInfo}
                />
              )}
          </Styled.FlexRow>
        </Styled.FlexColumn>
      ) : (
        'Loading...'
      )}
    </>
  );
};

export default Treatments;
