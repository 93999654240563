import React, { useState } from 'react';
import Tooltip, { Position } from '../ui/Tooltip';
import {
  fetchEvent,
  fetchPlot,
  handleSaveComment,
  handleVerify,
} from '../../api/event';
import { fetchNoteText, fetchNotes } from '../../api/note';

import Chart from '../../components/ui/Chart';
import Colors from '../../styles/Colors';
import CommentBox from '../ui/CommentBox';
import { Event } from '../../types/Event';
import Functions from '../../util/UtilityFunctions';
import NotesFooter from '../notes/NotesFooter';
import Options from '../../util/Options';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import PriorityBubble from '../ui/PriorityBubble';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import { fetchFeatureFlags } from '../../api/auth';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const { ruleOptions, RuleNames } = Options;

const position: Position = { bottom: '5px', left: '1px' };

const TitleBar = styled(Styled.FlexRow)`
  margin: 12px 15px 19px 20px;
  justify-content: space-between;
  align-items: center;
  min-width: 1429px;
`;

const EventType = styled(Styled.FlexRow)`
  color: ${Colors.GRAY_ONE};
  height: 24px;
  margin-bottom: 6px;
  ${TypeStyles.HEADING_ONE}
`;

const PriorityMachineDate = styled(Styled.FlexRow)`
  color: ${Colors.GRAY_TWO};
  align-items: center;
  ${TypeStyles.LABEL_TEXT}
`;

const PageVerification = styled(Styled.FlexRow)`
  align-items: center;
  text-align: center;
  ${TypeStyles.LABEL_TEXT}
`;

const Details = styled(Styled.FlexRow)`
  margin: 20px 20px 0px 20px;
  justify-content: space-between;
  width: 400px;
`;

const EventInfo = styled(Styled.FlexColumn)`
  font-weight: 600;
  color: ${Colors.BLUE_ONE};
  justify-content: space-around;
  ${TypeStyles.LABEL_TEXT}
`;

const EventInfoText = styled.div`
  font-weight: 300;
  color: ${Colors.GRAY_TWO};
  ${TypeStyles.LABEL_TEXT}
  margin: 8px 0px 30px 0px;
`;

const EventInfoTextNotes = styled(EventInfoText)`
  margin-bottom: 0px;
`;

const Chevron = styled(Styled.Bubble)<{ disabled: boolean }>`
  position: relative;
  width: 34px;
  height: 34px;
  margin-left: 5px;
  border: 1px solid ${Colors.ADDITIONAL_BLUE_GRAY};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'none' : Colors.HOVER_TERTIARY};
  }

  cursor: ${(props) => !props.disabled && 'pointer'}
  opacity: ${(props) => props.disabled && '.4'}
`;

const CloseIcon = styled.div`
  margin-left: 25px;
  cursor: pointer;
  height: 26px;

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

const Container = styled.div`
  background-color: white;
  min-height: 700px;
  min-width: 1440px;
`;

const CheckCircle = styled.div`
  margin: 0px 27px 0px 9px;
`;

const Notes = styled.div`
  width: 50px;
`;

type Props = {
  event: Event;
  handleClose: () => void;
  handlePageChange: (direction: number) => void;
  startPage: number;
  endPage: number;
  rtPlanId: string;
  plotParams: string[];
  treatmentSite: string;
  handleRefresh: () => void;
  handleTreatmentCardRefresh: (num: number) => void;
  handleAfterVerify: (startPage: number, endPage: number) => void;
  isNavigationDisabled?: boolean;
  patientInfo?: PatientNameAndMrn;
};

const EventDetails = ({
  event,
  handleClose,
  handlePageChange,
  startPage,
  endPage,
  rtPlanId,
  plotParams,
  treatmentSite,
  handleRefresh,
  handleTreatmentCardRefresh,
  handleAfterVerify,
  isNavigationDisabled,
  patientInfo,
}: Props) => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];

  const [shiftPlot, setShiftPlot] = useState(
    event.ruleType === RuleNames.COUCH_SHIFT
  );

  const { data: plotData, loading: plotDataLoading } = usePromise(fetchPlot, [
    rtPlanId,
    plotParams,
    shiftPlot,
    navigate,
  ]);

  const { data: eventData } = usePromise(fetchEvent, [
    String(event.id),
    navigate,
  ]);

  const afterVerify = () => {
    handleRefresh();
    handleAfterVerify(startPage, endPage);
  };

  const handleVerifyEvent = () => {
    handleVerify(
      [`${event.id}`],
      afterVerify,
      event.verified,
      handleTreatmentCardRefresh,
      navigate
    );
  };

  const handleSave = (comment: string, afterSave: () => void) => {
    handleSaveComment(`${event.id}`, comment, afterSave, navigate);
  };

  const couch = plotData?.[0]?.param === 'Couch Lateral';

  const [notesShown, setNotesShown] = useState(false);
  const [notesPage, setNotesPage] = useState(0);
  const [activeNoteTypes, setActiveNoteTypes] = useState([]);

  const { data: noteData, loading: noteLoading } = usePromise(fetchNotes, [
    notesPage,
    10,
    JSON.stringify([event.clinicPatientId]),
    event.eventTime,
    event.eventTime,
    JSON.stringify(activeNoteTypes),
    navigate,
  ]);

  const noteKeys = noteData?.notes.map((note) => note.clinicNoteId) || [];
  const maxNotePageNumber = noteData?.pages - 1;
  const noteFilterTypes = noteData?.noteTypes;

  const { data: noteTextData, loading: noteTextLoading } = usePromise(
    fetchNoteText,
    [JSON.stringify(noteKeys), disableMappingService]
  );

  const notes = noteTextData;
  const noteObject = noteData?.notes.map((note) => ({
    ...note,
    noteText: notes?.[String(note.clinicNoteId)],
  }));

  const handleNotesPageChange = (direction: number) => {
    if (
      !(notesPage === 0 && direction === -1) &&
      !(notesPage === maxNotePageNumber && direction === 1)
    ) {
      setNotesPage(notesPage + direction);
    }
  };
  const [initialToggles, setInitialToggles] = useState(null);
  const onShiftToggleClick = (toggles: string[] | null) => {
    setShiftPlot(!shiftPlot);
    setInitialToggles(toggles);
  };

  return (
    <div style={{ minHeight: '900px' }}>
      <Container>
        <TitleBar>
          <Styled.FlexColumn>
            <EventType>
              {ruleOptions[event.ruleType].name}
              <Tooltip position={position}>
                {ruleOptions[event.ruleType].description}
              </Tooltip>
            </EventType>
            <PriorityMachineDate>
              <PriorityBubble priority={event.priority} /> • {event.machineName}{' '}
              • {Functions.dateToString(event.eventTime)}
            </PriorityMachineDate>
            {event.verified && (
              <PriorityMachineDate>
                <CheckCircle>
                  <Styled.CheckCircle />
                </CheckCircle>
                Verified{' '}
                {event.verifiedAt &&
                  `${Functions.dateToString(event.verifiedAt, true)} `}
                {event.verifiedBy && `by ${event.verifiedBy}`}
              </PriorityMachineDate>
            )}
          </Styled.FlexColumn>
          <PageVerification>
            Displaying {startPage} of {endPage}
            <Chevron
              onClick={() => !isNavigationDisabled && handlePageChange(-1)}
              disabled={isNavigationDisabled}
            >
              <Styled.ChevronLeft
                color={!isNavigationDisabled && Colors.ADDITIONAL_SLATE}
                disabled={isNavigationDisabled}
              />
            </Chevron>
            <Chevron
              onClick={() => !isNavigationDisabled && handlePageChange(1)}
              disabled={isNavigationDisabled}
            >
              <Styled.ChevronRight
                color={!isNavigationDisabled && Colors.ADDITIONAL_SLATE}
                disabled={isNavigationDisabled}
              />
            </Chevron>
            <Styled.VerifyButton
              onClick={handleVerifyEvent}
              verified={event.verified}
            >
              {event.verified ? 'Unverify' : 'Verify'}
            </Styled.VerifyButton>
            <CloseIcon onClick={handleClose}>
              <Styled.CloseIcon />
            </CloseIcon>
          </PageVerification>
        </TitleBar>
        <Styled.Line style={{ minWidth: '1449px' }} />
        <br />
        <Styled.FlexRow>
          <Details>
            <EventInfo>
              TREATMENT SITE(S)
              <EventInfoText>{treatmentSite}</EventInfoText>
              DETAILS
              <EventInfoTextNotes>{event.description}</EventInfoTextNotes>
              {event.note ? (
                <Styled.FlexRow>
                  <EventInfoTextNotes style={{ fontStyle: 'italic' }}>
                    Notes: {event.note}
                  </EventInfoTextNotes>
                </Styled.FlexRow>
              ) : (
                <>
                  {event.additionalDetails?.map((note) => (
                    <Styled.FlexRow>
                      <EventInfoTextNotes style={{ fontStyle: 'italic' }}>
                        {note}
                      </EventInfoTextNotes>
                    </Styled.FlexRow>
                  ))}{' '}
                  <br />
                </>
              )}
              COMMENT
              <CommentBox
                currentComment={eventData?.comment || ''}
                onSaveClick={handleSave}
              />
              <Notes>
                <Styled.EventDetailButton
                  onClick={() => {
                    setNotesShown(!notesShown);
                  }}
                >
                  NOTES
                </Styled.EventDetailButton>
              </Notes>
            </EventInfo>
          </Details>
          {plotData && couch && event && !plotDataLoading && (
            <Chart
              plotData={plotData}
              treatmentParameter={event.treatmentParameter}
              eventDate={Functions.dateToDate(event?.eventTime)}
              isCouchShift={shiftPlot}
              onShiftToggleClick={onShiftToggleClick}
              initialToggles={initialToggles}
            />
          )}
        </Styled.FlexRow>
      </Container>
      {notesShown && (
        <>
          <br />
          <NotesFooter
            notes={noteObject}
            handleClose={() => {
              setNotesShown(false);
            }}
            handleNotesPageChange={handleNotesPageChange}
            page={notesPage + 1}
            maxPageNumber={maxNotePageNumber + 1}
            patientInfo={patientInfo}
            types={noteFilterTypes}
            setTypes={setActiveNoteTypes}
            activeTypes={activeNoteTypes}
            loading={noteLoading || noteTextLoading}
            disableMappingService={disableMappingService}
            activeId={event.clinicPatientId}
          />
        </>
      )}
    </div>
  );
};

export default EventDetails;
