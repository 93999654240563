import React, { useRef, useState } from 'react';

import Checkbox from '../ui/Checkbox';
import Functions from '../../util/UtilityFunctions';
import MenuComponents from '../../styles/MenuComponents';
import Priority from '../../types/Priority';
import Styled from '../../styles/Styles';

export type Option = {
  value: number | string;
  label: string;
  priority?: Priority;
};

type Props = {
  options: Option[];
  title?: string;
  onChange?: (checked: boolean[]) => void;
  isDisabled?: boolean;
  initialFilter?: boolean[];
};

const {
  SelectedBorder,
  UnselectedBorder,
  Menu,
  Chevron,
  CloseIcon,
  MenuTitle,
  SelectAll,
  OptionRow,
  Selected,
} = MenuComponents;

const FilterMenu = ({
  title,
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  const initial = initialFilter || options.map(() => true);

  const [menuShown, setMenuShown] = useState(false);
  const [checked, setChecked] = useState(initial);
  const ref = useRef();
  Functions.useOnClickOutside(ref, () => setMenuShown(false));

  const handleCheck = value => {
    const newChecked = checked;
    newChecked[value] = !checked[value];
    setChecked([...newChecked]);
    onChange([...newChecked]);
  };

  const handleSelectAll = () => {
    const selected = options.map(() => true);
    setChecked(selected);
    onChange(selected);
  };

  const handleDeselectAll = () => {
    const deselected = options.map(() => false);
    setChecked(deselected);
    onChange(deselected);
  };

  const allChecked: boolean = checked?.every(value => value);
  const noneChecked: boolean = checked?.every(value => !value);
  const firstChecked: string | JSX.Element = checked?.find(check => check)
    ? options[checked.indexOf(checked.find(check => check))]?.label
    : '';
  const additionalSelected: number = checked?.filter(check => check).length - 1;

  let menuBorder = (
    <SelectedBorder
      disabled={isDisabled}
      onClick={() => setMenuShown(!menuShown)}
      id={`${title}Filter`}
    >
      <Selected>
        {firstChecked}
        {!!additionalSelected && ` + ${additionalSelected} `}
      </Selected>
      <Chevron>
        <Styled.ChevronDown height="18" width="18" />
      </Chevron>
    </SelectedBorder>
  );
  if (allChecked || noneChecked) {
    menuBorder = (
      <UnselectedBorder
        disabled={isDisabled || !options.length}
        onClick={() => setMenuShown(!menuShown)}
        id={`${title}Filter`}
      >
        {allChecked && !!options.length && <Selected>All {title}</Selected>}
        {(noneChecked || !options.length) && <Selected>No {title}</Selected>}
        {!allChecked && !noneChecked && (
          <Selected>
            {firstChecked}
            {!!additionalSelected && ` + ${additionalSelected} `}
          </Selected>
        )}
        <Chevron>
          <Styled.ChevronDown height="18" width="18" />
        </Chevron>
      </UnselectedBorder>
    );
  }

  return (
    <Styled.FlexColumn style={{ position: 'relative' }}>
      <div>{menuBorder}</div>
      <div ref={ref}>
        {menuShown && (
          <Menu>
            <div>
              <MenuTitle>
                {title}
                <CloseIcon onClick={() => setMenuShown(false)}>
                  <Styled.CloseIcon />
                </CloseIcon>
              </MenuTitle>
            </div>
            {noneChecked ? (
              <SelectAll onClick={handleSelectAll}>Select All</SelectAll>
            ) : (
              <SelectAll onClick={handleDeselectAll}>Clear All</SelectAll>
            )}
            {options.map((option: Option, index) => (
              <OptionRow
                id={`option${index}`}
                onClick={() => handleCheck(index)}
              >
                <div style={{ width: '25px' }}>
                  <Checkbox isChecked={checked?.[index]} />
                </div>
                {option.label}
              </OptionRow>
            ))}
          </Menu>
        )}
      </div>
    </Styled.FlexColumn>
  );
};

const PrioritiesMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <FilterMenu
      title="Priorities"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const MachinesMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <FilterMenu
      title="Machines"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const EventsMenu = ({
  options,
  onChange,
  isDisabled,
  initialFilter,
}: Props) => {
  return (
    <FilterMenu
      title="Events"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

const NotesMenu = ({ options, onChange, isDisabled, initialFilter }: Props) => {
  return (
    <FilterMenu
      title="Notes"
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      initialFilter={initialFilter}
    />
  );
};

export { PrioritiesMenu, MachinesMenu, EventsMenu, NotesMenu };

export default FilterMenu;
