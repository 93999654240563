import { Auth } from '@aws-amplify/auth';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import Styled from '../styles/Styles';
import axios from '../util/api';
import { clearCookies } from '../auth';
import routes from '../routes';
import { toast } from 'react-toastify';

export const signOut = async (navigate: NavigateFunction) => {
  await axios
    .post(
      '/remove_token',
      {},
      {
        headers: {
          'X-Requested-By': 'chart-alert',
          'X-User-Clinic': localStorage.getItem('userClinicId') || undefined,
        },
      }
    )
    .then((res) => {
      console.log(res);
    })
    .catch((e) => console.log(e));
  clearCookies('cognito');
  localStorage.removeItem('userClinicId');
  localStorage.removeItem('userClinicName');
  await Auth.signOut();
  navigate(routes.login);
};

export const fetchFeatureFlags = async (flag?: string) => {
  const featureFlagsString = await fetchUserAttribute('custom:featureFlags');
  const featureFlags = featureFlagsString ? JSON.parse(featureFlagsString) : {};
  return flag ? featureFlags[flag] : featureFlags;
};

export const fetchEmail = async () => fetchUserAttribute('email');

export const fetchUserClinics = async () => {
  const userClinicsString = await fetchUserAttribute(
    'custom:secondaryClinicIds'
  );
  return userClinicsString ? JSON.parse(userClinicsString) : [];
};

export const fetchIsAdmin = async () => {
  const rolesString = await fetchUserAttribute('custom:roles');
  const roles = rolesString ? JSON.parse(rolesString) : [];
  return roles.includes('admin');
};

export const fetchUserAttribute = async (attribute: string) => {
  try {
    const session = await Auth.currentSession();
    const { payload } = session?.getIdToken();
    return payload[attribute];
  } catch (error) {
    toast.error(
      React.createElement(Styled.ErrorToast, {
        message: `${error}`,
      }),
      Styled.ToastProps
    );
    Log.error(error, 'Login.tsx');
  }
};

export const fetchInitialUserAndClinics = async (
  navigate: NavigateFunction,
  currentClinic?: string
) => {
  try {
    const user = await Auth.currentSession();
    const { payload } = user?.getIdToken();
    const userClinicsString = payload['custom:secondaryClinicIds'];
    const userClinics = userClinicsString ? JSON.parse(userClinicsString) : [];
    const forceSelection =
      userClinics.length &&
      !userClinics.some((c: any) => String(c.clinicId) === currentClinic);
    return { user, forceSelection };
  } catch (error) {
    navigate(routes.login);
    Log.error(error, 'Login.tsx');
    return String(error);
  }
};
