import React, { useEffect, useState } from 'react';

import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Buttons = styled(Styled.FlexRow)`
  width: 300px;
  height: 48px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid ${Colors.GRAY_FIVE};
  background-color: ${Colors.GRAY_SEVEN};
  position: relative;
  bottom: 13px;
`;

const Button = styled.div<{ disabled: boolean }>`
  width: 100px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  padding: 4px 12px 5px 12px;
  text-align: center;
  cursor: ${props => !props.disabled && 'pointer'};
  ${TypeStyles.LABEL_TEXT}
`;

const CancelButton = styled(Button)`
  color: ${Colors.ADDITIONAL_SLATE}
  background-color: ${Colors.GRAY_SEVEN};
  border: 1px solid ${Colors.ADDITIONAL_BLUE_GRAY};

  &:hover{
      background-color: ${props => !props.disabled && Colors.HOVER_TERTIARY};
  }
  opacity: ${props => props.disabled && '.4'}
`;

const SaveButton = styled(Button)`
  color: white;
  background-color: ${Colors.BLUE_TWO};
  margin-left: 10px;

  &:hover {
    background-color: ${props => !props.disabled && Colors.HOVER_PRIMARY};
  }
  opacity: ${props => props.disabled && '.4'};
`;

type Props = {
  currentComment?: string;
  onSaveClick?: (comment: string, afterSave: () => void) => void;
};

const CommentBox = ({ currentComment, onSaveClick }: Props) => {
  const [value, setValue] = useState(currentComment);
  const [currentSavedComment, setCurrentSavedComment] = useState(
    currentComment
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleCancelClick = () => {
    setValue(currentSavedComment);
    setIsDisabled(true);
  };

  const afterSave = () => {
    setIsDisabled(true);
    setCurrentSavedComment(value);
  };

  useEffect(() => {
    if (value !== currentSavedComment) {
      setIsDisabled(false);
    }
  }, [value]);

  useEffect(() => {
    setValue(currentComment);
    setCurrentSavedComment(currentComment);
  }, [currentComment]);

  return (
    <Styled.FlexColumn>
      <textarea
        rows={4}
        cols={50}
        placeholder="Enter comment"
        value={value}
        onChange={handleChange}
        style={{
          height: '120px',
          width: '300px',
          boxSizing: 'border-box',
          padding: '10px 12px 10px 12px',
          resize: 'none',
          fontFamily: 'inherit',
          marginTop: '10px',
          borderRadius: '4px 4px 0px 0px',
          border: `1px solid ${Colors.GRAY_FIVE}`,
          color: `${(value && Colors.GRAY_TWO) || Colors.GRAY_FOUR}`,
          outline: 'none',
        }}
      />
      <Buttons>
        <CancelButton
          disabled={isDisabled}
          onClick={!isDisabled && handleCancelClick}
        >
          Cancel
        </CancelButton>
        <SaveButton
          disabled={isDisabled}
          onClick={() => !isDisabled && onSaveClick(value, afterSave)}
        >
          Save
        </SaveButton>
      </Buttons>
    </Styled.FlexColumn>
  );
};

export default CommentBox;
