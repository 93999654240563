import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import ListFooter from '../event/ListFooter';
import Checkbox from '../ui/Checkbox';
import { usePromise } from '../../util/api';
import { fetchUserWhitelist, saveUserWhitelist } from '../../api/admin';
import { useNavigate } from 'react-router-dom';

const TitleText = styled.div`
  margin: 20px 0px 20px 20px;
  ${TypeStyles.TITLE_TEXT}
`;
const AddUserBar = styled(Styled.FlexRow)`
  margin: 15px 20px 30px 20px;
  height: 34px;
  align-items: baseline;
`;
const SaveBar = styled(Styled.FlexRow)`
  border: 1px solid ${Colors.GRAY_SIX};
  border-radius: 4px;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0px;
  ${TypeStyles.LABEL_TEXT}
`;

const SaveButton = styled(Styled.SaveButton)`
  width: auto;
  margin-left: 12px;
  padding: 2px 10px 0px 10px;
`;
const CancelButton = styled(Styled.CancelButton)`
  width: auto;
  margin-left: 12px;
  padding: 2px 10px 0px 10px;
`;

type UserRowProps = {
  email: string;
  isChecked: boolean;
  checkboxClick: () => void;
  clinicCheckboxClick?: (string, number) => void;
  userClinicIds?: number[];
  secondaryClinicIds?: number[];
};

const UserRow = ({
  email,
  isChecked,
  checkboxClick,
  clinicCheckboxClick,
  userClinicIds,
  secondaryClinicIds,
}: UserRowProps) => {
  return (
    <Styled.Row>
      <Styled.ItemType style={{ flexBasis: '45px' }}>
        <Checkbox isChecked={isChecked} onClick={checkboxClick} />
      </Styled.ItemType>
      <Styled.ItemType style={{ flexBasis: '300px' }}>{email}</Styled.ItemType>
      {(secondaryClinicIds || []).map((clinic) => (
        <Styled.ItemType style={{ flexBasis: '300px' }}>
          <Checkbox
            isChecked={userClinicIds?.includes(clinic)}
            onClick={() => {
              clinicCheckboxClick(email, clinic);
            }}
          />
        </Styled.ItemType>
      ))}
    </Styled.Row>
  );
};

const EmailInput = ({
  onChange,
  value,
}: {
  onChange: (event) => void;
  value: string;
}) => {
  return (
    <div>
      <input
        size={30}
        placeholder="New User Email"
        onChange={onChange}
        value={value}
        style={{
          borderRadius: '4px',
          border: `1px solid ${Colors.GRAY_FIVE}`,
          fontSize: '14px',
          lineHeight: '19px',
          height: '34px',
          fontFamily: 'Open Sans',
          marginLeft: '10px',
        }}
        id="email-input-box"
      />
    </div>
  );
};

const UserWhitelist = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const tick = () => {
    const newCount = count < 10 ? count + 1 : 1;
    setCount(newCount);
  };

  const { data } = usePromise(fetchUserWhitelist, [count, navigate]);
  const {
    whitelist: initialUserEmails,
    associatedClinics: secondaryClinics,
    primaryClinicId,
  } = data || { whitelist: [], associatedClinics: [] };

  const [hasChanges, setHasChanges] = useState(false);

  const [userEmails, setUserEmails]: [
    { email: string; clinicIds: number[] }[],
    any
  ] = useState([]);

  const handleResetChanges = (newEmails?: any[]) => {
    const initialUserEmailsCopy = (newEmails || initialUserEmails || []).map(
      (e) => ({
        email: e.userEmail,
        clinicIds: [...e.clinicIds],
      })
    );
    setUserEmails(initialUserEmailsCopy);
    setHasChanges(false);
    setSelectedOptions(getNoneSelected(initialUserEmailsCopy));
  };

  useEffect(() => {
    if (initialUserEmails) {
      handleResetChanges();
    }
  }, [primaryClinicId]);

  const getNoneSelected = (emails: { email: string; clinicIds: number[] }[]) =>
    emails?.length
      ? Object.fromEntries(emails.map((email) => [email.email, false]))
      : {};

  const [selectedOptions, setSelectedOptions] = useState({});
  const noSelectedOptions: boolean =
    selectedOptions &&
    Object.values(selectedOptions).every((selected) => !selected);

  const handleSelectAll = () => {
    const selected = Object.fromEntries(
      userEmails.map((email) => [email.email, true])
    );
    setSelectedOptions(selected);
  };
  const handleClearAll = () => {
    setSelectedOptions(getNoneSelected(userEmails));
  };
  const handleSelectOption = (email) => {
    const newSelectedOptions = selectedOptions;
    newSelectedOptions[email] = !selectedOptions[email];
    setSelectedOptions({ ...newSelectedOptions });
  };

  const numberSelectedOptions: number = selectedOptions
    ? (Object.values(selectedOptions).reduce(
        (a: number, option) => a + (option ? 1 : 0),
        0
      ) as number)
    : 0;

  const [newEmail, setNewEmail] = useState('');

  const handleNewEmailEntry = (event) => {
    setNewEmail(event.target.value.toLowerCase());
  };
  const handleAddEmail = () => {
    if (userEmails.find((e) => e.email === newEmail) || !primaryClinicId) {
      return;
    }
    const newUserEmails = [
      ...userEmails,
      { email: newEmail, clinicIds: [primaryClinicId] },
    ];
    setUserEmails(newUserEmails);
    const newSelectedOptions = selectedOptions;
    setSelectedOptions({ ...newSelectedOptions, [newEmail]: false });
    setHasChanges(true);
    setNewEmail('');
  };
  const handleRemoveEmails = () => {
    const newUserEmails = Object.entries(selectedOptions)
      .filter((entry) => !entry[1])
      .map(([email]) => userEmails.find((e) => e.email === email))
      .filter(Boolean);
    setUserEmails(newUserEmails);
    setHasChanges(true);
    handleClearAll();
  };

  const handleClinicAccessChange = (email, clinicId) => {
    const userIndex = userEmails.findIndex((e) => e.email === email);
    if (userIndex < 0) {
      return;
    }
    const newUserEmails = [...userEmails];
    const newClinicIds = [...newUserEmails[userIndex].clinicIds];
    const clinicIdIndex = newClinicIds.indexOf(clinicId);
    if (clinicIdIndex < 0) {
      newClinicIds.push(clinicId);
    } else {
      newClinicIds.splice(clinicIdIndex);
    }
    newUserEmails[userIndex].clinicIds = newClinicIds;
    setUserEmails(newUserEmails);
    setHasChanges(true);
  };

  const handleSaveWhitelist = () => {
    const whitelistToSave = userEmails.map((e) => ({
      userEmail: e.email,
      clinicIds: e.clinicIds,
    }));
    saveUserWhitelist(
      whitelistToSave,
      () => {
        tick();
        handleResetChanges(whitelistToSave);
      },
      navigate
    );
  };

  return (
    <Styled.FlexColumn style={{ width: '100%' }}>
      <TitleText>User Whitelist</TitleText>
      <AddUserBar>
        Add new user by email:{' '}
        <EmailInput onChange={handleNewEmailEntry} value={newEmail} />
        <Styled.SaveButton onClick={handleAddEmail} enabled={!!newEmail}>
          Add User
        </Styled.SaveButton>
      </AddUserBar>
      {hasChanges && (
        <SaveBar>
          <SaveButton enabled={true} onClick={handleSaveWhitelist}>
            Save Changes
          </SaveButton>
          <CancelButton
            enabled={true}
            onClick={() => {
              handleResetChanges();
            }}
          >
            Reset Changes
          </CancelButton>
        </SaveBar>
      )}
      <Styled.TitleRow>
        <Styled.TitleRowType style={{ flexBasis: '45px' }}>
          <Checkbox
            isChecked={!noSelectedOptions && !!selectedOptions}
            onClick={noSelectedOptions ? handleSelectAll : handleClearAll}
            isSelectAll
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType style={{ flexBasis: '300px' }}>
          User Email&nbsp;
        </Styled.TitleRowType>
        {secondaryClinics.map((clinic) => (
          <Styled.TitleRowType style={{ flexBasis: '300px' }}>
            {clinic.clinicName} Access
          </Styled.TitleRowType>
        ))}
      </Styled.TitleRow>
      <Styled.Line color={Colors.ADDITIONAL_BLUE_GRAY} />
      <div>
        {userEmails?.map((email) => (
          <UserRow
            email={email.email}
            isChecked={selectedOptions?.[email.email]}
            secondaryClinicIds={secondaryClinics.map((c) => c.clinicId)}
            userClinicIds={email.clinicIds}
            checkboxClick={() => handleSelectOption(email.email)}
            clinicCheckboxClick={handleClinicAccessChange}
          />
        ))}
      </div>
      {!!numberSelectedOptions && (
        <ListFooter
          number={numberSelectedOptions}
          onClearClick={handleClearAll}
          onClick={handleRemoveEmails}
          isOppositeClick={true}
          isAdmin
          customText="Remove Emails"
        />
      )}
    </Styled.FlexColumn>
  );
};

export default UserWhitelist;
