import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const RadioButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

type Props = {
  isChecked: boolean;
};

const RadioButton = ({ isChecked }: Props) => {
  return (
    <RadioButtonContainer>
      <HiddenRadioButton />
      {isChecked ? (
        <Styled.RadioButtonChecked />
      ) : (
        <Styled.RadioButtonUnchecked />
      )}
    </RadioButtonContainer>
  );
};

export default RadioButton;
