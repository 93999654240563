import Options, { TimeFilterOptions } from '../util/Options';

import { FinalPatient } from '../types/Checklist';
import { NavigateFunction } from 'react-router-dom';
import { PatientNameAndMrn } from '../types/PatientInfo';
import Priority from '../types/Priority';
import { UnreviewedImagePatient } from '../types/Patient';
import axios from './api';
import moment from 'moment';
import routes from '../routes';
import { useEffect } from 'react';
import { AxiosResponse } from 'axios';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);

      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

function dateToString(date: Date, local?: boolean) {
  const mdyFormat = 'MMM D, YYYY';
  return (local ? moment(date).utc().local() : moment(date).utc()).calendar(
    null,
    {
      sameDay: '[Today at] h:mma',
      lastDay: '[Yesterday at] h:mma',
      lastWeek: `${mdyFormat} [at] h:mma`,
      sameElse: `${mdyFormat} [at] h:mma`,
    }
  );
}

function dateToDate(date: Date) {
  return moment(date).utc().format('MMM D, YYYY');
}

function dateToShortMonth(date: Date) {
  return moment(date).utc().format('MMM D, YYYY');
}

function textLength(str: string, font: string) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = font;
  const length = ctx.measureText(str).width;
  return length;
}

function sortFunction(sortDescending: boolean, sortBy: string) {
  switch (sortBy) {
    case 'events':
      const total = (machine) =>
        machine.highEvents + machine.medEvents + machine.lowEvents;
      return (a, b) => (sortDescending ? 1 : -1) * (total(b) - total(a));
    case 'name':
      return (a, b) =>
        (sortDescending ? 1 : -1) * a.machineName.localeCompare(b.machineName);
  }
}

function timeFilterToApiDate(timeFilter: number) {
  let date = moment(new Date());
  switch (timeFilter) {
    case TimeFilterOptions.TODAY:
      date = date.startOf('day');
      break;
    case TimeFilterOptions.PAST_WEEK:
      date = date.subtract(1, 'weeks');
      break;
    case TimeFilterOptions.PAST_MONTH:
      date = date.subtract(1, 'months');
      break;
    default:
      date = undefined;
  }
  return date?.utc().format('YYYY-MM-DD');
}

export type filter = {
  sortBy?: string;
  sortDescending?: boolean;
  nameOrMRN?: string;
  verified?: boolean;
  machine?: string[];
  eventTime?: string;
  priority?: string[];
  ruleType?: string[];
  eventAfter?: string;
  eventBefore?: string;
  clinicPatientId?: string;
  timeFilter?: number;
};

function getDuration(startDate: Date, endDate: Date) {
  const momentDuration = moment.duration(
    moment(endDate).diff(moment(startDate))
  );
  const months = Math.floor(momentDuration.asMonths()) || 1;
  const days = Math.floor(momentDuration.asDays()) || 1;
  const duration =
    momentDuration.asMonths() > 1
      ? `${months} ${months === 1 ? 'Month' : 'Months'}`
      : `${days} ${days === 1 ? 'Day' : 'Days'}`;
  return duration;
}

function getPlotParams(treatmentParameter: string) {
  let param = [];
  switch (treatmentParameter) {
    case 'couch_lat':
    case 'couch_vrt':
    case 'couch_lng':
      param = ['couch_lat', 'couch_vrt', 'couch_lng'];
      break;
    case 'gantry_angle':
    case 'collimator_angle':
      param = [treatmentParameter];
  }
  return param;
}

function sortAdminOptions(a, b, sortDescending, sortBy) {
  const type = String(sortBy) === String('type');
  const sortA = type ? a?.description.toUpperCase() : a.priority;
  const sortB = type ? b?.description.toUpperCase() : b.priority;

  let comparison = 0;
  if (sortA > sortB) {
    comparison = sortDescending ? 1 : -1;
  } else if (sortB > sortA) {
    comparison = sortDescending ? -1 : 1;
  }
  return comparison;
}

function getPatientsFilter(
  apiFilter: filter,
  verified: number,
  priorities: boolean[],
  time: number
) {
  const newFilter: filter = apiFilter;
  switch (verified) {
    case 0:
      newFilter.verified = false;
      break;
    case 1:
      newFilter.verified = true;
      break;
    case 2:
      delete newFilter.verified;
  }
  if (
    priorities.filter(Boolean).length &&
    priorities.filter(Boolean).length !== Object.keys(Priority).length / 2
  ) {
    newFilter.priority = priorities
      .map(
        (check, index) =>
          check && Priority[String(Options.priorityOptions[index].priority)]
      )
      .filter(Boolean);
  } else {
    delete newFilter.priority;
  }
  switch (time) {
    case 2:
      delete newFilter.eventAfter;
      break;
    default:
      newFilter.eventAfter = timeFilterToApiDate(time);
  }
  return newFilter;
}

export type apiFilterOptions = {
  verified?: number;
  priority?: boolean[];
  time?: number;
  machine?: boolean[];
  event?: boolean[];
};

function getInitialApiFilterOptions(
  initialApiFilter: filter,
  machineOptions,
  savedTimeFilter?: number
) {
  const initialApiFilterOptions: apiFilterOptions = {
    verified: 2,
    priority: Options.priorityOptions.map(() => true),
    time:
      savedTimeFilter !== undefined
        ? savedTimeFilter
        : initialApiFilter.timeFilter || 3,
    machine: machineOptions.map(() => true),
    event: Options.eventOptions.map(() => true),
  };
  switch (initialApiFilter.verified) {
    case false:
      initialApiFilterOptions.verified = 0;
      break;
    case true:
      initialApiFilterOptions.verified = 1;
  }
  if (initialApiFilter.priority) {
    initialApiFilterOptions.priority = Object.keys(Priority)
      .map((key) => initialApiFilter.priority.includes(key))
      .slice(4)
      .reverse();
  }

  if (initialApiFilter.machine) {
    initialApiFilterOptions.machine = machineOptions.map((option) =>
      initialApiFilter.machine.includes(option.label)
    );
  }
  if (initialApiFilter.ruleType) {
    initialApiFilterOptions.event = Options.eventOptions.map((option) =>
      initialApiFilter.ruleType.includes(option.value)
    );
  }
  return initialApiFilterOptions;
}

function abbreviateCouch(couchString: string) {
  let newString = couchString;
  if (couchString.includes('Couch Lateral')) {
    newString = couchString.replace('Couch Lateral', 'Lat');
  }
  if (couchString.includes('Couch Vertical')) {
    newString = couchString.replace('Couch Vertical', 'Vrt');
  }
  if (couchString.includes('Couch Longitudinal')) {
    newString = couchString.replace('Couch Longitudinal', 'Lng');
  }
  return newString;
}

function dateToApiDate(date: Date) {
  const apiDate = moment(date);
  return apiDate.utc().format('YYYY-MM-DD');
}

function handleVerifiedChange(apiFilter: filter, checked: number) {
  let newFilter: filter = apiFilter;
  switch (checked) {
    case 0:
      newFilter = { ...apiFilter, verified: false };
      break;
    case 1:
      newFilter = { ...apiFilter, verified: true };
      break;
    case 2:
      delete newFilter.verified;
  }
  return newFilter;
}

function handlePriorityChange(apiFilter: filter, checked: boolean[]) {
  let newFilter: filter = apiFilter;
  if (
    checked.filter(Boolean).length &&
    checked.filter(Boolean).length !== Object.keys(Priority).length / 2
  ) {
    newFilter = {
      ...apiFilter,
      priority: checked
        .map(
          (check, index) =>
            check && Priority[String(Options.priorityOptions[index].priority)]
        )
        .filter(Boolean),
    };
  } else {
    delete newFilter.priority;
  }
  return newFilter;
}

function handleTimeChange(
  apiFilter: filter,
  checked: number,
  customStartDate?: Date,
  customEndDate?: Date
) {
  let newFilter: filter = apiFilter;
  delete newFilter.eventTime;
  if (checked === TimeFilterOptions.ALL_TIME) {
    delete newFilter.eventAfter;
    delete newFilter.eventBefore;
  } else if (checked === TimeFilterOptions.CUSTOM) {
    newFilter = {
      ...apiFilter,
      eventAfter: dateToApiDate(customStartDate || new Date()),
      eventBefore: dateToApiDate(customEndDate || new Date()),
    };
  } else {
    delete newFilter.eventAfter;
    delete newFilter.eventBefore;
    newFilter = {
      ...apiFilter,
      eventAfter: timeFilterToApiDate(checked),
    };
  }
  return newFilter;
}

function handleMachineChange(
  apiFilter: filter,
  checked: boolean[],
  machineOptions
) {
  let newFilter: filter = apiFilter;
  if (
    checked.filter(Boolean).length &&
    checked.filter(Boolean).length !== machineOptions.length
  ) {
    newFilter = {
      ...apiFilter,
      machine: checked
        .map((check, index) => check && machineOptions[index].label)
        .filter(Boolean),
    };
  } else {
    delete newFilter.machine;
  }
  return newFilter;
}

function handleEventChange(apiFilter: filter, checked: boolean[]) {
  let newFilter: filter = apiFilter;
  if (
    checked.filter(Boolean).length &&
    checked.filter(Boolean).length !== Options.eventOptions.length
  ) {
    newFilter = {
      ...apiFilter,
      ruleType: checked
        .map((check, index) => check && Options.eventOptions[index].value)
        .filter(Boolean),
    };
  } else {
    delete newFilter.ruleType;
  }
  return newFilter;
}

function handleSetSortBy(apiFilter: filter, sortBy: string) {
  let newFilter: filter = apiFilter;
  if (sortBy === apiFilter.sortBy) {
    newFilter = {
      ...apiFilter,
      sortDescending: !apiFilter.sortDescending,
    };
  } else {
    newFilter = { ...apiFilter, sortDescending: true, sortBy };
  }
  return newFilter;
}

export type apiCallProps = {
  route: string;
  isPostRequest?: boolean;
  paramsOrData?: any;
  navigate?: NavigateFunction;
};

async function apiCall(props: apiCallProps, tryCount?: number) {
  const { route, isPostRequest, paramsOrData, navigate } = props;

  const userClinicId = localStorage.getItem('userClinicId');
  const userClinicHeader = userClinicId
    ? { 'X-User-Clinic': userClinicId }
    : {};

  return isPostRequest
    ? axios
        .post(route, paramsOrData, {
          headers: {
            'X-Requested-By': 'chart-alert',
            ...userClinicHeader,
          },
        })
        .then((res) => {
          if (res.status === 401) {
            navigate(routes.login);
          }
          return res;
        })
    : axios
        .get(route, {
          headers: {
            'X-Requested-By': 'chart-alert',
            ...userClinicHeader,
          },
          params: paramsOrData,
          paramsSerializer: (params) =>
            Object.keys(params)
              .map((key) =>
                Array.isArray(params[key])
                  ? params[key]
                      .map((value) =>
                        value !== undefined
                          ? `${key}[]=${encodeURIComponent(value)}`
                          : ''
                      )
                      .filter(Boolean)
                      .join('&')
                  : params[key] !== undefined
                  ? `${key}=${encodeURIComponent(params[key])}`
                  : ''
              )
              .filter(Boolean)
              .join('&'),
        })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          if (String(error).includes('401') && !tryCount) {
            return new Promise((resolve) => {
              setTimeout(() => resolve(apiCall(props, 1)), 500);
            }) as Promise<AxiosResponse<any>>;
          } else if (String(error).includes('401')) {
            navigate(routes.login);
          }
          return {} as AxiosResponse<any>;
        });
}

function getLastUpdated(lastUpdate: Date) {
  moment.updateLocale('en', {});
  const startDate = moment(lastUpdate);
  const endDate = moment(new Date());
  const warning = !(endDate.diff(moment(startDate), 'minutes') <= 30);
  const lastUpdated = `Treatment data was updated ${startDate.fromNow()}`;
  return {
    lastUpdated,
    warning,
  };
}

function getLastRealTimeUpdated(lastUpdate: Date, currentDate: Date) {
  moment.updateLocale('en', {
    relativeTime: {
      s: '%d s',
      ss: '%d s',
      m: '1 min',
      mm: '%d min',
      h: '1 hr',
      hh: '%d hr',
    },
  });
  moment.relativeTimeThreshold('ss', 0);
  moment.relativeTimeThreshold('s', 60);
  const startDate = moment(lastUpdate).utc();
  const lastUpdated = `Updated ${startDate.fromNow()}`;
  return lastUpdated;
}

function formatPatientName(patientInfo: PatientNameAndMrn) {
  return patientInfo
    ? `${patientInfo.lastName || '-'}, ${`${patientInfo.firstName || '-'}${
        patientInfo.middleName ? ` ${patientInfo.middleName}` : ''
      }`}`
    : '-, -';
}

function insightDateToMonth(date: string) {
  const p = moment(date, 'YYYY-MM-DD');
  const month = p.format('MMM');
  const year = p.format('YYYY');
  return `${month} ${year}`;
}

function formatChecklistFractions(patient: FinalPatient) {
  return `${patient.latestFraction}/${
    patient.rxFractions
  } (${patient.deliveredDose.toFixed(2)}cGy/${patient.rxDose.toFixed(2)}cGy)`;
}

function formatUnreviewedImageFractions(patient: UnreviewedImagePatient) {
  return `${(patient.deliveredDose / patient.latestFraction).toFixed(0)}cGy (${
    patient.latestFraction
  }/${patient.rxFractions})`;
}

function boldDescription(desc: string) {
  const regex = /\d+.\d{2}|Lateral|Vertical|Longitudinal/gm;
  const sentence = desc.split(' ');
  const array = sentence.map((word) => [word, regex.test(word)]);

  return array;
}

function dateToNotesDate(date: Date) {
  const apiDate = moment(date);
  return apiDate.utc().format('M/D/YY H:mm a');
}

function extractMRNs(searchTerm: string) {
  const mrnMatch = /(?<=\s|^|,)(?:[a-zA-Z]\d{4,}|\d{5,})(?=\s|$|,)/gm;
  return searchTerm.match(mrnMatch);
}

const Functions = {
  useOnClickOutside,
  dateToString,
  dateToDate,
  dateToShortMonth,
  textLength,
  sortFunction,
  timeFilterToApiDate,
  getDuration,
  getPlotParams,
  sortAdminOptions,
  getPatientsFilter,
  getInitialApiFilterOptions,
  abbreviateCouch,
  dateToApiDate,
  handleVerifiedChange,
  handlePriorityChange,
  handleTimeChange,
  handleMachineChange,
  handleEventChange,
  handleSetSortBy,
  apiCall,
  getLastUpdated,
  getLastRealTimeUpdated,
  formatPatientName,
  insightDateToMonth,
  formatChecklistFractions,
  boldDescription,
  formatUnreviewedImageFractions,
  dateToNotesDate,
  extractMRNs,
};

export default Functions;
