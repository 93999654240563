import React, { useState } from 'react';

import EventsConfigOptionDetails from '../components/admin/EventsConfigOptionDetails';
import Header from '../components/ui/Header';
import NoteTypes from '../components/admin/NoteTypes';
import SettingsColumn from '../components/ui/SettingsColumn';
import Styled from '../styles/Styles';
import { fetchIsAdmin } from '../api/auth';
import { usePromise } from '../util/api';
import UserWhitelist from '../components/admin/UserWhitelist';

const configOptions = ['Events', 'Note Types', 'User Whitelist'];

const Admin = () => {
  const [activeTab, setActiveTab] = useState('Events');

  const { data: isAdmin } = usePromise(fetchIsAdmin, []);

  return (
    <>
      <Header admin />
      {isAdmin && (
        <Styled.FlexRow>
          <SettingsColumn
            options={configOptions}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === 'Events' && <EventsConfigOptionDetails />}
          {activeTab === 'Note Types' && <NoteTypes />}
          {activeTab === 'User Whitelist' && <UserWhitelist />}
        </Styled.FlexRow>
      )}
      {!isAdmin && <>Sorry, you are not authorized to access this page!</>}
    </>
  );
};

export default Admin;
