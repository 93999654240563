import React, { useState } from 'react';

import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import { Note } from '../../types/Event';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import Styled from '../../styles/Styles';
import { handleVerifyNote } from '../../api/note';

type Props = {
  note: Note & { noteText: string };
  patientInfo?: PatientNameAndMrn;
  disableMappingService?: boolean;
  onClick?: () => void;
  isHighlighted?: boolean;
  handleNoteRefresh?: () => void;
  checkboxClick?: () => void;
  isChecked?: boolean;
};

const NotesRow = ({
  note,
  patientInfo,
  disableMappingService,
  onClick,
  isHighlighted,
  handleNoteRefresh,
  checkboxClick,
  isChecked,
}: Props) => {
  const [isShown, setIsShown] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const handleVerify = () => {
    handleVerifyNote([`${note.id}`], handleNoteRefresh, !!note.verifiedAt);
  };
  return (
    <Styled.Row
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={buttonHover ? () => {} : onClick}
      style={{
        maxHeight: '56px',
        minWidth: '1730px',
        backgroundColor: `${
          isHighlighted || isShown ? Colors.HOVER_SECONDARY : 'white'
        }`,
      }}
    >
      <Styled.ItemType
        theme={{ flexBasis: '45px' }}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
      >
        <Checkbox onClick={checkboxClick} isChecked={isChecked} />
      </Styled.ItemType>
      <Styled.ItemType
        theme={{ flexBasis: '200px' }}
        style={{ wordBreak: 'break-all' }}
      >
        {disableMappingService
          ? `${note.clinicPatientId}, ${note.clinicPatientId}`
          : patientInfo
          ? Functions.formatPatientName(patientInfo)
          : '-, -'}
      </Styled.ItemType>
      <Styled.ItemType theme={{ flexBasis: '80px' }}>
        {disableMappingService
          ? note.clinicPatientId
          : patientInfo
          ? patientInfo.mrn
          : '-'}
      </Styled.ItemType>
      <Styled.ItemType theme={{ flexBasis: '200px' }}>
        {Functions.dateToString(note?.noteEdited)}
      </Styled.ItemType>
      <Styled.ItemType theme={{ flexBasis: '150px' }}>
        {note?.type}
      </Styled.ItemType>
      <Styled.ItemType theme={{ flexBasis: '250px' }}>
        {note?.verifiedAt
          ? `Verified at ${Functions.dateToString(note?.verifiedAt)} by ${
              note?.verifiedBy
            }`
          : 'Not Verified'}{' '}
      </Styled.ItemType>
      <Styled.ItemType
        theme={{
          flexGrow: 1,
          flexShrink: 1,
          display: 'inline-block',
        }}
      >
        {note?.noteText}
      </Styled.ItemType>

      {isShown ? (
        <Styled.FlexRow
          style={{
            position: 'sticky',
            right: '.5px',
            height: '55px',
            alignItems: 'center',
            backgroundColor: Colors.MOUSEOVER_BLUE,
          }}
        >
          <Styled.FlexRow
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
          >
            <Styled.VerifyButton
              style={{ height: '28px', width: '64px' }}
              onClick={handleVerify}
              verified={!!note.verifiedAt}
            >
              {note.verifiedAt ? 'Unverify' : 'Verify'}
            </Styled.VerifyButton>
          </Styled.FlexRow>
        </Styled.FlexRow>
      ) : (
        <></>
      )}
    </Styled.Row>
  );
};

export default NotesRow;
