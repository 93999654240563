import React, { useEffect, useState } from 'react';
import {
  fetchEventNotifyConfig,
  handleSaveEventNotifyConfig,
} from '../../api/settings';

import Checkbox from '../ui/Checkbox';
import { EventNotifyConfig } from '../../types/Settings';
import Options from '../../util/Options';
import PriorityBubble from '../ui/PriorityBubble';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import { fetchActiveRules } from '../../api/event';
import { fetchAllMachines } from '../../api/machine';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const Title = styled(Styled.FlexColumn)`
  margin: 20px 0px 20px 0px;
  ${TypeStyles.HEADING_ONE}
`;

const Box = styled.div`
  width: 25px;
`;

const ConfigRow = styled(Styled.FlexRow)`
  margin-left: 30px;
  justify-content: space-between;
  width: 500px;
`;

const EventNotifications = () => {
  const navigate = useNavigate();
  const { data: ruleStatus } = usePromise(fetchActiveRules, [navigate]);
  const { data: configData } = usePromise(fetchEventNotifyConfig, [navigate]);
  const { data: machineStatus } = usePromise(fetchAllMachines, [navigate]);

  const activeRules = ruleStatus?.rules;
  const machines = machineStatus?.machines?.map(
    (machine) => machine.machineName
  );

  const [notifiedRules, setNotifiedRules] = useState([]);
  const [notifiedMachines, setNotifiedMachines] = useState([]);
  const [configStatus, setConfigStatus] = useState<EventNotifyConfig>();

  useEffect(() => {
    if (configData?.rules) {
      setNotifiedRules(configData.rules);
    }
    if (configData?.machines) {
      setNotifiedMachines(configData.machines);
    }
    if (configData) {
      setConfigStatus(configData);
    }
  }, [configData]);

  const handleRuleClick = (ruleText: string) => {
    const newNotifiedRules = [...notifiedRules];
    const index = notifiedRules.indexOf(ruleText);
    if (index === -1) {
      newNotifiedRules.push(ruleText);
    } else {
      newNotifiedRules.splice(index, 1);
    }
    setNotifiedRules(newNotifiedRules);
  };
  const handleMachineClick = (machine: string) => {
    const newNotifiedMachines = [...notifiedMachines];
    const index = notifiedMachines.indexOf(machine);
    if (index === -1) {
      newNotifiedMachines.push(machine);
    } else {
      newNotifiedMachines.splice(index, 1);
    }
    setNotifiedMachines(newNotifiedMachines);
  };
  const handleCancel = () => {
    setNotifiedRules(configStatus?.rules);
    setNotifiedMachines(configStatus?.machines);
  };
  const afterSave = () => {
    setConfigStatus({ machines: notifiedMachines, rules: notifiedRules });
  };
  const handleSave = () => {
    const newConfig = { machines: notifiedMachines, rules: notifiedRules };
    handleSaveEventNotifyConfig(newConfig, afterSave, navigate);
  };
  const currentConfig = { machines: notifiedMachines, rules: notifiedRules };
  const isDisabled =
    configStatus?.rules.sort().join() === currentConfig?.rules.sort().join() &&
    configStatus?.machines.sort().join() ===
      currentConfig?.machines.sort().join();
  const { ruleOptions } = Options;
  return (
    <Styled.FlexColumn>
      <ConfigRow>
        <Styled.FlexColumn>
          <Title>Rules</Title>
          {activeRules
            ?.sort((a, b) => a.priority - b.priority)
            .map((rule) => {
              const ruleText = Options.eventOptions.find(
                (event) => event.label === rule.name
              )?.value;
              return (
                <Styled.ActiveRuleRow>
                  <Styled.FlexRow
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '80px',
                    }}
                  >
                    <Checkbox
                      isChecked={notifiedRules.includes(ruleText)}
                      onClick={() => handleRuleClick(ruleText)}
                    />
                    <PriorityBubble priority={rule.priority} />
                  </Styled.FlexRow>
                  {ruleOptions[rule.name].name}
                </Styled.ActiveRuleRow>
              );
            })}
        </Styled.FlexColumn>
        <Styled.FlexColumn>
          <Title>Machines</Title>
          {machines?.map((machine) => (
            <Styled.ActiveRuleRow>
              <Box>
                <Checkbox
                  isChecked={notifiedMachines.includes(machine)}
                  onClick={() => handleMachineClick(machine)}
                />
              </Box>
              {machine}
            </Styled.ActiveRuleRow>
          ))}
        </Styled.FlexColumn>
      </ConfigRow>
      <ConfigRow>
        <Styled.ButtonRow>
          <Styled.CancelButton
            onClick={() => !isDisabled && handleCancel()}
            enabled={!isDisabled}
          >
            Cancel
          </Styled.CancelButton>
          <Styled.SaveButton
            onClick={() => !isDisabled && handleSave()}
            enabled={!isDisabled}
          >
            Save
          </Styled.SaveButton>
        </Styled.ButtonRow>
      </ConfigRow>
    </Styled.FlexColumn>
  );
};

export default EventNotifications;
