import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { InstitutionsMenu, SitesMenu } from '../components/ui/RadioMenu';
import React, { useEffect, useState } from 'react';
import {
  fetchCouchShift,
  fetchCouchShiftOptions,
  fetchOverridePercentiles,
  fetchOverrideRates,
} from '../api/insight';

import BoxPlotChart from '../components/ui/BoxPlotChart';
import Colors from '../styles/Colors';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import Functions from '../util/UtilityFunctions';
import InsightLegend from '../components/ui/InsightLegend';
import InsightMetricsCard from '../components/ui/InsightMetricsCard';
import Legend from '../components/ui/Legend';
import { MachinesMenu } from '../components/ui/FilterMenu';
import Options from '../util/Options';
import Styled from '../styles/Styles';
import { fetchMachinesWithOverrides } from '../api/machine';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

export const percentileToColor = {
  percentile25: 'green',
  percentile50: 'gold',
  percentile75: 'red',
};

export const percentileToText = {
  percentile25: '25th',
  percentile50: '50th',
  percentile75: '75th',
};

const Insight = () => {
  const navigate = useNavigate();
  const metrics = [
    'Couch Lateral Override Ratio',
    'Couch Vertical Override Ratio',
    'Couch Longitudinal Override Ratio',
  ];

  const metricColors = {
    'Couch Lateral Override Ratio': Colors.BLUE_TWO,
    'Couch Vertical Override Ratio': Colors.BLUE_TWO,
    'Couch Longitudinal Override Ratio': Colors.BLUE_TWO,
  };

  const shiftMetrics = [
    'Couch Lateral Shift',
    'Couch Vert Shift',
    'Couch Long Shift',
  ];

  const shiftMetricColors = {
    'Couch Lateral Shift': Colors.BLUE_TWO,
    'Couch Vert Shift': Colors.BLUE_TWO,
    'Couch Long Shift': Colors.BLUE_TWO,
  };

  const { data: machineData, loading: machineDataLoading } = usePromise(
    fetchMachinesWithOverrides,
    [navigate]
  );
  const machines = machineData?.machines;
  const machineOptions = machines && Options.getOptions(machines);
  const initialMachineFilter = machineOptions?.map((option, index) => !index);
  const initialMachineList = machines && [machines[0]];
  const [machineList, setMachineList] = useState(initialMachineList);
  const paramList = ['couch_lat', 'couch_vrt', 'couch_lng'];
  const [param, setParam] = useState('couch_lat');
  const [overrides, setOverrides] = useState([]);

  // there were issues with infinite renders when machines were sent in as an array of strings, so it's stringified and then parsed on the other side
  const { data: overrideData, loading: overrideLoading } = usePromise(
    fetchOverrideRates,
    [JSON.stringify(machineList), param, navigate]
  );

  const { data: percentileData, loading: percentileLoading } = usePromise(
    fetchOverridePercentiles,
    [param, navigate]
  );

  const handleMachineChange = (selected: boolean[]) => {
    const newMachineList = selected
      .map((machine, index) => machine && machines[index])
      .filter(Boolean);
    setMachineList(newMachineList);
  };

  const handleMetricChange = (selected: string) => {
    const num = metrics.findIndex((metric) => metric === selected);
    setParam(paramList[num]);
  };

  useEffect(() => {
    if (machines) {
      setMachineList(initialMachineList);
    }
  }, [machineData]);
  useEffect(() => {
    if (Array.isArray(overrideData)) {
      setOverrides(overrideData);
    }
  }, [overrideData]);

  const start = moment('2021-01-01', 'YYYY-MM-DD');
  const end = moment(new Date()).add(1, 'M');
  const length = end.diff(start, 'months');
  const xPoints = Array.from({ length }, (v, i) =>
    start.clone().add(i, 'M').format('YYYY-MM-DD')
  );

  const overrideMapping: Map<string, Object> = new Map();
  overrides.forEach((machine) => {
    machine.data.forEach((month) => {
      const { machineName } = machine;
      const { rate } = month;

      if (overrideMapping.has(month.month)) {
        overrideMapping.set(month.month, {
          [machineName]: rate,
          ...overrideMapping.get(month.month),
        });
      } else {
        overrideMapping.set(month.month, { [machineName]: rate });
      }
    });
  });

  const plotData = xPoints?.map((xPoint) => ({
    name: `${Functions.insightDateToMonth(xPoint)}`,
    ...overrideMapping.get(xPoint),
  }));

  const parentColorList = [
    'rgb(64, 224, 208)',
    'rgb(218, 112, 214)',
    'rgb(0, 0, 255)',
    'rgb(255, 160, 122)',
    'rgb(173, 255, 47)',
    'rgb(255, 0, 255)',
    'rgb(205, 92, 92)',
    'rgb(0, 206, 209)',
    'rgb(255, 69, 0)',
    'rgb(50, 205, 50)',
    'rgb(255, 215, 0)',
    'rgb(153, 50, 204)',
    'rgb(255, 140, 0)',
    'rgb(85, 107, 47)',
    'rgb(0, 0, 139)',
    'rgb(184, 134, 11)',
    'rgb(178, 34, 34)',
    'rgb(255, 255, 48)',
    'rgb(100, 149, 237)',
    'rgb(250, 235, 215)',
    'rgb(192, 192, 192)',
    'rgb(60, 179, 113)',
    'rgb(75, 0, 130)',
    'rgb(220, 20, 60)',
    'rgb(47, 79, 79)',
    'rgb(0, 0, 0)',
  ];

  const colors = machines?.map((machine, index) => {
    return parentColorList[index];
  });

  const nameToColor =
    machines &&
    Object.fromEntries(
      machines?.map((machine, index) => [machine, colors?.[index]])
    );

  const CustomTooltip = (props) => {
    if (!props.active || !props.payload) {
      return null;
    }
    const dataPoint = plotData.find(
      (point) => point.name === props.payload?.[0]?.payload.name
    );

    const newPayload = [
      ...machineList
        .filter((machine) => dataPoint?.[machine] !== undefined)
        .map((machine) => {
          return {
            ...props.payload[
              props.payload.findIndex((payload) => payload.dataKey === machine)
            ],
            unit: '%',
            payload: dataPoint,
            value: Number(dataPoint[machine].toFixed(1)),
          };
        }),
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  const checked = [metrics[paramList.findIndex((par) => par === param)]];

  const charts = ['Couch Override Ratio', 'IGRT Shift'];

  const [activeChart, setActiveChart] = useState('Couch Override Ratio');

  const { data: couchShiftOptionData, loading: couchShiftOptionDataLoading } =
    usePromise(fetchCouchShiftOptions, [navigate]);

  const [site, setSite] = useState('');
  const [hospital, setHospital] = useState('');
  const [shiftParam, setShiftParam] = useState('couch_lat');
  const shiftChecked = [
    shiftMetrics[paramList.findIndex((par) => par === shiftParam)],
  ];

  const handleShiftMetricChange = (selected: string) => {
    const num = shiftMetrics.findIndex((metric) => metric === selected);
    setShiftParam(paramList[num]);
  };
  const handleSiteChange = (num: number) => {
    setSite(couchShiftOptionData?.sites[num]);
  };
  const handleHospitalChange = (num: number) => {
    setHospital(couchShiftOptionData?.hospitals[num]);
  };

  useEffect(() => {
    if (couchShiftOptionData) {
      setSite(couchShiftOptionData.sites[0]);
      setHospital(couchShiftOptionData.hospitals[0]);
    }
  }, [couchShiftOptionData]);

  const { data: couchShiftData, loading: couchShiftDataLoading } = usePromise(
    fetchCouchShift,
    [shiftParam, site, navigate]
  );

  return (
    <>
      {machineOptions && (
        <Styled.FlexRow>
          <Styled.SideBar>
            <Styled.SideBarHeader>Metrics</Styled.SideBarHeader>
            {charts.map((chart, index) => (
              <InsightMetricsCard
                metric={chart}
                isActive={
                  index ===
                  charts.findIndex((chartType) => chartType === activeChart)
                }
                onClick={() => setActiveChart(charts[index])}
              />
            ))}
          </Styled.SideBar>
          {activeChart === 'Couch Override Ratio' && (
            <Styled.FlexColumn style={{ padding: '30px' }}>
              <Styled.FlexRow
                style={{ justifyContent: 'right', paddingRight: '259px' }}
              >
                <MachinesMenu
                  options={machineOptions}
                  onChange={handleMachineChange}
                  initialFilter={initialMachineFilter}
                />
              </Styled.FlexRow>
              <Styled.FlexRow>
                <LineChart
                  width={1300}
                  height={700}
                  data={plotData}
                  margin={{
                    top: 40,
                    right: 120,
                    left: 5,
                    bottom: 5,
                  }}
                  z-index={0}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    label={{
                      value: `Couch Override Ratio (%)`,
                      angle: -90,
                      position: 'insideLeft',
                    }}
                    tickFormatter={(tick) => parseFloat(tick.toFixed(4))}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {machineList?.map((machine) => (
                    <Line
                      type="monotone"
                      name={machine}
                      dataKey={machine}
                      stroke={nameToColor[machine]}
                    />
                  ))}
                  {percentileData &&
                    Object.keys(percentileData).map((key) => (
                      <ReferenceLine
                        y={percentileData[key]}
                        stroke={percentileToColor[key]}
                        strokeDasharray="6 6"
                        ifOverflow="extendDomain"
                      />
                    ))}
                </LineChart>
                {nameToColor && (
                  <InsightLegend
                    nameToColor={nameToColor}
                    machines={machineList}
                    percentileData={percentileData}
                  />
                )}
              </Styled.FlexRow>
              <Styled.FlexRow>
                <Legend
                  dataTypes={metrics}
                  nameToColor={metricColors}
                  onClick={handleMetricChange}
                  checked={checked}
                />
              </Styled.FlexRow>
            </Styled.FlexColumn>
          )}
          {activeChart === 'IGRT Shift' &&
            couchShiftOptionData &&
            couchShiftData && (
              <Styled.FlexColumn
                style={{ padding: '30px', width: '80%', minHeight: '500px' }}
              >
                <Styled.FlexRow
                  style={{ justifyContent: 'right', marginBottom: '30px' }}
                >
                  <InstitutionsMenu
                    options={couchShiftOptionData?.hospitals.map(
                      (hospital, index) => ({ value: index, label: hospital })
                    )}
                    onChange={handleHospitalChange}
                  />
                  <SitesMenu
                    options={couchShiftOptionData.sites.map(
                      (siteOption, index) => ({
                        value: index,
                        label: siteOption,
                      })
                    )}
                    onChange={handleSiteChange}
                  />
                </Styled.FlexRow>
                <Styled.FlexRow>
                  <BoxPlotChart
                    plotData={couchShiftData?.[hospital]?.data?.map(
                      (point) => ({
                        name: point.month,
                        min: point.min,
                        max: point.max,
                        upperQuartile: point.percentile75,
                        lowerQuartile: point.percentile25,
                        median: point.percentile50,
                      })
                    )}
                    threshold={30}
                  />
                  {couchShiftData?.[hospital]?.excluded && (
                    <Styled.FlexRow
                      style={{
                        position: 'relative',
                        right: '900px',
                        top: '50px',
                        minWidth: '400px',
                      }}
                    >
                      Couch shift data not available for this institution.
                    </Styled.FlexRow>
                  )}
                </Styled.FlexRow>
                <Styled.FlexRow style={{ justifyContent: 'center' }}>
                  <Legend
                    dataTypes={shiftMetrics}
                    nameToColor={shiftMetricColors}
                    onClick={handleShiftMetricChange}
                    checked={shiftChecked}
                  />
                </Styled.FlexRow>
              </Styled.FlexColumn>
            )}
          {(!couchShiftOptionData?.sites?.length ||
            !couchShiftOptionData?.hospitals.length) &&
            activeChart === 'Couch Shift' && <div>No Couch Shift Data</div>}
        </Styled.FlexRow>
      )}
      {(((overrideLoading || percentileLoading || machineDataLoading) &&
        activeChart === 'Couch Override Ratio' &&
        machineList?.length !== 0) ||
        (activeChart === 'Couch Shift' &&
          (couchShiftOptionDataLoading || couchShiftDataLoading) &&
          couchShiftOptionData?.sites?.length &&
          couchShiftOptionData?.hospitals.length)) && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
    </>
  );
};

export default Insight;
