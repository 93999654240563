import Colors from '../../styles/Colors';
import Options from '../../util/Options';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Legend = styled(Styled.FlexColumn)`
  ${TypeStyles.LABEL_TEXT}
  z-index: 9;
  position: relative;
  top: 30px;
`;

const Icons = styled.div`
  height: 10px;
  width: 40px;
  position: relative;
  padding: 5px;
`;

const LegendText = styled(Styled.FlexRow)`
  color: ${Colors.GRAY_THREE};
  font-size: 13px;
`;

const { imagingOptions } = Options;

type Props = {
  nameToColor: { [key: string]: string };
  isCouchShift: boolean;
};

const ImagingLegend = ({ nameToColor, isCouchShift }: Props) => {
  return (
    <Legend>
      Image Approval Status
      {Object.entries(imagingOptions).map(([status, color]) => (
        <LegendText>
          <Styled.ImagingStatusIcon color={color} type={status} />
          {status}
        </LegendText>
      ))}
      <br />
      <br />
      Couch {isCouchShift ? 'Shift' : 'Position'}
      <LegendText>
        <Icons>
          <svg width={50} height={15}>
            {Object.entries(nameToColor).map(([key, color], index) => (
              <rect
                x={0 + index * 15}
                y={0}
                width="10"
                height="10"
                stroke={color}
                fill={color}
              />
            ))}
          </svg>{' '}
        </Icons>
        Within Tolerance
      </LegendText>
      <LegendText>
        <Icons>
          <svg width={50} height={15}>
            <rect x={0} y={0} width="10" height="10" stroke="red" fill="red" />
          </svg>{' '}
        </Icons>
        Out of Tolerance
      </LegendText>
    </Legend>
  );
};

export default ImagingLegend;
