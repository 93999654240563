import Functions, { filter } from '../util/UtilityFunctions';
import {
  toPatient,
  toPatientInfo,
  toPatients,
  toUnreviewedImagePatients,
} from '../util/convertToTypes';

import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import Styled from '../styles/Styles';
import { mappingAxios } from '../util/api';
import { toast } from 'react-toastify';

export const fetchPatient = async (
  patientId: number,
  pageNumber: number,
  size: number,
  navigate?: NavigateFunction
) => {
  try {
    const params = {
      page: pageNumber,
      pageSize: size,
    };
    const state = await Functions.apiCall({
      route: `/patient/${patientId}`,
      paramsOrData: params,
      navigate,
    });
    return toPatient(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'patient.ts');
  }
};

export const fetchPatients = async (
  pageNumber: number,
  size: number,
  apiFilter?: filter,
  navigate?: NavigateFunction
) => {
  try {
    const params = {
      page: pageNumber,
      pageSize: size,
      ...apiFilter,
    };
    const state = await Functions.apiCall({
      route: '/patients_all',
      paramsOrData: params,
      navigate,
    });
    return toPatients(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'patient.ts');
  }
};

export const fetchPatientInfo = async (
  patientIds: string[],
  disableMappingService: boolean
) => {
  const patientIdsList = Array.from(new Set(patientIds));
  const params = { ids: patientIdsList };
  try {
    const state =
      patientIds !== undefined &&
      patientIds?.[0] !== undefined &&
      !!patientIds?.length &&
      !disableMappingService &&
      (await mappingAxios.get('/patientInfo', {
        params,
      }));
    return toPatientInfo(state?.data);
  } catch (error) {
    toast.error(
      React.createElement(Styled.ErrorToast, {
        message: 'Oops! Something went wrong with the mapping service...',
      }),
      Styled.ToastProps
    );
    Log.error(error, 'patient.ts');
  }
};

export const fetchClinicIds = async (searchTerm: string[]) => {
  const params = {
    nameOrMRN: searchTerm,
  };
  try {
    const state =
      searchTerm !== undefined &&
      (await mappingAxios.get('/patientSearch', { params }));
    return state?.data;
  } catch (error) {
    toast.error(
      React.createElement(Styled.ErrorToast, {
        message:
          'Oops! Something went wrong with the mapping search service...',
      }),
      Styled.ToastProps
    );
    Log.error(error, 'patient.ts');
  }
};

export const fetchUnreviewedImagePatients = async (
  page: number,
  pageSize: number,
  hospitalName: string,
  today: string,
  sortDescending: boolean,
  count: number,
  navigate?: NavigateFunction
) => {
  try {
    const params = {
      page,
      pageSize,
      hospitalName,
      today,
      sortDescending,
    };
    const state =
      count !== 0 &&
      (await Functions.apiCall({
        route: '/images/unreviewed',
        paramsOrData: params,
        navigate,
      }));
    return toUnreviewedImagePatients(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'patient.ts');
  }
};
