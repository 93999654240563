import RadioButton from './RadioButton';
import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const FilterContainer = styled(Styled.FlexRow)`
  margin-left: 400px;
  margin-right: 180px;
  justify-content: center;
  background-color: white;
  position: relative;
  top: 6px;
  z-index: 3;
`;

const Option = styled(Styled.FlexRow)`
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
`;

const options = ['1W', '1M', 'ALL'];

type Props = {
  checked: boolean[];
  handleChange: (index: number) => void;
};

const ChartDateFilter = ({ checked, handleChange }: Props) => {
  return (
    <FilterContainer>
      {options.map((option, index) => (
        <Option onClick={() => handleChange(index)}>
          <RadioButton isChecked={checked[index]} />
          {option}
        </Option>
      ))}
    </FilterContainer>
  );
};

export default ChartDateFilter;
