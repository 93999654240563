import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Footer = styled(Styled.FlexRow)<{ isAdmin?: boolean }>`
  background-color: ${Colors.BLUE_ONE};
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  color: white;
  position: ${(props) => (props.isAdmin ? 'sticky' : 'auto')}
  bottom: 0px;
  ${TypeStyles.LABEL_TEXT}
`;

const Bubble = styled.div`
  border-radius: 15px;
  background-color: white;
  width: 30px;
  height: 20px;
  font-weight: bold;
  text-align: center;
  margin-right: 10px;
  color: ${Colors.BLUE_ONE};
  ${TypeStyles.LABEL_TEXT}
`;

const Clear = styled.div`
  font-weight: 600;
  width: 63px;
  height: 34px;
  background-color: transparent;
  ${TypeStyles.LABEL_TEXT}
  border: 1px solid white;
  border-radius: 4px;
  margin-left: 30px;
  text-align: center;
  padding: 6px 10px 9px 10px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.HOVER_OUTLINE_WHITE};
  }
`;

const Button = styled(Styled.VerifyButton)`
  width: auto;
  margin-left: 12px;
  padding: 6px 10px 9px 10px;
`;

type Props = {
  number: number;
  onClearClick: () => void;
  onClick: () => void;
  isOppositeClick: boolean;
  isAdmin?: boolean;
  notes?: boolean;
  customText?: string;
};

const ListFooter = ({
  number,
  onClearClick,
  onClick,
  isOppositeClick,
  isAdmin,
  notes,
  customText,
}: Props) => {
  const eventText =
    number === 1 ? (notes ? 'Event' : 'Note') : notes ? 'Notes' : 'Events';
  const verifyText = isOppositeClick ? 'Unverify' : 'Verify';

  const optionText = number === 1 ? 'Option' : 'Options';
  const enableText = isOppositeClick ? 'Disable' : 'Enable';
  return (
    <Footer isAdmin={isAdmin}>
      <Bubble>{number}</Bubble> {isAdmin ? optionText : eventText} Selected
      <Clear onClick={onClearClick}>Clear</Clear>
      <Button onClick={onClick} verified={isOppositeClick}>
        {isAdmin ? customText || enableText : verifyText}{' '}
        {customText ? '' : isAdmin ? optionText : eventText}
      </Button>
    </Footer>
  );
};

export default ListFooter;
