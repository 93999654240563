import Functions, { filter } from '../util/UtilityFunctions';
import React, { useEffect, useState } from 'react';

import Colors from '../styles/Colors';
import DashboardCard from '../components/dashboard/DashboardCard';
import MachineCard from '../components/machine/MachineCard';
import Styled from '../styles/Styles';
import UnreviewedImagesCard from '../components/dashboard/UnreviewedImagesCard';
import { fetchCounts } from '../api/event';
import routes from '../routes';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const ColumnOne = styled.div`
  grid-column: 2 / 7;
`;

const ColumnTwo = styled.div`
  grid-column: 8 / 13;
`;

const MachineCardContainer = styled.div`
  grid-column: 2 / 13;
`;

const UnreviewedImagesCardContainer = styled.div`
  grid-column: 14 / 33;
`;

const Row = styled(Styled.FlexRow)`
  justify-content: space-between;
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const today = Functions.timeFilterToApiDate(0);
  const savedFilter: filter = JSON.parse(
    localStorage.getItem('dashboardFilter')
  );
  const savedTimeFilter = Functions.timeFilterToApiDate(
    savedFilter?.timeFilter
  );
  const [countFilter, setCountFilter] = useState(
    savedFilter?.timeFilter ? savedTimeFilter : today
  );
  const tick = () => {
    const newCount = count < 10 ? count + 1 : 1;
    setCount(newCount);
  };
  const { data, loading } = usePromise(fetchCounts, [
    count,
    navigate,
    countFilter,
  ]);
  const highPriorityEvents = data?.highPriorityEvents;
  const unverifiedEvents = data?.unverifiedEvents;
  const unverifiedPatients = data?.unverifiedPatients;
  const readyForBilling = data?.readyForBilling;
  // useEffect(() => {
  //   const timer = setInterval(() => tick(), 300000);
  //   return () => clearInterval(timer);
  // });
  useEffect(() => {
    if (data) {
      const currentIdp = localStorage.getItem('currentIdp');
      const currentEmail = localStorage.getItem('currentEmail');

      if (currentIdp) {
        localStorage.setItem('idp', currentIdp);
      }
      if (currentEmail) {
        localStorage.setItem('email', currentEmail);
      }
    }
  }, [data]);

  const setCountsFilter = (num: number) => {
    const day = Functions.timeFilterToApiDate(num);
    setCountFilter(day);
  };

  return (
    <Styled.GridBox>
      {loading && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
      <MachineCardContainer>
        <MachineCard count={count} setCountsFilter={setCountsFilter}>
          {' '}
          <Row>
            <Styled.StyledLink to={routes.eventsHighPriority}>
              <DashboardCard
                color={Colors.RED}
                number={highPriorityEvents}
                label="High Priority Events"
                id="highPriorityEvents"
              />
            </Styled.StyledLink>
            <Styled.StyledLink to={routes.eventsUnverified}>
              <DashboardCard
                color={Colors.BLUE_TWO}
                number={unverifiedEvents}
                label="Unverified Events"
                id="unverifiedEvents"
              />
            </Styled.StyledLink>
          </Row>
          <Row>
            <Styled.StyledLink to={routes.patientsUnverified}>
              <DashboardCard
                color={Colors.BLUE_TWO}
                number={unverifiedPatients}
                label="Patients with Unverified Events"
                id="unverifiedPatients"
              />
            </Styled.StyledLink>
            <Styled.StyledLink to={routes.patientsReadyForBilling}>
              <DashboardCard
                color={Colors.BLUE_TWO}
                number={readyForBilling}
                label="Patients with No Pending Events"
                id="patientsReadyForBilling"
              />
            </Styled.StyledLink>
          </Row>
        </MachineCard>
      </MachineCardContainer>
      <UnreviewedImagesCardContainer>
        <UnreviewedImagesCard count={count} />
      </UnreviewedImagesCardContainer>
    </Styled.GridBox>
  );
};

export default Dashboard;
