import React, { useState } from 'react';

import Checkbox from '../ui/Checkbox';
import { Rule } from '../../types/Admin';
import Styled from '../../styles/Styles';

type Props = {
  option: Rule;
  isChecked: boolean;
  onClick: () => void;
  checkboxClick: () => void;
};

const AdminRow = ({ option, isChecked, onClick, checkboxClick }: Props) => {
  const { description, enabled, priority } = option;
  const [isShown, setIsShown] = useState(false);
  const priorities = { 1: 'High', 2: 'Medium', 3: 'Low', 4: 'Pass' };

  return (
    <Styled.Row
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Styled.ItemType style={{ flexBasis: '45px' }} inactive={!enabled}>
        <Checkbox isChecked={isChecked} onClick={checkboxClick} />
      </Styled.ItemType>
      <Styled.ItemType style={{ flexBasis: '300px' }} inactive={!enabled}>
        {description}
      </Styled.ItemType>
      <Styled.ItemType style={{ flexBasis: '100px' }} inactive={!enabled}>
        {priorities[priority]}
      </Styled.ItemType>
      {/* <Styled.ItemType style={{ flexBasis: '300px' }} inactive={!enabled}>
        n/a
      </Styled.ItemType> */}
      {isShown && <Styled.ViewButton onClick={onClick}>View</Styled.ViewButton>}
    </Styled.Row>
  );
};

export default AdminRow;
