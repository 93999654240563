import { Reducer, useMemo, useReducer } from 'react';

import axios from 'axios';
import qs from 'qs';

interface State<T> {
  loading: boolean;
  error: boolean;
  data?: T | null;
}

type Start = { type: 'start' };
type Finish<T> = { type: 'finish'; data: State<T>['data'] };
type Error = { type: 'error' };

type Action<T> = Start | Finish<T> | Error;

const initialState: State<any> = { loading: false, error: false };

const reducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case 'start':
      return { ...(state || initialState), loading: true };
    case 'finish':
      return action.data !== undefined
        ? { ...state, loading: false, data: action.data }
        : { ...state, loading: true, data: action.data };
    case 'error':
      return { ...state, loading: false, error: true };
    default:
      return { ...state, loading: false, error: true };
  }
};

export const useHandlePromise = <T = any, Args = any>(
  promise: (...args: Args[]) => Promise<T>
): [State<T>, (...args: Args[]) => Promise<T>] => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(
    reducer,
    initialState
  );

  const fetch = async (...args: Args[]): Promise<T> => {
    dispatch({ type: 'start' });
    try {
      const data = await promise(...args);
      dispatch({ type: 'finish', data });
      return data;
    } catch (e) {
      dispatch({ type: 'error' });
      throw e;
    }
  };

  return [state, fetch];
};

export const usePromise = <T, Args>(
  promise: (...args: Args[]) => Promise<T>,
  args: Args[]
) => {
  const [state, fetch] = useHandlePromise(promise);
  // eslint-disable-next-line
  useMemo(() => fetch(...args), [...args]);
  return state;
};

export const toJson = async (resp: Response) => resp.json();

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const mappingAxios = axios.create({
  baseURL: process.env.REACT_APP_MAPPING_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});
