import Functions, { filter } from '../util/UtilityFunctions';
import {
  toClinicStatus,
  toCounts,
  toEvent,
  toEvents,
  toPlot,
} from '../util/convertToTypes';

import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import { toast } from 'react-toastify';

export const fetchAllEvents = async (
  pageNumber: number,
  size: number,
  apiFilter?: filter,
  patientId?: number,
  rtPlanId?: number,
  navigate?: NavigateFunction
) => {
  try {
    const url: string =
      patientId && rtPlanId
        ? `/events_all/${patientId}/${rtPlanId}`
        : '/events_all';
    const params = {
      page: pageNumber,
      pageSize: size,
      ...apiFilter,
    };
    const state = await Functions.apiCall({
      route: url,
      paramsOrData: params,
      navigate,
    });
    return toEvents(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const fetchEvent = async (
  eventId: string,
  navigate: NavigateFunction
) => {
  try {
    const state =
      eventId !== 'undefined' &&
      (await Functions.apiCall({ route: `/event/${eventId}`, navigate }));
    return toEvent(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const fetchPlot = async (
  rtPlanId: string,
  plotParams: string[],
  isCouchShift?: boolean,
  navigate?: NavigateFunction
) => {
  try {
    const params = { params: plotParams };
    const state =
      plotParams?.length &&
      (await Functions.apiCall({
        route: isCouchShift
          ? `/couch_shift_plot/${rtPlanId}`
          : `/plot/${rtPlanId}`,
        paramsOrData: params,
        navigate,
      }));
    return toPlot(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const verifyEvent = async (
  eventIds: string[],
  unverify?: boolean,
  navigate?: NavigateFunction
) => {
  const url = unverify ? '/unverify' : '/verify';
  const data = { eventIds };
  const state = await Functions.apiCall({
    route: url,
    isPostRequest: true,
    paramsOrData: data,
    navigate,
  });
  return state;
};

export const saveComment = async (
  eventId: string,
  comment: string,
  navigate: NavigateFunction
) => {
  const data = {
    comment,
    eventId,
  };
  const state = await Functions.apiCall({
    route: '/comment',
    isPostRequest: true,
    paramsOrData: data,
    navigate,
  });
  return state;
};

export const handleVerify = async (
  events: string[],
  afterVerify: () => void,
  unverify?: boolean,
  handleTreatmentCardRefresh?: (num: number) => void,
  navigate?: NavigateFunction
) => {
  try {
    const res = await verifyEvent(events, unverify, navigate);

    if (handleTreatmentCardRefresh) {
      handleTreatmentCardRefresh(res.data.unverifiedEvents);
    }
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(
      `${unverify ? 'Unverified' : 'Verified'} Events: ${events}`,
      'event.ts'
    );
    afterVerify();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const handleSaveComment = async (
  eventId: string,
  comment: string,
  afterSave: () => void,
  navigate: NavigateFunction
) => {
  try {
    await saveComment(eventId, comment, navigate);
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(`Saved Comment On Event ${eventId}: ${comment}`, 'event.ts');
    afterSave();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const fetchCounts = async (
  count: number,
  navigate: NavigateFunction,
  afterDate?: string
) => {
  try {
    const params = {
      afterDate,
    };
    const state =
      count !== 0 &&
      (await Functions.apiCall({
        route: '/counts',
        paramsOrData: params,
        navigate,
      }));
    return toCounts(state?.data);
  } catch (error) {
    if (error?.response) {
      if (error.response.status === 401) {
        navigate('/login');
      }
    }
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};

export const fetchActiveRules = async (navigate: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({
      route: '/clinic_status',
      navigate,
    });
    return toClinicStatus(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'event.ts');
  }
};
