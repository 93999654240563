import Colors from '../styles/Colors';
import Priority from '../types/Priority';

enum VerificationOption {
  UNVERIFIED = 0,
  VERIFIED = 1,
  ALLVERIFIED = 2,
}

const verifiedOptions = [
  { value: VerificationOption.UNVERIFIED, label: 'Unverified' },
  { value: VerificationOption.VERIFIED, label: 'Verified' },
  { value: VerificationOption.ALLVERIFIED, label: 'All Verification' },
];

const priorityOptions = [
  {
    value: 0,
    label: 'High',
    priority: Priority.high,
  },
  {
    value: 1,
    label: 'Medium',
    priority: Priority.med,
  },
  {
    value: 2,
    label: 'Low',
    priority: Priority.low,
  },
  {
    value: 3,
    label: 'Pass',
    priority: Priority.pass,
  },
];

const timeOptions = [
  { value: 0, label: 'Today' },
  { value: 1, label: 'Past 7 Days' },
  { value: 2, label: 'Past Month' },
  { value: 3, label: 'All Time' },
  { value: 4, label: 'Custom' },
];

export enum TimeFilterOptions {
  TODAY = 0,
  PAST_WEEK = 1,
  PAST_MONTH = 2,
  ALL_TIME = 3,
  CUSTOM = 4,
}

const eventOptions = [
  { value: 'couchPositionCheck', label: 'Couch Position Check' },
  { value: 'couchRotationCheck', label: 'Couch Rotation Check' },
  { value: 'gantryAngleCheck', label: 'Gantry Angle Check' },
  { value: 'collimatorAngleCheck', label: 'Collimator Angle Check' },
  { value: 'deliveredDose', label: 'Delivered Dose' },
  { value: 'treatmentFrequency', label: 'Treatment Frequency' },
  { value: 'prescriptionChange', label: 'Prescription Change' },
  { value: 'jawPositionCheck', label: 'Jaw Position Check' },
  { value: 'treatmentDataConsistency', label: 'Treatment Data Consistency' },
  { value: 'couchShiftCheck', label: 'Couch Position IGRT Shift Check' },
  { value: 'imageRejection', label: 'Image Rejection' },
  { value: 'treatmentModality', label: 'Treatment Modality Consistency' },
  { value: 'beamModifier', label: 'Beam Modifying Devices' },
  { value: 'treatmentMachine', label: 'Treatment Machine Check' },
  { value: 'noteAddition', label: 'Notes' },
];

const getOptions = (options: string[]) => {
  return Array.from(new Set(options)).map((option, index) => ({
    value: index,
    label: option,
  }));
};

const ruleOptions = {
  'Couch Position Check': {
    name: 'Couch Position Override',
    description: 'Check whether the couch position exceeds the tolerance.',
  },
  'Couch Rotation Check': {
    name: 'Couch Rotation Override',
    description:
      'Check whether the couch rotation angle exceeds the tolerance.',
  },
  'Gantry Angle Check': {
    name: 'Gantry Angle Override',
    description: 'Check whether the gantry angle exceeds the tolerance.',
  },
  'Collimator Angle Check': {
    name: 'Collimator Angle Override',
    description: 'Check whether the collimator angle exceeds the tolerance.',
  },
  'Delivered Dose': {
    name: 'Dose Consistency',
    description:
      'Check whether the delivered dose is the same as the prescription dose.',
  },
  'Treatment Frequency': {
    name: 'Treatment Frequency',
    description: 'Check whether there are any missing/extra treatments.',
  },
  'Prescription Change': {
    name: 'Prescription Change',
    description:
      'Check whether the prescription is re-approved after the treatment starts.',
  },
  'Jaw Position Check': {
    name: 'Jaw Position Override',
    description: 'Check whether the jaw position exceeds the tolerance.',
  },
  'Treatment Data Consistency': {
    name: 'Treatment Data Consistency',
    description: 'Check for missing treatment records in MOSAIQ.',
  },
  'Couch Position IGRT Shift Check': {
    name: 'IGRT Shift',
    description:
      'Check whether the couch shift between the treatment and 1st imaging beam exceeds the tolerance',
  },
  'Image Rejection': {
    name: 'Image Rejection',
    description: 'Check whether the image is rejected.',
  },
  'Treatment Modality Consistency': {
    name: 'Treatment Modality Consistency',
    description:
      'Check whether the treatment modality is consistent with the prescription.',
  },
  'Beam Modifying Devices': {
    name: 'Beam Modifying Devices',
    description:
      'Check whether the beam modifying devices are consistent with the prescription.',
  },
  'Treatment Machine': {
    name: 'Treatment Machine Match',
    description:
      'Check whether the machine that performed the treatment is consistent with the assigned machine.',
  },
  'Note Addition': {
    name: 'Notes Added',
    description: 'Check if new notes are added.',
  },
};

const imagingOptions = {
  Approved: `${Colors.GREEN}`,
  'Partial Approved': `${Colors.GREEN}`,
  Rejected: `${Colors.RED}`,
  'Partial Rejected': `${Colors.RED}`,
  'Not Reviewed': `${Colors.GRAY}`,
};

const metricOptions = [
  { value: 0, label: 'Couch Longitudinal Override Ratio' },
  { value: 1, label: 'Couch Vertical Override Ratio' },
  { value: 2, label: 'Couch Lateral Override Ratio' },
];

enum RuleNames {
  COUCH_SHIFT = 'Couch Position IGRT Shift Check',
}

const Options = {
  VerificationOption,
  verifiedOptions,
  priorityOptions,
  timeOptions,
  TimeFilterOptions,
  eventOptions,
  getOptions,
  ruleOptions,
  imagingOptions,
  metricOptions,
  RuleNames,
};

export default Options;
