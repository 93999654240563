import Colors from '../../styles/Colors';
import Priority from '../../types/Priority';
import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const Bubble = styled(Styled.Bubble)`
  width: 41px;
  height: 18px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-align: center;
`;

const PRIORITY_COLOR = {
  high: Colors.RED,
  med: Colors.YELLOW,
  low: Colors.GREEN,
  pass: Colors.BLUE_TWO,
};

type Props = {
  priority: Priority;
  children?: React.ReactNode;
};

const PriorityBubble = ({ priority }: Props) => {
  const str = String(Priority[priority]); // TODO fix this in convertToTypes
  return <Bubble color={PRIORITY_COLOR[str]}>{str?.toUpperCase()}</Bubble>;
};

export default PriorityBubble;
