import {
  toFinalPatients,
  toHospitals,
  toWeeklyPatients,
} from '../util/convertToTypes';

import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import moment from 'moment';
import { toast } from 'react-toastify';

export const fetchHospitals = async (navigate: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({
      route: 'clinic_hospitals',
      navigate,
    });
    return toHospitals(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'checklist.ts');
  }
};

export const fetchFinalPatients = async (
  page: number,
  pageSize: number,
  today: Date,
  hospitalName: string,
  sortDescending: boolean,
  navigate: NavigateFunction
) => {
  try {
    const day = moment(today).format('YYYY-MM-DD');
    const params = {
      page,
      pageSize,
      today: day,
      hospitalName,
      sortDescending,
    };
    const state =
      hospitalName &&
      (await Functions.apiCall({
        route: 'patients_final',
        paramsOrData: params,
        navigate,
      }));
    return toFinalPatients(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'checklist.ts');
  }
};

export const fetchWeeklyPatients = async (
  page: number,
  pageSize: number,
  today: Date,
  hospitalName: string,
  sortDescending: boolean,
  navigate: NavigateFunction
) => {
  try {
    const day = moment(today).format('YYYY-MM-DD');
    const params = {
      page,
      pageSize,
      today: day,
      hospitalName,
      sortDescending,
    };
    const state =
      hospitalName &&
      (await Functions.apiCall({
        route: 'patients_weekly',
        paramsOrData: params,
        navigate,
      }));
    return toWeeklyPatients(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'checklist.ts');
  }
};
