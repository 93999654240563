import React, { useEffect, useState } from 'react';
import { fetchAllEvents, handleVerify } from '../../api/event';
import { fetchNoteText, fetchNotes } from '../../api/note';

import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import { Event } from '../../types/Event';
import EventRow from './EventRow';
import ListFooter from './ListFooter';
import Log from '../../Log';
import NotesFooter from '../notes/NotesFooter';
import SearchBar from '../../components/ui/SearchBar';
import ShowingText from '../../components/ui/ShowingText';
import SortArrow from '../ui/SortArrow';
import Styled from '../../styles/Styles';
import { fetchFeatureFlags } from '../../api/auth';
import { fetchPatientInfo } from '../../api/patient';
import { filter } from '../../util/UtilityFunctions';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

export const FilterBar = styled(Styled.FlexRow)<{ treatmentsPage: boolean }>`
  margin: 15px 20px 30px 20px;
  height: 34px;
  min-width: ${(props) => (props.treatmentsPage ? '1409px' : '1700px')};
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0px;
`;

type Props = {
  handleClick?: (event: Event) => void;
  patientId?: number;
  rtPlanId?: number;
  setEvents?: (events: Event[]) => void;
  setTotalEvents?: (totalEvents: number) => void;
  setMaxPageNumber?: (pageNumber: number) => void;
  pageNumber?: number;
  setPageNumber?: (page: number) => void;
  pageLength?: number;
  handleTreatmentCardRefresh?: (num: number) => void;
  apiFilter?: filter;
  searchTerm?: string;
  handleSearchChange?: (event) => void;
  handleSearch?: () => void;
  handleSetSortBy?: (sortBy: string) => void;
  handleEventRefresh?: () => void;
  children?: React.ReactNode;
  handlePageLengthChange?: (num) => void;
  treatments?: boolean;
};

const EventList = ({
  handleClick,
  patientId,
  rtPlanId,
  setEvents,
  setTotalEvents,
  setMaxPageNumber,
  pageNumber,
  setPageNumber,
  pageLength,
  handleTreatmentCardRefresh,
  apiFilter,
  searchTerm,
  handleSearchChange,
  handleSearch,
  handleSetSortBy,
  handleEventRefresh,
  children,
  handlePageLengthChange,
  treatments,
}: Props) => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];

  delete apiFilter.nameOrMRN;
  const { data, loading, error } = usePromise(fetchAllEvents, [
    pageNumber,
    pageLength,
    apiFilter,
    patientId,
    rtPlanId,
    navigate,
  ]);
  // const events: Event[] = error ? eventList : data?.events;
  const events: Event[] = data?.events;
  const [clinicPatientIds, setClinicPatientIds] = useState([]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [
    clinicPatientIds,
    disableMappingService,
  ]);
  const patientInfo = patientInfoResponse?.data;
  const pages: number = data?.pages;
  const totalEvents: number = data?.totalEvents;

  const minEventNumber: number = pageNumber * pageLength + 1;
  const maxPageNumber: number = pages - 1;
  const maxEventNumber: number =
    pageNumber === maxPageNumber
      ? totalEvents
      : minEventNumber + pageLength - 1;

  const noneSelected =
    events && Object.fromEntries(events.map((event) => [event.id, false]));
  const [selectedEvents, setSelectedEvents] = useState(noneSelected);
  const noSelectedEvents: boolean =
    selectedEvents &&
    Object.values(selectedEvents).every((selected) => !selected);
  const handleSelectAll = () => {
    const selected = Object.fromEntries(
      events.map((event) => [event.id, true])
    );
    setSelectedEvents(selected);
  };
  const handleClearAll = () => {
    setSelectedEvents(noneSelected);
  };
  const handleSelectEvent = (index) => {
    const newSelectedEvents = selectedEvents;
    newSelectedEvents[index] = !selectedEvents[index];
    setSelectedEvents({ ...newSelectedEvents });
  };
  const numberSelectedEvents: number = selectedEvents
    ? Object.values(selectedEvents).reduce(
        (a: number, event) => a + (event ? 1 : 0),
        0
      )
    : 0;
  const handleVerifyEvents = (isUnverify: boolean) => {
    const eventIds: string[] = Object.keys(selectedEvents).filter(
      (key) => selectedEvents[key]
    );
    const afterVerify = () => {
      setSelectedEvents(noneSelected);
      handleEventRefresh();
    };
    handleVerify(eventIds, afterVerify, isUnverify, handleTreatmentCardRefresh);
  };

  const [activeNotesId, setActiveNotesId] = useState([]);
  const [activeNotesDate, setActiveNotesDate] = useState<Date | string>('');
  useEffect(() => {
    handleClearAll();
    if (setEvents) {
      setEvents(events);
      setTotalEvents(totalEvents);
    }
    setClinicPatientIds(events?.map((event) => event.clinicPatientId));
    setActiveNotesId([events?.[0]?.clinicPatientId]);
    setActiveNotesDate(events?.[0]?.eventTime);
  }, [events]);

  useEffect(() => {
    if (setMaxPageNumber) {
      setMaxPageNumber(maxPageNumber);
    }
  }, [maxPageNumber]);

  const selectedKeys =
    selectedEvents &&
    Object.keys(selectedEvents).filter((key) => selectedEvents[key]);
  const isUnverify: boolean =
    Boolean(selectedKeys?.length) &&
    events
      ?.filter((event) => selectedKeys.includes(String(event.id)))
      .every((event) => event.verified);

  Log.trace(`API Filter: ${JSON.stringify(apiFilter)}`, 'EventList.tsx');

  const [notesPage, setNotesPage] = useState(0);
  const [activeNoteTypes, setActiveNoteTypes] = useState([]);
  const { data: noteData, loading: noteLoading } = usePromise(fetchNotes, [
    notesPage,
    10,
    JSON.stringify(activeNotesId),
    activeNotesDate,
    activeNotesDate,
    JSON.stringify(activeNoteTypes),
    navigate,
  ]);

  const noteFilterTypes = noteData?.noteTypes;

  const noteKeys = noteData?.notes.map((note) => note.clinicNoteId) || [];
  const maxNotePageNumber = noteData?.pages - 1;

  const { data: noteTextData, loading: noteTextLoading } = usePromise(
    fetchNoteText,
    [JSON.stringify(noteKeys), disableMappingService]
  );
  const notes = noteTextData;
  const noteObject = noteData?.notes.map((note) => ({
    ...note,
    noteText: notes?.[String(note.clinicNoteId)],
  }));

  const [noteActive, setNoteActive] = useState(false);
  const handleNotesClose = () => {
    setNoteActive(false);
  };
  const [notesIndex, setNotesIndex] = useState(0);
  const handleNotesClick = (id: string, date: Date, index: number) => {
    if (noteActive) {
      if (index === notesIndex) {
        setNoteActive(false);
      }
    } else {
      setNoteActive(true);
    }
    setActiveNotesId([id]);
    setActiveNotesDate(date);
    setNotesIndex(index);
  };
  const handleNotesPageChange = (direction: number) => {
    if (
      !(notesPage === 0 && direction === -1) &&
      !(notesPage === maxNotePageNumber && direction === 1)
    ) {
      setNotesPage(notesPage + direction);
    }
  };
  useEffect(() => {
    setNotesPage(0);
  }, [activeNotesId]);
  return (
    <div
      style={{
        height: '100%',
        minWidth: `${!!patientId ? '1420px' : '1700px'}`,
      }}
    >
      <FilterBar treatmentsPage={!!patientId}>
        {!patientId && (
          <SearchBar
            value={searchTerm}
            onChange={handleSearchChange}
            handleSearch={handleSearch}
          />
        )}
        {children}
        <ShowingText
          minNumber={minEventNumber}
          maxNumber={maxEventNumber}
          totalNumber={totalEvents}
          pageNumber={pageNumber}
          maxPageNumber={maxPageNumber}
          onLeftClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
          onRightClick={() =>
            pageNumber < maxPageNumber && setPageNumber(pageNumber + 1)
          }
          handlePageLengthChange={handlePageLengthChange}
          pageLength={pageLength}
          treatments={treatments}
        />
      </FilterBar>
      {!!numberSelectedEvents && (
        <ListFooter
          number={numberSelectedEvents}
          onClearClick={handleClearAll}
          onClick={() => handleVerifyEvents(isUnverify)}
          isOppositeClick={isUnverify}
        />
      )}
      {loading && (
        <Styled.OverlayBox>
          <Styled.Spinner />
        </Styled.OverlayBox>
      )}
      <Styled.TitleRow>
        <Styled.TitleRowType theme={{ flexBasis: '45px' }}>
          <Checkbox
            isChecked={selectedEvents && !noSelectedEvents}
            onClick={noSelectedEvents ? handleSelectAll : handleClearAll}
            isSelectAll
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType
          theme={{ flexBasis: '70px' }}
          onClick={() => handleSetSortBy('priority')}
        >
          PRIORITY&nbsp;
          <SortArrow
            sortDescending={apiFilter.sortDescending}
            isShown={apiFilter.sortBy === 'priority'}
          />
        </Styled.TitleRowType>
        {!patientId && (
          <>
            <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
              NAME&nbsp;
            </Styled.TitleRowType>
            <Styled.TitleRowType theme={{ flexBasis: '80px' }}>
              MRN&nbsp;
            </Styled.TitleRowType>
          </>
        )}
        <Styled.TitleRowType
          theme={{ flexBasis: '100px' }}
          onClick={() => handleSetSortBy('machine')}
        >
          MACHINE&nbsp;
          <SortArrow
            sortDescending={apiFilter.sortDescending}
            isShown={apiFilter.sortBy === 'machine'}
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType
          theme={{ flexBasis: '200px' }}
          onClick={() => handleSetSortBy('eventTime')}
        >
          TIME&nbsp;
          <SortArrow
            sortDescending={apiFilter.sortDescending}
            isShown={apiFilter.sortBy === 'eventTime'}
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType
          theme={{ flexBasis: '200px' }}
          onClick={() => handleSetSortBy('ruleType')}
        >
          TYPE&nbsp;
          <SortArrow
            sortDescending={apiFilter.sortDescending}
            isShown={apiFilter.sortBy === 'ruleType'}
          />
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '475px' }}>
          DETAILS
        </Styled.TitleRowType>
        <Styled.TitleRowType
          theme={{ flexBasis: '400px', flexGrow: '1', flexShrink: '1' }}
        >
          COMMENT
        </Styled.TitleRowType>
      </Styled.TitleRow>
      <Styled.Line
        color={Colors.ADDITIONAL_BLUE_GRAY}
        style={{ minWidth: `${!!patientId ? '1420px' : '1730px'}` }}
      />
      <div style={{ minHeight: '650px' }}>
        {events?.map((event: Event, index) => (
          <EventRow
            event={event}
            includePatient={!patientId}
            eventsPageButtons={!patientId}
            isChecked={selectedEvents?.[event.id]}
            viewButtonClick={() => handleClick(event)}
            checkboxClick={() => handleSelectEvent(event.id)}
            handleEventRefresh={handleEventRefresh}
            patientInfo={patientInfo?.[event.clinicPatientId]}
            disableMappingService={disableMappingService}
            handleNotesClick={handleNotesClick}
            isHighlighted={notesIndex === index && noteActive}
            index={index}
          />
        ))}
      </div>
      {!loading && !events?.length && (
        <Styled.NoItemsAvailable>No Events Available</Styled.NoItemsAvailable>
      )}
      {noteActive && (
        <Footer>
          <NotesFooter
            notes={noteObject}
            handleClose={handleNotesClose}
            handleNotesPageChange={handleNotesPageChange}
            page={notesPage + 1}
            maxPageNumber={maxNotePageNumber + 1}
            patientInfo={patientInfo?.[String(activeNotesId?.[0])]}
            types={noteFilterTypes}
            setTypes={setActiveNoteTypes}
            activeTypes={activeNoteTypes}
            loading={noteLoading || noteTextLoading}
            disableMappingService={disableMappingService}
            activeId={activeNotesId?.[0]}
          />
        </Footer>
      )}
    </div>
  );
};

export default EventList;
