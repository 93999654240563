import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import React from 'react';
import Styled from '../../styles/Styles';
import { TreatmentPlan } from '../../types/TreatmentPlan';
import styled from 'styled-components';

const Card = styled(Styled.FlexColumn)`
  height: 85px;
  box-sizing: border-box;
  padding: 12px 0px 0px 20px;
  border-top: 1px solid ${Colors.GRAY_FIVE};
  background-color: ${props => props.color || 'white'};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.ADDITIONAL_LIGHT_BLUE};
  }
`;

const EventDetails = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${props => props.color || Colors.GRAY_ONE};
`;

const Date = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.GRAY_THREE};
`;

const Unverified = styled(Styled.Bubble)`
  box-sizing: border-box;
  height: 18px;
  width: 90px;
  text-align: center;
  line-height: 14px;
  font-weight: 600;
  font-size: 10px;
  color: ${Colors.ADDITIONAL_SLATE};
  border: 1px solid ${Colors.ADDITIONAL_SLATE};
  background-color: ${props => props.color || 'transparent'};
`;

const ActiveBar = styled.div`
  height: 85px;
  width: 4px;
  background-color: ${Colors.BLUE_TWO};
  position: relative;
  right: 20px;
  bottom: 12px;
`;

type Props = {
  treatmentPlan: TreatmentPlan;
  isActive: boolean;
  onClick: () => void;
  unverifiedEvents: { [key: string]: number };
};

const TreatmentCard = ({
  treatmentPlan,
  isActive,
  onClick,
  unverifiedEvents,
}: Props) => {
  return (
    <>
      <Styled.SideBarCard
        color={isActive && Colors.ADDITIONAL_LIGHT_BLUE}
        onClick={onClick}
      >
        <Styled.FlexRow>
          {isActive && <Styled.SideBarActiveBar />}
          <Styled.FlexColumn>
            <EventDetails color={isActive && Colors.BLUE_TWO}>
              {treatmentPlan.name}
            </EventDetails>
            <Date>
              {Functions.dateToDate(treatmentPlan.startDate)} -{' '}
              {treatmentPlan.endDate &&
                Functions.dateToDate(treatmentPlan.endDate)}
            </Date>
            <Unverified color={isActive && Colors.ADDITIONAL_LIGHT_BLUE}>
              {unverifiedEvents[treatmentPlan.id]} Unverified
            </Unverified>
          </Styled.FlexColumn>
        </Styled.FlexRow>
      </Styled.SideBarCard>
    </>
  );
};

export default TreatmentCard;
