import React, { useEffect, useState } from 'react';
import ShowingText, { PAGE_LENGTH } from '../ui/ShowingText';
import {
  fetchPatientInfo,
  fetchUnreviewedImagePatients,
} from '../../api/patient';

import Colors from '../../styles/Colors';
import { InstitutionsMenu } from '../ui/RadioMenu';
import SortArrow from '../ui/SortArrow';
import Styled from '../../styles/Styles';
import UnreviewedImageRow from '../dashboard/UnreviewedImageRow';
import { fetchFeatureFlags } from '../../api/auth';
import { fetchHospitals } from '../../api/checklist';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

type Props = {
  count: number;
};

const UnreviewedImagesCard = ({ count }: Props) => {
  const navigate = useNavigate();
  const { data: featureFlags } = usePromise(fetchFeatureFlags, []);
  const disableMappingService = featureFlags?.['disableMappingService'];

  const initialHospitalOptions = [{ value: 0, label: 'No Hospitals' }];
  const [hospitalOptions, setHospitalOptions] = useState(
    initialHospitalOptions
  );
  const initialHospital = localStorage.getItem('unreviewedImageHospital') || '';
  const [hospital, setHospital] = useState(initialHospital);
  const initialPage = Number(localStorage.getItem('unreviewedImagePage')) || 0;
  const initialPageLength =
    Number(localStorage.getItem('unreviewedImagePageLength')) || PAGE_LENGTH[0];
  const [pageNumber, setPageNumber] = useState(initialPage);
  const [pageLength, setPageLength] = useState(initialPageLength);
  const date = moment(new Date()).format('YYYY-MM-DD');

  const initialSortDescending =
    Boolean(localStorage.getItem('unreviewedImageSortDescending')) || true;
  const [sortDescending, setSortDescending] = useState<boolean>(
    initialSortDescending
  );
  const { data: hospitalData } = usePromise(fetchHospitals, [navigate]);
  const { data: imagePatients } = usePromise(fetchUnreviewedImagePatients, [
    pageNumber,
    pageLength,
    hospital,
    date,
    sortDescending,
    count,
    navigate,
  ]);

  const handleHospitalChange = (checked: number) => {
    setHospital(hospitalData.hospitals[checked]);
  };

  useEffect(() => {
    if (hospitalData?.hospitals?.filter(Boolean).length) {
      const newHospitalOptions = hospitalData?.hospitals?.map(
        (institution, index) => ({
          value: index,
          label: institution,
        })
      );
      if (!initialHospital) {
        setHospital(hospitalData.hospitals[0]);
      }
      setHospitalOptions(newHospitalOptions);
    }
  }, [hospitalData]);

  useEffect(() => {
    localStorage.setItem('unreviewedImageHospital', hospital);
  }, [hospital]);
  useEffect(() => {
    localStorage.setItem('patientsPage', String(pageNumber));
  }, [pageNumber]);
  const initialHospitalIndex = hospitalOptions?.findIndex(
    (hos) => hos?.label === hospital
  );
  const totalPatients = imagePatients?.totalPatients;
  const maxPageNumber = imagePatients?.pages - 1;
  const minPatientNumber: number = pageNumber * pageLength + 1;
  const maxPatientNumber: number = Math.min(
    totalPatients,
    pageLength * (pageNumber + 1)
  );
  const patients = imagePatients?.patients;

  const handlePageLengthChange = (num: number) => {
    setPageLength(num);
    localStorage.setItem('unreviewedImagePageLength', String(num));
    setPageNumber(0);
  };

  const [clinicPatientIds, setClinicPatientIds] = useState([]);
  useEffect(() => {
    setClinicPatientIds(
      imagePatients?.patients?.map((patient) => patient.clinicPatientId)
    );
  }, [imagePatients]);
  const patientInfoResponse = usePromise(fetchPatientInfo, [
    clinicPatientIds,
    disableMappingService,
  ]);
  const patientInfo = patientInfoResponse?.data;
  return (
    <Styled.CardBorder>
      <Styled.CardTitle>
        <Styled.CardTitleText>Unreviewed Images</Styled.CardTitleText>
        {hospitalOptions?.[0]?.label !== 'No Hospitals' && (
          <InstitutionsMenu
            options={hospitalOptions}
            onChange={handleHospitalChange}
            initialFilter={
              initialHospitalIndex !== -1 ? initialHospitalIndex : 0
            }
          />
        )}
      </Styled.CardTitle>
      <Styled.FlexRow
        style={{ margin: '20px 10px 0px 0px', justifyContent: 'right' }}
      >
        <ShowingText
          minNumber={minPatientNumber}
          maxNumber={maxPatientNumber}
          totalNumber={totalPatients}
          pageNumber={pageNumber}
          maxPageNumber={maxPageNumber}
          onLeftClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
          onRightClick={() =>
            pageNumber < maxPageNumber && setPageNumber(pageNumber + 1)
          }
          patients
          handlePageLengthChange={(num) => handlePageLengthChange(num)}
          pageLength={pageLength}
        />
      </Styled.FlexRow>
      <Styled.TitleRow>
        <Styled.TitleRowType theme={{ flexBasis: '80px' }}>
          MRN&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
          NAME&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType
          onClick={() => {
            setSortDescending(!sortDescending);
          }}
          theme={{ flexBasis: '100px' }}
        >
          MACHINE&nbsp;
          <SortArrow sortDescending={sortDescending} isShown />
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '100px' }}>
          SITE&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
          DETAILS&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '200px' }}>
          IMAGE TIME&nbsp;
        </Styled.TitleRowType>
      </Styled.TitleRow>
      <Styled.Line color={Colors.ADDITIONAL_BLUE_GRAY} />
      {patients?.map((patient) => (
        <UnreviewedImageRow
          patient={patient}
          patientInfo={patientInfo?.[patient.clinicPatientId]}
          disableMappingService={disableMappingService}
        />
      ))}
    </Styled.CardBorder>
  );
};

export default UnreviewedImagesCard;
