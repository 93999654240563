import React, { useEffect, useState } from 'react';
import { fetchNoteTypeConfig, handleSaveNoteTypeConfig } from '../../api/note';

import Checkbox from '../ui/Checkbox';
import Styled from '../../styles/Styles';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const NoteTypes = () => {
  const navigate = useNavigate();
  const { data } = usePromise(fetchNoteTypeConfig, [navigate]);
  const types = data?.configs;
  const [config, setConfig] = useState([]);
  const [currentConfig, setCurrentConfig] = useState([]);
  useEffect(() => {
    setCurrentConfig(types);
    setConfig(types);
  }, [data]);
  const onClick = (type: string) => {
    const newConfig = [...config];
    const index = newConfig.findIndex((noteType) => noteType.type === type);
    const noteType = {
      ...newConfig[index],
      enabled: !newConfig[index].enabled,
    };
    newConfig[index] = noteType;
    setConfig(newConfig);
  };
  const isDisabled = JSON.stringify(currentConfig) === JSON.stringify(config);

  const handleCancel = () => {
    setConfig(currentConfig);
  };
  const afterSave = () => {
    setCurrentConfig(config);
  };
  const handleSave = () => {
    handleSaveNoteTypeConfig(config, afterSave);
  };

  return (
    <Styled.FlexColumn style={{ fontWeight: 'bold', marginLeft: '10px' }}>
      Types
      {config?.map((type) => (
        <Styled.FlexRow
          style={{
            justifyContent: 'space-between',
            height: '20px',
            alignItems: 'center',
            fontWeight: 'normal',
          }}
        >
          <Checkbox
            isChecked={type.enabled}
            onClick={() => {
              onClick(type.type);
            }}
          />{' '}
          &nbsp;
          {type.type}
        </Styled.FlexRow>
      ))}
      <Styled.ButtonRow>
        <Styled.CancelButton
          onClick={() => !isDisabled && handleCancel()}
          enabled={!isDisabled}
        >
          Cancel
        </Styled.CancelButton>
        <Styled.SaveButton
          onClick={() => !isDisabled && handleSave()}
          enabled={!isDisabled}
        >
          Save
        </Styled.SaveButton>
      </Styled.ButtonRow>
    </Styled.FlexColumn>
  );
};

export default NoteTypes;
