enum TypeStyles {
  LABEL_TEXT = 'font-size: 14px; line-height: 19px;',
  TITLE_ROW_TEXT = 'font-size: 14px; font-weight: 600; letter-spacing: 0.2px; line-height: 17px; color: #033775;',
  TITLE_TEXT = 'font-weight: bold; font-size: 22px; line-height: 30px;',
  HEADING_ONE = 'font-size: 18px; font-weight: bold; line-height: 24px;',
  HEADING_TWO = 'font-size: 22px; line-height: 28px; font-weight:500',
  CARD_TITLE_TEXT = 'font-size: 18px; font-weight: bold; line-height: 24px;',
}

export default TypeStyles;
