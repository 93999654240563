import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const Metric = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${props => props.color || Colors.GRAY_ONE};
  align-self: flex-center;
`;

type Props = {
  isActive: boolean;
  onClick: () => void;
  metric: string;
};

const InsightMetricsCard = ({ isActive, onClick, metric }: Props) => {
  return (
    <Styled.SideBarCard
      height="40px"
      color={isActive && Colors.ADDITIONAL_LIGHT_BLUE}
      onClick={onClick}
    >
      <Styled.FlexRow>
        {isActive && <Styled.SideBarActiveBar height="40px" />}
        <Styled.FlexColumn>
          <Metric color={isActive && Colors.BLUE_TWO}>{metric}</Metric>
        </Styled.FlexColumn>
      </Styled.FlexRow>
    </Styled.SideBarCard>
  );
};

export default InsightMetricsCard;
