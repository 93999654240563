import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

type Props = {
  isChecked: boolean;
  onClick?: () => void;
  isSelectAll?: boolean;
};

const Checkbox = ({ isChecked, onClick, isSelectAll }: Props) => {
  let Checkbox = <Styled.UncheckedBox />;
  if (isChecked && isSelectAll) {
    Checkbox = <Styled.IndeterminateCheckbox />;
  }
  if (isChecked && !isSelectAll) {
    Checkbox = <Styled.CheckedBox />;
  }

  return (
    <CheckboxContainer onClick={onClick}>
      <HiddenCheckbox checked={isChecked} />
      {Checkbox}
    </CheckboxContainer>
  );
};

export default Checkbox;
