import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import { Note } from '../../types/Event';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Footer = styled(Styled.FlexColumn)`
  background-color: ${Colors.BLUE_ONE};
  width: 100%;
  height: 150px;
  overflow: scroll;
  padding: 0px 10px 0px 20px;
  box-sizing: border-box;
  color: white;
  z-index: 12;
  ${TypeStyles.LABEL_TEXT}
`;

const TitleBar = styled(Styled.FlexRow)`
  background-color: ${Colors.BLUE_ONE};
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0px 10px 0px 20px;
  box-sizing: border-box;
  color: white;
  justify-content: space-between;
  z-index: 12;
  ${TypeStyles.LABEL_TEXT}
`;

const CloseIcon = styled.div`
  cursor: pointer;
  height: 26px;
  width: 24px;

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

const Chevron = styled.div`
  height: 26px;
  width: 24px;
`;

const FooterFooter = styled(Styled.FlexRow)`
  justify-content: right;
  background-color: ${Colors.BLUE_ONE};
  color: white;
  height: 20px;
  align-items: center;
  padding: 10px;
`;

const Notes = styled.div`
  font-size: 11px;
  color: ${Colors.GRAY_FOUR};
`;

const Filter = styled(Styled.FlexRow)<{ active?: boolean }>`
  border-radius: 4px;
  border: 1px solid white;
  background-color: ${props => (props.active ? 'white' : Colors.BLUE_ONE)};
  color: ${props => (props.active ? Colors.BLUE_ONE : 'white')};
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  padding: 2px 5px 2px 5px;
`;

const Container = styled.div`
  position: sticky;
  bottom: 0px;
  height: 210px;
  z-index: 12;
  background-color: ${Colors.BLUE_ONE};
`;

type NoteObject = Note & { noteText: string };

type Props = {
  notes: NoteObject[];
  handleClose?: () => void;
  handleNotesPageChange?: (num: number) => void;
  page?: number;
  maxPageNumber?: number;
  patientInfo?: PatientNameAndMrn;
  types?: string[];
  setTypes?: (type: string[]) => void;
  activeTypes?: string[];
  loading?: boolean;
  disableMappingService?: boolean;
  activeId?: string;
};

const NotesFooter = ({
  notes,
  handleClose,
  handleNotesPageChange,
  page,
  maxPageNumber,
  patientInfo,
  types,
  setTypes,
  activeTypes,
  loading,
  disableMappingService,
  activeId,
}: Props) => {
  const handleSetTypes = (type: string) => {
    const newTypes = [...activeTypes];
    if (activeTypes.includes(type)) {
      newTypes.splice(newTypes.indexOf(type), 1);
    } else {
      newTypes.push(type);
    }
    setTypes(newTypes);
  };
  return (
    <Container>
      {loading ? (
        <Styled.FlexRow
          style={{
            paddingTop: '70px',
          }}
        >
          <Styled.Spinner />
        </Styled.FlexRow>
      ) : (
        <>
          <TitleBar>
            <Styled.FlexRow>
              {disableMappingService
                ? `${activeId}, ${activeId}`
                : patientInfo
                ? Functions.formatPatientName(patientInfo)
                : '-, -'}{' '}
              &nbsp;{' '}
              {disableMappingService
                ? activeId
                : patientInfo
                ? patientInfo.mrn
                : '-'}
              {types?.map(type => (
                <Filter
                  onClick={() => handleSetTypes(type)}
                  active={activeTypes.includes(type)}
                >
                  {type}
                </Filter>
              ))}
            </Styled.FlexRow>
            <CloseIcon onClick={handleClose}>
              <Styled.CloseIcon color={'white'} />
            </CloseIcon>
          </TitleBar>
          <Footer>
            {notes &&
              notes.map(note => (
                <>
                  <Styled.FlexRow
                    style={{ fontWeight: 'bold', marginTop: '15px' }}
                  >
                    {note.type}&nbsp;&nbsp;&nbsp;
                    <Notes>
                      {Functions.dateToNotesDate(note.noteEdited)}
                      &nbsp;&nbsp;&nbsp;
                    </Notes>
                    {note?.multipleCount && (
                      <Styled.FlexRow
                        style={{
                          fontStyle: 'italic',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                      >
                        {note?.multipleCount !== 1 &&
                          `# Duplicated Notes: ${note?.multipleCount}`}
                      </Styled.FlexRow>
                    )}
                  </Styled.FlexRow>
                  {note?.noteText?.split('\n').map(noteLine => (
                    <div>{noteLine}</div>
                  ))}
                </>
              ))}
          </Footer>
          <FooterFooter>
            {maxPageNumber && maxPageNumber !== 0 && maxPageNumber !== 1 && (
              <>
                <Chevron onClick={() => handleNotesPageChange(-1)}>
                  <Styled.ChevronLeft />
                </Chevron>
                {page} / {maxPageNumber}
                <Chevron onClick={() => handleNotesPageChange(1)}>
                  <Styled.ChevronRight />
                </Chevron>
              </>
            )}
          </FooterFooter>
        </>
      )}
    </Container>
  );
};
export default NotesFooter;
