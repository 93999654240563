import { EventNotifyConfig } from '../types/Settings';
import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import { toEventNotifyConfig } from '../util/convertToTypes';
import { toast } from 'react-toastify';

export const fetchEventNotifyConfig = async (navigate: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({
      route: '/event_notify_settings',
      navigate,
    });
    return toEventNotifyConfig(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'settings.ts');
  }
};

export const saveEventNotifyConfig = async (
  config: EventNotifyConfig,
  navigate: NavigateFunction
) => {
  const state = await Functions.apiCall({
    route: '/save_event_notify_settings',
    isPostRequest: true,
    paramsOrData: config,
    navigate,
  });
  return state;
};

export const handleSaveEventNotifyConfig = async (
  config: EventNotifyConfig,
  afterSave: () => void,
  navigate: NavigateFunction
) => {
  try {
    await saveEventNotifyConfig(config, navigate);
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(
      `Event Notifications Saved: ${JSON.stringify(config)}`,
      'settings.ts'
    );
    afterSave();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'settings.ts');
  }
};
