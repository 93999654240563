import Functions from '../../util/UtilityFunctions';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import React from 'react';
import Styled from '../../styles/Styles';
import { UnreviewedImagePatient } from '../../types/Patient';
import { reverse } from 'named-urls';
import routes from '../../routes';

type Props = {
  patient: UnreviewedImagePatient;
  patientInfo?: PatientNameAndMrn;
  disableMappingService: boolean;
};

const UnreviewedImageRow = ({
  patient,
  patientInfo,
  disableMappingService,
}: Props) => {
  return (
    <Styled.StyledLink
      to={`${reverse(`${routes.treatmentsPatients}`, {
        patientID: patient.id,
      })}`}
    >
      <Styled.Row
        style={{
          justifyContent: 'left',
          cursor: 'pointer',
        }}
      >
        <Styled.ItemType theme={{ flexBasis: '80px' }}>
          {disableMappingService
            ? patient?.clinicPatientId
            : patientInfo
            ? patientInfo.mrn
            : '-'}
        </Styled.ItemType>
        <Styled.ItemType
          theme={{ flexBasis: '200px' }}
          style={{ wordBreak: 'break-all' }}
        >
          {disableMappingService
            ? `${patient?.clinicPatientId}, ${patient?.id}`
            : patientInfo
            ? Functions.formatPatientName(patientInfo)
            : '-, -'}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '100px' }}>
          {patient?.machineName}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '100px' }}>
          {patient?.siteName}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '200px' }}>
          {Functions.formatUnreviewedImageFractions(patient)}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '200px' }}>
          {Functions.dateToString(patient.unreviewedImageTime)}
        </Styled.ItemType>
      </Styled.Row>
    </Styled.StyledLink>
  );
};

export default UnreviewedImageRow;
