import React, { useEffect, useState } from 'react';
import { fetchRules, handleSaveRules } from '../../api/admin';

import AdminRow from '../admin/AdminRow';
import Checkbox from '../ui/Checkbox';
import Colors from '../../styles/Colors';
import EventTypeDetails from '../admin/EventTypeDetails';
import Functions from '../../util/UtilityFunctions';
import ListFooter from '../event/ListFooter';
import Options from '../../util/Options';
import Priority from '../../types/Priority';
import { Rule } from '../../types/Admin';
import SearchBar from '../ui/SearchBar';
import SortArrow from '../ui/SortArrow';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const TitleText = styled.div`
  margin: 20px 0px 20px 20px;
  ${TypeStyles.TITLE_TEXT}
`;

const FilterBar = styled(Styled.FlexRow)`
  margin: 15px 20px 30px 20px;
  height: 34px;
`;

const ShowInactive = styled(Styled.FlexRow)`
  align-items: center;
  width: 120px;
  justify-content: space-between;
  color: ${Colors.GRAY_TWO};
  margin-left: 20px;
  ${TypeStyles.LABEL_TEXT}
`;

const { priorityOptions, RuleNames } = Options;

const EventsConfigOptionDetails = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const tick = () => {
    const newCount = count < 10 ? count + 1 : 1;
    setCount(newCount);
  };

  const { data: eventConfigOptions } = usePromise(fetchRules, [
    count,
    navigate,
  ]);
  const [isEventTypeActive, setIsEventTypeActive] = useState(false);
  const [activeEventType, setActiveEventType] = useState<Rule>();
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const [priorityFilter, setPriorityFilter] = useState(
    priorityOptions.map((option) => Priority[String(option.priority)])
  );
  const handlePriorityChange = (checked: boolean[]) => {
    setPriorityFilter(
      checked
        .map(
          (check, index) =>
            check && Priority[String(priorityOptions[index].priority)]
        )
        .filter(Boolean)
    );
  };

  const [inactiveIsShown, setInactiveIsShown] = useState(false);

  const handleEventOptionClick = (option) => {
    setActiveEventType(option);
    setIsEventTypeActive(true);
  };

  const handleClose = () => {
    setIsEventTypeActive(false);
  };

  const [sortDescending, setSortDescending] = useState(true);
  const [sortBy, setSortBy] = useState('type');
  const handleSortChange = (sort) => {
    if (sort === sortBy) {
      setSortDescending(!sortDescending);
    } else {
      setSortDescending(true);
      setSortBy(sort);
    }
  };
  const options: Rule[] = eventConfigOptions
    ?.filter((option) => option.description?.toLowerCase().includes(searchTerm))
    .sort((a, b) => Functions.sortAdminOptions(a, b, sortDescending, sortBy))
    .map((option) => {
      if (option.description.includes(RuleNames.COUCH_SHIFT)) {
        return {
          ...option,
          description: option.description.replace(
            RuleNames.COUCH_SHIFT,
            'IGRT Shift'
          ),
        };
      }
      if (option.description.includes('Check')) {
        return {
          ...option,
          description: option.description.replace('Check', 'Override'),
        };
      }
      if (option.description.includes('Delivered Dose')) {
        return {
          ...option,
          description: 'Dose Consistency',
        };
      }
      return option;
    });
  const noneSelected =
    options?.length &&
    Object.fromEntries(
      options.map((option) => [`${option.ruleType}${option.parameter}`, false])
    );
  const [selectedOptions, setSelectedOptions] = useState(noneSelected);
  const noSelectedOptions: boolean =
    selectedOptions &&
    Object.values(selectedOptions).every((selected) => !selected);
  const handleSelectAll = () => {
    const selected = Object.fromEntries(
      options.map((option) => [
        `${option.ruleType}${option.parameter}`,
        option.enabled || inactiveIsShown,
      ])
    );
    setSelectedOptions(selected);
  };
  const handleClearAll = () => {
    setSelectedOptions(noneSelected);
  };
  const handleSelectOption = (type) => {
    const newSelectedOptions = selectedOptions;
    newSelectedOptions[type] = !selectedOptions[type];
    setSelectedOptions({ ...newSelectedOptions });
  };

  useEffect(() => {
    handleClearAll();
  }, [eventConfigOptions]);

  const handleOptionRefresh = () => {
    tick();
  };

  const numberSelectedOptions: number = selectedOptions
    ? Object.values(selectedOptions).reduce(
        (a: number, option) => a + (option ? 1 : 0),
        0
      )
    : 0;

  const handleSave = (
    active: boolean,
    priority: number,
    customTolerance: number,
    noteTypes: string[]
  ) => {
    const newRules = eventConfigOptions.map((option) => {
      const activeOption =
        String(option.ruleType) === String(activeEventType.ruleType) &&
        String(option.parameter) === String(activeEventType.parameter);
      return {
        enabled: activeOption ? active : option.enabled,
        ruleType: option.ruleType,
        parameter: option.parameter,
        priority: activeOption ? priority : option.priority,
        customTolerance: activeOption
          ? customTolerance
          : option.customTolerance,
        noteTypes: activeOption ? noteTypes : option?.noteTypes,
      };
    });
    const afterSave = () => {
      handleClose();
      handleOptionRefresh();
    };
    handleSaveRules(newRules, afterSave, navigate);
  };

  const selectedKeys =
    selectedOptions &&
    Object.keys(selectedOptions).filter((key) => selectedOptions[key]);
  const handleBulkSave = (isDisable: boolean) => {
    const newRules = eventConfigOptions.map((option) => ({
      enabled: selectedKeys.includes(`${option.ruleType}${option.parameter}`)
        ? !isDisable
        : option.enabled,
      ruleType: option.ruleType,
      parameter: option.parameter,
      priority: option.priority,
      customTolerance: option.customTolerance,
    }));
    const afterSave = () => {
      handleOptionRefresh();
    };
    handleSaveRules(newRules, afterSave, navigate);
  };

  const isDisable: boolean =
    Boolean(selectedKeys?.length) &&
    options
      ?.filter((option) =>
        selectedKeys.includes(String(`${option.ruleType}${option.parameter}`))
      )
      .every((option) => option.enabled);

  return (
    <>
      {!isEventTypeActive && (
        <Styled.FlexColumn style={{ width: '100%' }}>
          <TitleText>Events</TitleText>
          <FilterBar>
            <SearchBar value={searchTerm} onChange={handleSearchChange} admin />
            {/* <FilterMenu
              title="Priorities"
              options={priorityOptions}
              onChange={handlePriorityChange}
            /> */}
            <ShowInactive>
              <Checkbox
                isChecked={inactiveIsShown}
                onClick={() => setInactiveIsShown(!inactiveIsShown)}
              />
              Show Inactive
            </ShowInactive>
          </FilterBar>
          <Styled.TitleRow>
            <Styled.TitleRowType style={{ flexBasis: '45px' }}>
              <Checkbox
                isChecked={!noSelectedOptions && !!selectedOptions}
                onClick={noSelectedOptions ? handleSelectAll : handleClearAll}
                isSelectAll
              />
            </Styled.TitleRowType>
            <Styled.TitleRowType
              style={{ flexBasis: '300px' }}
              onClick={() => handleSortChange('type')}
            >
              Event Type&nbsp;
              <SortArrow
                sortDescending={sortDescending}
                isShown={sortBy === 'type'}
              />
            </Styled.TitleRowType>
            <Styled.TitleRowType
              style={{ flexBasis: '100px' }}
              onClick={() => handleSortChange('priority')}
            >
              Priority&nbsp;
              <SortArrow
                sortDescending={sortDescending}
                isShown={sortBy === 'priority'}
              />
            </Styled.TitleRowType>
            {/* <Styled.TitleRowType style={{ flexBasis: '300px' }}>
              Description&nbsp;
            </Styled.TitleRowType> */}
          </Styled.TitleRow>
          <Styled.Line color={Colors.ADDITIONAL_BLUE_GRAY} />
          <div>
            {options?.map(
              (option) =>
                (option.enabled || inactiveIsShown) && (
                  <AdminRow
                    option={option}
                    isChecked={
                      selectedOptions?.[`${option.ruleType}${option.parameter}`]
                    }
                    onClick={() => handleEventOptionClick(option)}
                    checkboxClick={() =>
                      handleSelectOption(
                        `${option.ruleType}${option.parameter}`
                      )
                    }
                  />
                )
            )}
          </div>
          {!!numberSelectedOptions && (
            <ListFooter
              number={numberSelectedOptions}
              onClearClick={handleClearAll}
              onClick={() => handleBulkSave(isDisable)}
              isOppositeClick={isDisable}
              isAdmin
            />
          )}
        </Styled.FlexColumn>
      )}
      {isEventTypeActive && (
        <EventTypeDetails
          option={activeEventType}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default EventsConfigOptionDetails;
