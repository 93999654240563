import { EventsMenu, MachinesMenu, PrioritiesMenu } from '../ui/FilterMenu';
import Functions, {
  apiFilterOptions,
  filter,
} from '../../util/UtilityFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { TimeMenu, VerificationMenu } from '../ui/RadioMenu';

import { Event } from '../../types/Event';
import EventDetails from '../event/EventDetails';
import EventList from '../event/EventList';
import Options from '../../util/Options';
import { PAGE_LENGTH } from '../ui/ShowingText';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import { fetchAllMachines } from '../../api/machine';
import { fetchEvent } from '../../api/event';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../util/api';

const { getOptions, TimeFilterOptions } = Options;

type Props = {
  eventID?: number;
  patientID?: number;
  rtPlanID?: number;
  treatmentSite?: string;
  handleTreatmentCardRefresh?: (num: number) => void;
  timeFilter?: number;
  patientInfo?: PatientNameAndMrn;
};

const TreatmentEvents = ({
  eventID,
  patientID,
  rtPlanID,
  treatmentSite,
  handleTreatmentCardRefresh,
  timeFilter,
  patientInfo,
}: Props) => {
  const navigate = useNavigate();
  const machineData = usePromise(fetchAllMachines, [
    navigate,
    undefined,
    String(patientID),
    String(rtPlanID),
  ]);
  const { data } = usePromise(fetchEvent, [`${eventID}`, navigate]); // TODO: figure out better way to do a linked event so it's not called twice
  const linkedEvent = data;

  const machines = machineData?.data?.machines?.map(
    (event) => event.machineName
  );
  const machineOptions = machines && getOptions(machines);

  const [events, setEvents] = useState<Event[]>([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageLength, setPageLength] = useState(
    JSON.parse(localStorage.getItem('treatmentsPageLength')) || PAGE_LENGTH[0]
  );
  const [maxPageNumber, setMaxPageNumber] = useState(0);

  const initialTimeFilter = timeFilter || TimeFilterOptions.PAST_WEEK;

  const initialApiFilter: filter = {
    verified: false,
    sortDescending: true,
    sortBy: 'priority',
    priority: ['high', 'med', 'low'],
    eventAfter: Functions.timeFilterToApiDate(Number(initialTimeFilter)),
  };
  const defaultOptions: apiFilterOptions = {
    verified: 2,
    priority: Options.priorityOptions.map(() => true),
    time: 1,
    machine: [],
    event: Options.eventOptions.map(() => true),
  };
  let initialApiFilterOptions: apiFilterOptions =
    (machineOptions !== undefined &&
      Functions.getInitialApiFilterOptions(
        initialApiFilter,
        machineOptions,
        initialTimeFilter
      )) ||
    defaultOptions;
  const [apiFilter, setApiFilter] = useState(initialApiFilter);
  const handleVerifiedChange = (checked: number) => {
    const newFilter: filter = Functions.handleVerifiedChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
  };
  const handlePriorityChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handlePriorityChange(
      apiFilter,
      checked
    );
    setApiFilter({ ...newFilter });
  };
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [timeFilterOption, setTimeFilterOption] = useState(
    initialApiFilterOptions.time
  );
  const handleTimeChange = (checked: number) => {
    setTimeFilterOption(checked);
    const newFilter: filter = Functions.handleTimeChange(
      apiFilter,
      checked,
      customStartDate,
      customEndDate
    );
    setApiFilter({ ...newFilter });
  };
  const handleMachineChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handleMachineChange(
      apiFilter,
      checked,
      machineOptions
    );
    setApiFilter({ ...newFilter });
  };
  const handleEventChange = (checked: boolean[]) => {
    const newFilter: filter = Functions.handleEventChange(apiFilter, checked);
    setApiFilter({ ...newFilter });
  };
  const handleSetSortBy = (sortBy: string) => {
    const newFilter: filter = Functions.handleSetSortBy(apiFilter, sortBy);
    setApiFilter({ ...newFilter });
  };

  const handleRefresh = () => {
    setApiFilter({ ...apiFilter });
  };
  useEffect(() => {
    if (timeFilterOption === TimeFilterOptions.CUSTOM) {
      handleTimeChange(TimeFilterOptions.CUSTOM);
    }
  }, [customStartDate, customEndDate]);

  useEffect(() => {
    if (machineOptions !== undefined) {
      initialApiFilterOptions = Functions.getInitialApiFilterOptions(
        initialApiFilter,
        machineOptions,
        initialTimeFilter
      );
    }
  }, [machineOptions]);

  const [isEventActive, setIsEventActive] = useState(!!eventID);
  const [activeEvent, setActiveEvent] = useState<Event>();
  const [activeEventIndex, setActiveEventIndex] = useState(0);
  const [currentTreatmentPlanId, setCurrentTreatmentPlanId] =
    useState(rtPlanID);
  const [linkedEventFlag, setLinkedEventFlag] = useState(true);
  const handleClick = (event: Event) => {
    setActiveEvent(event);
    setIsEventActive(true);
    setActiveEventIndex(
      events?.findIndex((e) => Number(e?.id) === Number(event?.id))
    );
  };
  const handleClose = () => {
    setIsEventActive(false);
    if (eventID) {
      setLinkedEventFlag(false);
    }
  };
  const pageChangeFlag = useRef(false);
  const handlePageChange = (direction: number) => {
    handleClose();
    const eventIndex = events?.indexOf(activeEvent);
    if (eventIndex === 0 && direction === -1 && maxPageNumber !== 0) {
      setPageNumber((pageNumber + maxPageNumber) % (maxPageNumber + 1));
      pageChangeFlag.current = true;
    }
    if (
      eventIndex === events?.length - 1 &&
      direction === 1 &&
      maxPageNumber !== 0
    ) {
      setPageNumber((pageNumber + 1) % (maxPageNumber + 1));
      pageChangeFlag.current = true;
    }
    const index =
      totalEvents &&
      (pageNumber * pageLength + eventIndex + direction + totalEvents) %
        totalEvents;
    const newIndex = index % pageLength;
    setActiveEventIndex(newIndex);
  };

  useEffect(() => {
    if (!isEventActive) {
      setPageNumber(0);
    }
  }, [apiFilter]);

  useEffect(() => {
    if (eventID && linkedEventFlag) {
      setActiveEvent(linkedEvent);
    } else if (pageChangeFlag.current) {
      setActiveEvent(events?.[activeEventIndex]);
      pageChangeFlag.current = false;
    } else if (isEventActive) {
      setActiveEvent(events?.[activeEventIndex]);
    }
  }, [events]);

  useEffect(() => {
    if (!pageChangeFlag.current) {
      setActiveEvent(events?.[activeEventIndex]);
    }
  }, [activeEventIndex]);

  const [plotParams, setPlotParams] = useState(
    activeEvent && Functions.getPlotParams(activeEvent?.treatmentParameter)
  );
  useEffect(() => {
    setPlotParams(
      activeEvent && Functions.getPlotParams(activeEvent?.treatmentParameter)
    );

    if (eventID && linkedEventFlag) {
      setIsEventActive(true);
    } else if (
      String(activeEvent?.id) !== String(eventID) ||
      (String(activeEvent?.id) === String(eventID) && !linkedEventFlag)
    ) {
      handleClick(activeEvent);
    }
  }, [activeEvent]);

  if (
    currentTreatmentPlanId &&
    rtPlanID &&
    currentTreatmentPlanId !== rtPlanID
  ) {
    setCurrentTreatmentPlanId(rtPlanID);
    setIsEventActive(false);
    if (eventID) {
      setLinkedEventFlag(false);
    }
  }

  const isInitialLinkedEvent =
    Number(activeEvent?.id) === Number(eventID) && linkedEventFlag;
  const handleAfterVerify = (startPage: number, endPage: number) => {
    const lastEvent: boolean = startPage === endPage;
    if (lastEvent) {
      if (startPage !== 1) {
        handlePageChange(-1);
      } else {
        handleClose();
      }
    }
  };

  const { verifiedOptions, priorityOptions, timeOptions, eventOptions } =
    Options;

  const handlePageLengthChange = (num: number) => {
    setPageLength(num);
    localStorage.setItem('treatmentsPageLength', String(num));
    setPageNumber(0);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {machineOptions && (
        <EventList
          handleClick={handleClick}
          patientId={patientID}
          rtPlanId={rtPlanID}
          setEvents={setEvents}
          setTotalEvents={setTotalEvents}
          setMaxPageNumber={setMaxPageNumber}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageLength={pageLength}
          handleTreatmentCardRefresh={handleTreatmentCardRefresh}
          apiFilter={apiFilter}
          handleSetSortBy={handleSetSortBy}
          handleEventRefresh={handleRefresh}
          handlePageLengthChange={(num) => handlePageLengthChange(num)}
          treatments
        >
          <VerificationMenu
            options={verifiedOptions}
            isVerified
            onChange={handleVerifiedChange}
            initialFilter={initialApiFilterOptions.verified}
          />
          <PrioritiesMenu
            options={priorityOptions}
            onChange={handlePriorityChange}
            initialFilter={initialApiFilterOptions.priority}
          />
          <TimeMenu
            options={timeOptions}
            onChange={handleTimeChange}
            initialFilter={initialApiFilterOptions.time}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
          />
          <MachinesMenu
            options={machineOptions}
            onChange={handleMachineChange}
            initialFilter={initialApiFilterOptions.machine}
          />
          <EventsMenu
            options={eventOptions}
            onChange={handleEventChange}
            initialFilter={initialApiFilterOptions.event}
          />
        </EventList>
      )}
      {isEventActive && !!activeEvent && !!currentTreatmentPlanId && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
          }}
        >
          <EventDetails
            event={activeEvent}
            handleClose={handleClose}
            handlePageChange={handlePageChange}
            startPage={pageNumber * pageLength + activeEventIndex + 1}
            endPage={isInitialLinkedEvent ? 1 : totalEvents}
            rtPlanId={String(currentTreatmentPlanId)}
            plotParams={plotParams}
            treatmentSite={treatmentSite}
            handleRefresh={handleRefresh}
            handleTreatmentCardRefresh={handleTreatmentCardRefresh}
            handleAfterVerify={handleAfterVerify}
            isNavigationDisabled={isInitialLinkedEvent || totalEvents === 1}
            patientInfo={patientInfo}
          />
        </div>
      )}
    </div>
  );
};

export default TreatmentEvents;
