import Colors from './Colors';
import React from 'react';
import Styled from './Styles';
import TypeStyles from './TypeStyles';
import styled from 'styled-components';

const Border = styled(Styled.FlexRow).attrs({ as: 'a' })`
  box-sizing: border-box;
  height: 34px;
  width: fit-content;
  border-radius: 4px;
  align-items: center
  justify-content: space-between;
  padding: 7px 14px;
  padding-right: 5px;
  margin-right: 10px;
  cursor: pointer;
`;

type Props = {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
  id?: string;
};

const SelectedBorder = ({ onClick, disabled, children, id }: Props) => {
  const SelectedBorderWrapper = styled(Border)<{ disabled: boolean }>`
    background-color: ${Colors.BLUE_TWO};
    font-weight: 600;
    color: white;

    &:hover {
      background-color: ${props =>
        props.disabled ? Colors.BLUE_TWO : Colors.HOVER_PRIMARY};
    }
    opacity: ${props => props.disabled && '.4'};
    cursor: ${props => props.disabled && 'default'};
  `;
  return (
    <SelectedBorderWrapper
      id={id}
      onClick={!disabled && onClick}
      disabled={disabled}
    >
      {children}
    </SelectedBorderWrapper>
  );
};

const UnselectedBorder = ({ onClick, disabled, children, id }: Props) => {
  const UnselectedBorderWrapper = styled(Border)<{ disabled: boolean }>`
    border: 1px solid ${Colors.ADDITIONAL_BLUE_GRAY};
    background-color: white;
    color: ${Colors.ADDITIONAL_SLATE};

    &:hover {
      background-color: ${props =>
        props.disabled ? 'white' : Colors.GRAY_SIX};
    }
    cursor: ${props => props.disabled && 'default'}
    opacity: ${props => props.disabled && '.4'};
  `;
  return (
    <UnselectedBorderWrapper
      id={id}
      onClick={!disabled && onClick}
      disabled={disabled}
    >
      {children}
    </UnselectedBorderWrapper>
  );
};

const Menu = styled(Styled.BorderBox)`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  min-width: 250px;
  max-height: 600px;
  overflow-y: scroll;
  top: 44px;
  padding: 20px 20px 20px 20px;
  z-index: 10;
`;

const Chevron = styled.div`
  position: relative;
  top: 3px;
  right: 3px;
`;

const CloseIcon = styled.div`
  width: 24px;
  height: 24px;

  &:hover {
    background-color: ${Colors.GRAY_SIX};
  }
`;

const MenuTitle = styled(Styled.FlexRow)`
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const SelectAll = styled.a`
  margin: 15px 0px 15px 0px;
  color: ${Colors.BLUE_TWO};
  ${TypeStyles.LABEL_TEXT};
`;

const OptionRow = styled(Styled.FlexRow).attrs({ as: 'a' })`
  align-items: center;
  margin: 10px 0px 0px 0px;
  height: 20px;
  white-space: nowrap;
`;

const Selected = styled(Styled.FlexRow)`
  margin-right: 8px;
  ${TypeStyles.LABEL_TEXT};
`;

const MenuComponents = {
  Border,
  SelectedBorder,
  UnselectedBorder,
  Menu,
  Chevron,
  CloseIcon,
  MenuTitle,
  SelectAll,
  OptionRow,
  Selected,
};

export default MenuComponents;
