import React from 'react';
import Styled from '../styles/Styles';
import styled from 'styled-components';

const ErrorPage = styled(Styled.FlexRow)`
  justify-content: center;
  padding-top: 100px;
`;

const Error = () => {
  return (
    <ErrorPage>
      Failed to fetch data from the API. Try{' '}
      <Styled.Reload onClick={() => window.location.reload()}>
        &nbsp;reloading&nbsp;
      </Styled.Reload>{' '}
      the page
    </ErrorPage>
  );
};

export default Error;
