import {
  toNoteResponse,
  toNoteTextResponse,
  toNoteTypeConfig,
} from '../util/convertToTypes';

import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import { NoteType } from '../types/Admin';
import React from 'react';
import Styled from '../styles/Styles';
import { mappingAxios } from '../util/api';
import { toast } from 'react-toastify';

export const fetchNotes = async (
  page: number,
  pageSize: number,
  ids?: string,
  noteAfter?: Date,
  noteBefore?: Date,
  typeList?: string,
  dashboard?: boolean,
  count?: number,
  navigate?: NavigateFunction
) => {
  try {
    const clinicPatientIds = JSON.parse(ids);
    const types = JSON.parse(typeList);
    const params = {
      page,
      pageSize,
      noteAfter,
      noteBefore,
      clinicPatientIds,
      types,
      getUnchanged: true,
    };
    const state =
      ((clinicPatientIds?.length && noteAfter && noteBefore) || dashboard) &&
      (await Functions.apiCall({
        route: '/notes',
        paramsOrData: params,
        navigate,
      }));
    return toNoteResponse(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'note.ts');
  }
};

export const fetchNoteTypes = async (navigate?: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({ route: '/notes/types', navigate });
    return state?.data;
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'note.ts');
  }
};

export const fetchNoteText = async (
  noteKeys: string,
  disableMappingService: boolean
) => {
  const keys = JSON.parse(noteKeys);
  const params = { noteKeys: keys };
  try {
    const state =
      !disableMappingService &&
      keys.length &&
      (await mappingAxios.get('/notes', {
        params,
      }));
    return state && toNoteTextResponse(state?.data);
  } catch (error) {
    toast.error(
      React.createElement(Styled.ErrorToast, {
        message: 'Oops! Something went wrong with the mapping service...',
      }),
      Styled.ToastProps
    );
    Log.error(error, 'note.ts');
  }
};

export const fetchNoteTypeConfig = async (navigate?: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({ route: '/notes/config', navigate });
    return toNoteTypeConfig(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'note.ts');
  }
};

export const saveNoteTypeConfig = async (
  types: NoteType[],
  navigate?: NavigateFunction
) => {
  const data = { configs: types };
  const state = await Functions.apiCall({
    route: '/notes/save_config',
    isPostRequest: true,
    paramsOrData: data,
    navigate,
  });
  return state;
};

export const handleSaveNoteTypeConfig = async (
  types: NoteType[],
  afterSave: () => void,
  navigate?: NavigateFunction
) => {
  try {
    await saveNoteTypeConfig(types, navigate);
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(`Note Types Saved: ${JSON.stringify(types)}`, 'note.ts');
    afterSave();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'note.ts');
  }
};

export const verifyNote = async (
  noteIds: string[],
  unverify?: boolean,
  navigate?: NavigateFunction
) => {
  const url = unverify ? '/notes/unverify' : '/notes/verify';
  const data = { noteIds };
  const state = await Functions.apiCall({
    route: url,
    isPostRequest: true,
    paramsOrData: data,
    navigate,
  });
  return state;
};

export const handleVerifyNote = async (
  notes: string[],
  afterVerify: () => void,
  unverify?: boolean,
  navigate?: NavigateFunction
) => {
  try {
    const res = await verifyNote(notes, unverify, navigate);
    toast.success(Styled.SuccessToast, Styled.ToastProps);
    Log.info(
      `${unverify ? 'Unverified' : 'Verified'} Notes: ${notes}`,
      'note.ts'
    );
    afterVerify();
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'note.ts');
  }
};
