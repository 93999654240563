import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const ZeroBubble = styled(Styled.Bubble)`
  color: ${Colors.GRAY_FOUR};
  border: 1px solid ${Colors.GRAY_SIX};
`;

type Props = {
  color?: string;
  number: number;
};

const ColorBubble = ({ color, number }: Props) => (
  <>
    {!number ? (
      <ZeroBubble>{number}</ZeroBubble>
    ) : (
      <Styled.Bubble style={{ minWidth: '22px' }} color={color}>
        {number}
      </Styled.Bubble>
    )}
  </>
);

export default ColorBubble;
