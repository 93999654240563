enum Colors {
  BLUE_ONE = '#033775',
  BLUE_TWO = '#058ED4',
  BLUE_THREE = '#0277A5',
  BLUE_FOUR = '#6AC2E5',
  GRAY_ONE = '#333333',
  GRAY_TWO = '#555555',
  GRAY_THREE = '#777777',
  GRAY_FOUR = '#999999',
  GRAY_FIVE = '#D6D7D8',
  GRAY_SIX = '#E8E8E8',
  GRAY_SEVEN = '#F5F5F5',
  GRAY_EIGHT = '#FAFAFA',
  ADDITIONAL_SLATE = '#617082',
  ADDITIONAL_BLUE_GRAY = '#9AAFC8',
  ADDITIONAL_LIGHT_BLUE = '#EDFAFF',
  PURPLE = '#DA70D6',
  RED = '#F05348',
  YELLOW = '#FFC81D',
  GREEN = '#6CBD2C',
  GRAY = '#808080',
  HOVER_PRIMARY = '#0474AE',
  HOVER_VERIFY = '#5A9D25',
  HOVER_SECONDARY = 'rgba(5,142,212,.15)',
  HOVER_TERTIARY = 'rgba(154,175,200,.2)',
  HOVER_UNVERIFY = 'rgba(108,189,44,.15)',
  HOVER_OUTLINE_WHITE = 'rgba(255,255,255,.2)',
  MOUSEOVER_BLUE = 'rgb(222,238,249)',
  WARNING = 'rgb(255,193,50)',
  REAL_TIME_ORANGE = 'rgb(194,86,38)',
  ORANGE_HOVER = '#95411D',
  DATA_INDICATOR_COLOR = 'rgb(0,136,0)',
}

export default Colors;
