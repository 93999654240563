import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, useNavigate } from 'react-router-dom';

import ActivityDetector from 'react-activity-detector';
import React from 'react';
import RouteComponentWrapper from './components/ui/RouteComponentWrapper';
import { ToastContainer } from 'react-toastify';
import { signOut } from './api/auth';
import styled from 'styled-components';

const Wrapper = styled.div`
  font: Open Sans;
`;

const App = () => {
  const customActivityEvents = ['click', 'keydown', 'mousemove'];

  return (
    <Wrapper>
      <Router>
        <ActivityDetector
          activityEvents={customActivityEvents}
          enabled={true}
          timeout={1800000}
          onIdle={() => {
            const navigate = useNavigate();
            signOut(navigate);
          }}
        />
        <RouteComponentWrapper />
      </Router>
      <ToastContainer />
    </Wrapper>
  );
};

export default App;
