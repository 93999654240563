import Colors from '../styles/Colors';
import Functions from '../util/UtilityFunctions';
import Options from '../util/Options';
import PriorityBubble from '../components/ui/PriorityBubble';
import React from 'react';
import Styled from '../styles/Styles';
import TypeStyles from '../styles/TypeStyles';
import { fetchActiveRules } from '../api/event';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const Modal = styled(Styled.Modal)`
  width: 50%;
  overflow-y: scroll;
  max-height: 80%;
  ${TypeStyles.HEADING_ONE}
`;

const Description = styled.div`
  font-weight: 300;
  margin-bottom: 30px;
  ${TypeStyles.LABEL_TEXT}
`;

const Title = styled(Styled.FlexRow)`
  margin-bottom: 30px;
  justify-content: space-between;
  position: sticky;
  top: -20px;
  background-color: white;
  width: 100%;
  z-index: 12;
  padding: 10px 0px 10px 0px;
  ${TypeStyles.HEADING_ONE};
`;

const CloseIcon = styled.div`
  cursor: pointer;
  height: 26px;

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

const RulesCheckedRow = styled(Styled.FlexColumn)`
  align-items: left;
  position: relative;
  bottom: 10px;
`;

const LastUpdated = styled.div`
  font-style: italic;
  font-weight: 500;
  ${TypeStyles.LABEL_TEXT}
`;

const { ruleOptions } = Options;

type Props = {
  onCloseClick?: () => void;
};

const RulesChecked = ({ onCloseClick }: Props) => {
  const navigate = useNavigate();
  const { data: status } = usePromise(fetchActiveRules, [navigate]);
  const activeRules = status?.rules;
  const { lastUpdated } = Functions.getLastUpdated(status?.lastUpdate);

  return (
    <>
      <Styled.ModalOverlay>
        <Modal>
          <Title>
            Checking Rules{' '}
            <CloseIcon onClick={onCloseClick}>
              <Styled.CloseIcon />
            </CloseIcon>
          </Title>
          {activeRules
            ?.sort((a, b) => a.priority - b.priority)
            .map((rule) => (
              <Styled.FlexRow>
                <PriorityBubble priority={rule.priority} />
                <RulesCheckedRow>
                  {ruleOptions[rule.name]?.name}
                  <Description>
                    {ruleOptions[rule.name]?.description}
                  </Description>
                </RulesCheckedRow>
              </Styled.FlexRow>
            ))}
          <LastUpdated>{lastUpdated}</LastUpdated>
        </Modal>
      </Styled.ModalOverlay>
    </>
  );
};

export default RulesChecked;
