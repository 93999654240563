import {
  toCouchShiftOptions,
  toCouchShiftPlotResponse,
  toOverridePercentiles,
  toOverrideRates,
} from '../util/convertToTypes';

import Functions from '../util/UtilityFunctions';
import Log from '../Log';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import { toast } from 'react-toastify';

export const fetchOverrideRates = async (
  machines: string,
  param: string,
  navigate: NavigateFunction
) => {
  try {
    const machineNames = machines && JSON.parse(machines);
    // there were issues with infinite renders when machines were sent in as an array of strings, so it's stringified and then parsed on the other side
    const params = { machineNames, param };
    const state =
      machineNames?.length &&
      (await Functions.apiCall({
        route: '/machine_override_rates',
        paramsOrData: params,
        navigate,
      }));
    return toOverrideRates(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'insight.ts');
  }
};

export const fetchOverridePercentiles = async (
  param: string,
  navigate: NavigateFunction
) => {
  try {
    const params = { param };
    const state = await Functions.apiCall({
      route: '/machine_override_rates/percentiles',
      paramsOrData: params,
      navigate,
    });
    return toOverridePercentiles(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'insight.ts');
  }
};

export const fetchCouchShiftOptions = async (navigate: NavigateFunction) => {
  try {
    const state = await Functions.apiCall({
      route: '/couch_shifts/hospitals_sites',
      navigate,
    });
    return toCouchShiftOptions(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'insight.ts');
  }
};

export const fetchCouchShift = async (
  param: string,
  site: string,
  navigate: NavigateFunction
) => {
  try {
    const params = { param, site };
    const state =
      param &&
      site &&
      (await Functions.apiCall({
        route: '/couch_shifts',
        paramsOrData: params,
        navigate,
      }));
    return toCouchShiftPlotResponse(state?.data);
  } catch (error) {
    toast.error(Styled.ErrorToast, Styled.ToastProps);
    Log.error(error, 'insight.ts');
  }
};
