import React from 'react';

import Colors from '../styles/Colors';
import Styled from '../styles/Styles';
import TypeStyles from '../styles/TypeStyles';
import { fetchUserClinics } from '../api/auth';
import routes from '../routes';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../util/api';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Border = styled(Styled.FlexColumn)`
  box-sizing: border-box;
  border: 1px solid ${Colors.GRAY_SIX};
  display: flex;
  flex-direction: column;
  width: 500px;
  justify-content: center;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
`;

const Title = styled(Styled.FlexRow)`
  color: white;
  height: 80px;
  align-items: center;
  background-color: ${Colors.BLUE_ONE};
  justify-content: center;
  ${TypeStyles.HEADING_ONE}
`;

const Row = styled(Styled.FlexRow)`
  justify-content: center;
  white-space: pre-line;
  margin-top: 20px;
`;

const Entry = styled(Styled.FlexColumn)`
  margin: 0px 0px 20px 0px;
  align-items: center;
`;

const ClinicSelector = () => {
  const navigate = useNavigate();
  const { data: userClinics } = usePromise(fetchUserClinics, []);

  // TODO - OR if length is one (no additional clinics besides default)
  if (!userClinics?.length) {
    navigate(routes.home);
  }

  const makeSelection = (clinicId: number, clinicName: string) => {
    localStorage.setItem('userClinicId', String(clinicId));
    localStorage.setItem('userClinicName', clinicName);
    navigate(routes.home);
  };

  return (
    <Wrapper>
      <Border>
        <Title>Select Clinic</Title>
        <Entry>
          {userClinics?.map((c: any) => (
            <Row>
              <Styled.ButtonBox
                style={{ 'margin-left': '0px' }}
                onClick={() => {
                  makeSelection(c.clinicId, c.clinicName);
                }}
              >
                {c.clinicName}
              </Styled.ButtonBox>
            </Row>
          ))}
        </Entry>
      </Border>
    </Wrapper>
  );
};

export default ClinicSelector;
