import React from 'react';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import ConfigOptionCard from '../admin/ConfigOptionCard';

const OptionBar = styled(Styled.FlexColumn)`
border-right: 1px solid ${Colors.GRAY_FIVE}
height: 100vh;
`;

type Props = {
  options: string[];
  activeTab: string;
  setActiveTab: (option: string) => void;
};

const SettingsColumn = ({ options, activeTab, setActiveTab }: Props) => {
  return (
    <OptionBar>
      {options.map(option => (
        <ConfigOptionCard
          optionName={option}
          isActive={activeTab === option}
          onClick={setActiveTab}
        />
      ))}
    </OptionBar>
  );
};

export default SettingsColumn;
