import { percentileToColor, percentileToText } from '../../pages/Insight';

import { OverridePercentiles } from '../../types/Insight';
import React from 'react';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Container = styled(Styled.FlexColumn)`
  position: relative;
  top: 30px;
  z-index: 9;
  ${TypeStyles.LABEL_TEXT}
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${props => props.color || 'white'};
  width: 20px;
  height: 20px;
`;

const LegendRow = styled(Styled.FlexRow)`
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
`;

const LegendSection = styled(Styled.FlexColumn)`
  margin-bottom: 30px;
`;

type Props = {
  nameToColor: { [key: string]: string };
  machines: string[];
  percentileData: OverridePercentiles;
};

const InsightLegend = ({ nameToColor, machines, percentileData }: Props) => {
  return (
    <Container>
      <LegendSection>
        National Baseline (Percentile)
        {Object.keys(percentileToColor).map(percentile => (
          <LegendRow
            style={{
              color: `${percentileToColor[percentile]}`,
              width: '150px',
            }}
          >
            <Styled.FlexRow>&ndash; &ndash; &ndash;</Styled.FlexRow>
            <Styled.FlexRow style={{ color: 'black' }}>
              &nbsp;
              {`${percentileToText[percentile]} (${Number(
                percentileData?.[percentile]?.toFixed(2)
              )}%)`}
            </Styled.FlexRow>{' '}
          </LegendRow>
        ))}
      </LegendSection>
      <LegendSection>
        {Object.keys(nameToColor).map(
          name =>
            machines?.includes(name) && (
              <LegendRow>
                <Circle color={nameToColor[name]} />
                &nbsp;
                {name}
              </LegendRow>
            )
        )}
      </LegendSection>
    </Container>
  );
};

export default InsightLegend;
