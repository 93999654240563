import ChecklistRow from '../checklist/ChecklistRow';
import Colors from '../../styles/Colors';
import { FinalPatient } from '../../types/Checklist';
import { InstitutionsMenu } from '../ui/RadioMenu';
import { PatientInfo } from '../../types/PatientInfo';
import React from 'react';
import ShowingText from '../ui/ShowingText';
import SortArrow from '../ui/SortArrow';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const Border = styled(Styled.CardBorder)`
  width: 850px;
  margin-left: 20px;
  max-height: 710px;
  overflow: auto;
`;

const TitleText = styled.div`
  margin: 20px 0px 0px 20px;
  ${TypeStyles.CARD_TITLE_TEXT}
`;

const FilterRow = styled(Styled.FlexRow)`
  margin: 20px;
`;

const Divider = styled.div`
  position: relative;
  top: 2px;
  margin: 0px 8px 0px 3px;
`;

type Props = {
  hospitalOptions?: { value: number; label: string }[];
  initialOption?: number;
  handleHospitalChange?: (checked: number) => void;
  totalPatients?: number;
  pageNumber?: number;
  maxPageNumber?: number;
  setPageNumber?: (num: number) => void;
  handlePageLengthChange?: (num: number) => void;
  pageLength?: number;
  refresh?: () => void;
  sortDescending?: boolean;
  setSortDescending?: (sortDescending: boolean) => void;
  patients?: FinalPatient[];
  patientInfo?: PatientInfo;
  patientDataLoading?: boolean;
  final?: boolean;
};

const Checklist = ({
  hospitalOptions,
  initialOption,
  handleHospitalChange,
  totalPatients,
  pageNumber,
  maxPageNumber,
  setPageNumber,
  handlePageLengthChange,
  pageLength,
  refresh,
  sortDescending,
  setSortDescending,
  patients,
  patientInfo,
  patientDataLoading,
  final,
}: Props) => {
  const minPatientNumber: number = pageNumber * pageLength + 1;
  const maxPatientNumber: number = Math.min(
    totalPatients,
    pageLength * (pageNumber + 1)
  );
  return (
    <Border>
      <TitleText>{final ? 'FINAL' : 'WEEKLY'} CHECK</TitleText>
      <FilterRow>
        {hospitalOptions && (
          <InstitutionsMenu
            options={hospitalOptions}
            onChange={handleHospitalChange}
            initialFilter={initialOption}
          />
        )}
        <ShowingText
          minNumber={minPatientNumber}
          maxNumber={maxPatientNumber}
          totalNumber={totalPatients}
          pageNumber={pageNumber}
          maxPageNumber={maxPageNumber}
          onLeftClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
          onRightClick={() =>
            pageNumber < maxPageNumber && setPageNumber(pageNumber + 1)
          }
          handlePageLengthChange={handlePageLengthChange}
          pageLength={pageLength}
          checklist
        />
        <Divider>
          <Styled.Divider />
        </Divider>
        <Styled.RefreshBorder onClick={() => refresh()}>
          <Styled.RefreshIcon />
        </Styled.RefreshBorder>
      </FilterRow>
      <Styled.TitleRow>
        <Styled.TitleRowType theme={{ flexBasis: '30px' }} />
        <Styled.TitleRowType theme={{ flexBasis: '270px' }}>
          NAME&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '100px' }}>
          MRN&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType theme={{ flexBasis: '230px' }}>
          RADIATION SUMMARY&nbsp;
        </Styled.TitleRowType>
        <Styled.TitleRowType
          onClick={() => setSortDescending(!sortDescending)}
          theme={{ flexBasis: '150px' }}
        >
          {final ? 'FINAL TREATMENT' : 'TREATMENT STATUS'}&nbsp;
          <SortArrow sortDescending={sortDescending} isShown />
        </Styled.TitleRowType>
      </Styled.TitleRow>
      <Styled.Line color={Colors.ADDITIONAL_BLUE_GRAY} />
      {patients?.map(patient => (
        <ChecklistRow
          patient={patient}
          patientInfo={patientInfo?.[patient.clinicPatientId]}
        />
      ))}
      {patientDataLoading && (
        <Styled.CardOverlayBox>
          <Styled.Spinner />
        </Styled.CardOverlayBox>
      )}
    </Border>
  );
};

export default Checklist;
