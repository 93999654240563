import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ImagingData, PlotData } from '../../types/Event';
import React, { useState } from 'react';

import ChartDateFilter from './ChartDateFilter';
import Colors from '../../styles/Colors';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import Functions from '../../util/UtilityFunctions';
import ImagingLegend from '../ui/ImagingLegend';
import Legend from '../ui/Legend';
import Options from '../../util/Options';
import Styled from '../../styles/Styles';
import moment from 'moment';
import styled from 'styled-components';

const PlotToggle = styled.div`
  width: 180px;
`;

const { imagingOptions } = Options;

type Props = {
  plotData: (PlotData | ImagingData)[];
  treatmentParameter?: string;
  eventDate?: string;
  isCouchShift?: boolean;
  onShiftToggleClick?: (initialToggles: string[] | null) => void;
  initialToggles?: string[];
};

const Chart = ({
  plotData,
  treatmentParameter,
  eventDate,
  isCouchShift,
  onShiftToggleClick,
  initialToggles,
}: Props) => {
  // separate out plotting data from image data, get data types from plotData
  const plottingData: PlotData[] = plotData.filter(
    set => set.param !== 'Image'
  ) as PlotData[];
  const imageData: ImagingData = plotData.find(
    set => set.param === 'Image'
  ) as ImagingData;
  const dataTypes = plottingData.map(dataSet => dataSet.param);
  const couchPositionTypes = [
    'Couch Lateral',
    'Couch Vertical',
    'Couch Longitudinal',
  ];
  const couch = couchPositionTypes.includes(dataTypes[0]);
  const showImages = couch || dataTypes[0] === 'Couch Rotation';

  const xPoints = plotData['0'].data.map(point =>
    Functions.dateToDate(point.treatmentTime)
  );
  const imageDataByFraction =
    imageData &&
    Object.fromEntries(
      imageData.data.map(fraction => [fraction.fraction, fraction.status])
    );
  // build data object to be used in the recharts component
  let data = xPoints.map((point, index) => {
    const obj = { name: xPoints[index] };
    if (showImages) {
      obj['Image Approval Status'] =
        imageDataByFraction?.[plottingData[0].data[index].fraction];
    }
    plottingData.forEach(dataSet => {
      const key = dataSet.param;
      if (!dataSet.data[index].missingImage) {
        const { value } = dataSet.data[index];
        obj[key] = value;

        dataSet.data[index].points.forEach(beam => {
          const beamKey = `${key} ${beam.beams.join(', ')}`;
          const beamValue = beam.value;
          obj[beamKey] = beamValue;
        });
      }
    });
    return obj;
  });

  const couchTypes = {
    couch_lat: 'Couch Lateral',
    couch_vrt: 'Couch Vertical',
    couch_lng: 'Couch Longitudinal',
  };
  const initialToggle = [couchTypes[treatmentParameter] || dataTypes[0]];
  const [toggle, setToggle] = useState<string[] | null>(initialToggles || null);
  // make toggle which is the array including all the data types that will be plotted
  const dataToggle: string[] = dataTypes.includes(toggle?.[0])
    ? toggle
    : initialToggle;

  // when clicking on a toggle, add the toggle if it's not selected and subtract it if it is selected (unless it is the only toggle selected)
  const onClick = (type: string) => {
    if (dataToggle.includes(type) && dataToggle.length > 1) {
      const newToggle = [...dataToggle];
      newToggle.splice(dataToggle.indexOf(type), 1);
      setToggle(newToggle);
    } else if (!dataToggle.includes(type)) {
      const newToggle = [...dataToggle];
      newToggle.push(type);
      setToggle(newToggle);
    }
  };

  // boolean for multiple plots to know when to switch to relative data set
  const multiplePlot: boolean = dataToggle.length > 1;

  // for each data type, calculate reference lines, color, and array of combined data points (for calculating range)
  const referenceLines = {};
  const nameToColor = {};
  const colors = [
    `${Colors.PURPLE}`,
    `${Colors.YELLOW}`,
    `${Colors.BLUE_ONE}`,
    `${Colors.BLUE_TWO}`,
  ];
  const dataPoints = {};
  dataTypes.forEach((type, index) => {
    referenceLines[type] = [
      plottingData[index].lowerThreshold,
      plottingData[index].upperThreshold,
    ];
    nameToColor[type] = colors[index];
    dataPoints[type] = [];
  });

  data.forEach(point =>
    Object.keys(point).forEach(key => {
      if (dataTypes.includes(key)) {
        dataPoints[key].push(point[key]);
      }
    })
  );

  // find set indices for each active data type, coalesce all beam values (for calculating range)
  const setIndex = dataToggle.map(toggle =>
    plottingData.findIndex(set => set.param === toggle)
  );
  const beamValues = setIndex.flatMap(index =>
    plottingData[index].data.flatMap(point =>
      point.points.map(beam => beam.value)
    )
  );

  // create array for time filter, logic for filtering out data points by time
  const [checked, setChecked] = useState([false, false, true]);
  const handleChange = (index: number) => {
    const newChecked = [false, false, false];
    newChecked[index] = true;
    setChecked(newChecked);
  };
  const timeFilter = [];
  plottingData[0].data.forEach(
    obj =>
      (checked[2] && timeFilter.push(obj.treatmentTime)) ||
      (checked[1] &&
        moment(new Date(obj.treatmentTime))
          .utc()
          .isAfter(
            moment(new Date())
              .utc()
              .subtract(1, 'months')
          ) &&
        timeFilter.push(obj.treatmentTime)) ||
      (checked[0] &&
        moment(new Date(obj.treatmentTime))
          .utc()
          .isAfter(
            moment(new Date())
              .utc()
              .subtract(1, 'weeks')
          ) &&
        timeFilter.push(obj.treatmentTime))
  );
  data = data.filter((point, index) =>
    timeFilter.includes(plottingData[0].data[index].treatmentTime)
  );

  // create array of all beam keys that are plotted. Using recharts, one line must be plotted for each beam key, with the line disabled to give the appearance of only adding dots for each beam
  const beamKeys: string[] = dataToggle.flatMap(toggle =>
    Array.from(
      new Set(
        data
          .flatMap(point => Object.keys(point))
          .filter(key => key.includes(toggle) && key !== toggle)
      )
    )
  );

  // create data set for multiple plots, where the data is transformed to the difference between each data point and the first data point of the set
  const dataKeys = data.reduce((array, point) => {
    Object.keys(point).forEach(key => {
      if (!array.includes(key)) {
        array.push(key);
      }
    });
    return array;
  }, []);
  const paramKeys = plottingData.map(dataSet => dataSet.param);
  const keyObj = paramKeys.reduce((obj, param) => {
    obj[param] = dataKeys?.filter(key => key.includes(param));
    return obj;
  }, {});
  const multiplePlotRelativeData = data.map(dataPoint => {
    const point = {
      name: dataPoint.name,
      'Image Approval Status': dataPoint['Image Approval Status'],
    };
    paramKeys.forEach(param => {
      keyObj[param].forEach(key => {
        if (dataPoint[key] !== undefined) {
          point[key] = Number((dataPoint[key] - data[0][param]).toFixed(2));
        }
      });
    });
    return point;
  });

  // create array of the key with the highest value for each point to determine which point to place imaging icon on
  const multiplePlotFilteredRelativeData = [];
  multiplePlotRelativeData.forEach(point => {
    const newPoint = {};
    Object.keys(point).forEach(key => {
      const flag = dataToggle.some(toggle => key.includes(toggle));
      if (flag) {
        newPoint[key] = point[key];
      }
    });
    multiplePlotFilteredRelativeData.push(newPoint);
  });
  const multiplePlotValues: number[][] = multiplePlotFilteredRelativeData.map(
    point => Object.values(point)
  );
  const multiplePlotKeys: string[][] = multiplePlotFilteredRelativeData.map(
    point => Object.keys(point)
  );
  const multiplePlotHighestValues = multiplePlotValues.map(point =>
    Math.max(...point)
  );
  const multiplePlotHighestKeys = Object.fromEntries(
    multiplePlotValues.map((values, index) => [
      multiplePlotRelativeData[index]['name'],
      multiplePlotKeys[index][
        values.findIndex(value => value === multiplePlotHighestValues[index])
      ],
    ])
  );

  // create customized dot component that includes imaging data, shows overrides in red if the point is not between the lowValue/highValue. Only one data type from the data toggle array will display imaging data
  const CustomizedDot = props => {
    const {
      cx,
      cy,
      stroke,
      value,
      lowValue,
      highValue,
      payload,
      dataKey,
    } = props;
    const imageApprovalStatus = payload['Image Approval Status'];
    const imageStatusIconColor = imagingOptions[imageApprovalStatus];
    const indicator =
      payload.name === eventDate &&
      dataKey.includes(couchTypes[treatmentParameter]);
    if (value < lowValue || value > highValue) {
      return (
        <svg>
          {dataKey?.includes(multiplePlotHighestKeys[payload.name]) && (
            <Styled.ImagingStatusIcon
              color={imageStatusIconColor}
              type={imageApprovalStatus}
              x={cx - 9}
              y={cy - 25}
            />
          )}
          <rect
            x={cx - 5}
            y={cy - 5}
            width="10"
            height="10"
            stroke="red"
            fill="red"
          />{' '}
          {indicator && (
            <g transform={`translate(${cx - 10},${cy + 2})`}>
              <path
                d="M320 192L0 576h192v256h256V576h192L320 192z"
                transform="scale(.03)"
                fill={Colors.DATA_INDICATOR_COLOR}
              />
            </g>
          )}
        </svg>
      );
    }

    return (
      <svg>
        {dataKey?.includes(multiplePlotHighestKeys[payload.name]) && (
          <Styled.ImagingStatusIcon
            color={imageStatusIconColor}
            type={imageApprovalStatus}
            x={cx - 9}
            y={cy - 25}
          />
        )}
        <rect
          x={cx - 5}
          y={cy - 5}
          width="10"
          height="10"
          stroke={stroke}
          fill={stroke}
        />{' '}
      </svg>
    );
  };

  const beamsPlotted = beamKeys.length !== 1;

  // create lowValue array and highValue array, to calculate the range
  const lowValue = dataToggle.map(toggle =>
    Math.min(...dataPoints[toggle], referenceLines[toggle][0], ...beamValues)
  );
  const highValue = dataToggle.map(toggle =>
    Math.max(...dataPoints[toggle], referenceLines[toggle][1], ...beamValues)
  );

  // create custom tooltip, if multiple plot is true then the tooltip will display the absolute data value as well as the relative data value
  const CustomTooltip = props => {
    if (!props.active || !props.payload?.length) {
      return null;
    }
    const dataPoint =
      props.relativeData &&
      data.find(point => point.name === props.payload[0].payload.name);

    const dataUnit = plottingData.filter(
      set => set.param === props.payload?.[0].dataKey
    )[0].unit;
    dataTypes.forEach(toggle => {
      if (
        dataPoint[`${toggle} ALL`] &&
        Number(dataPoint[`${toggle} ALL`]) === Number(dataPoint[toggle])
      ) {
        delete dataPoint[`${toggle} ALL`];
      }
    });
    const newPayload = multiplePlot
      ? [
          ...dataToggle.map(toggle => {
            return {
              ...props.payload[
                props.payload.findIndex(payload => payload.dataKey === toggle)
              ],
              unit: dataPoint[toggle] !== undefined ? dataUnit : '',
              payload: dataPoint,
              value: dataPoint[toggle],
            };
          }),
        ]
      : [...props.payload];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };
  // create custom dot for each data type, where the thresholds for determining override depends on the data type as well as the multiple plot variable
  const customDot = dataToggle.map(toggle => (
    <CustomizedDot
      lowValue={
        multiplePlot
          ? referenceLines[toggle][0] -
            plottingData.filter(set => set.param === toggle)[0].data[0].value
          : referenceLines[toggle][0]
      }
      highValue={
        multiplePlot
          ? referenceLines[toggle][1] -
            plottingData.filter(set => set.param === toggle)[0].data[0].value
          : referenceLines[toggle][1]
      }
      dataKey={toggle}
    />
  ));

  // create optimization arrays, the optimization array shows the optimization for each data type, the showOptimization array shows whether or not to display the optimization for each data type
  const optimization = dataToggle.map(toggle => {
    const set = plottingData.filter(obj => obj.param === toggle)[0];
    const optim = set.optimization;
    const oldCouchPosition = (set.upperThreshold + set.lowerThreshold) / 2;
    return { ...optim, oldCouchPosition };
  });
  const showOptimization = optimization.map(
    optimize =>
      optimize &&
      plottingData[0]?.data?.length > 2 &&
      optimize?.newOverrides < optimize?.oldOverrides
  );
  const paramToOptimizationText = {
    'Couch Lateral': 'lateral',
    'Couch Longitudinal': 'longitudinal',
    'Couch Vertical': 'vertical',
  };
  const optimizationText = dataToggle.map(
    toggle => paramToOptimizationText[toggle]
  );
  // calculate the range for the multiple plot
  const multiplePlotCombinedData = [];
  multiplePlotRelativeData.forEach(dataPoint =>
    dataToggle.forEach(toggle =>
      multiplePlotCombinedData.push(dataPoint[toggle])
    )
  );
  const lowYMultiplePlot = Math.min(...multiplePlotCombinedData);
  const highYMultiplePlot = Math.max(...multiplePlotCombinedData);

  return (
    <Styled.FlexColumn>
      <PlotToggle onClick={() => onShiftToggleClick(toggle)}>
        <Styled.EventDetailButton>
          {isCouchShift ? 'Switch to Couch Plot' : 'Switch to IGRT Shift Plot'}
        </Styled.EventDetailButton>
      </PlotToggle>
      <Styled.FlexRow
        style={{
          justifyContent: 'start',
          alignItems: 'flex-end',
          marginTop: '10px',
          width: '1080px',
          backgroundColor: 'white',
          position: 'relative',
          top: '12px',
          zIndex: 2,
        }}
      >
        <ChartDateFilter checked={checked} handleChange={handleChange} />
      </Styled.FlexRow>
      <Styled.FlexRow>
        <LineChart
          width={900}
          height={500}
          data={multiplePlot ? multiplePlotRelativeData : data}
          margin={{
            top: 20,
            right: 50,
            left: 5,
            bottom: 5,
          }}
          z-index={0}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            label={{
              value: `${
                dataToggle.length > 1
                  ? 'Multiple Positions'
                  : isCouchShift
                  ? `${dataToggle[0].replace('Couch ', '')} Shift`
                  : dataToggle[0]
              } (${plottingData[0].unit})`,
              angle: -90,
              position: 'insideLeft',
            }}
            domain={
              multiplePlot
                ? [lowYMultiplePlot - 3, highYMultiplePlot + 3]
                : [
                    isCouchShift ? 0 : Math.min(...lowValue) - 3,
                    Math.max(...highValue) + 3,
                  ]
            }
            tickFormatter={tick => parseFloat(tick.toFixed(4))}
          />
          <Tooltip
            content={<CustomTooltip relativeData={multiplePlotRelativeData} />}
          />
          {dataToggle.map((toggle, index) => (
            <Line
              type="monotone"
              name={couch ? Functions.abbreviateCouch(toggle) : toggle}
              dataKey={toggle}
              stroke={nameToColor[toggle]}
              dot={!beamsPlotted && customDot[index]}
              activeDot={false}
              isAnimationActive={false}
            />
          ))}
          {beamsPlotted &&
            beamKeys.map(key => (
              <Line
                type="monotone"
                name={couch ? Functions.abbreviateCouch(String(key)) : key}
                dataKey={key}
                stroke={
                  nameToColor[
                    dataToggle[
                      dataToggle.findIndex(toggle => key.includes(toggle))
                    ]
                  ]
                }
                strokeWidth={0}
                dot={
                  customDot[
                    dataToggle.findIndex(toggle => key.includes(toggle))
                  ]
                }
                activeDot={false}
                isAnimationActive={false}
              />
            ))}
          {!multiplePlot &&
            referenceLines[dataToggle[0]].map(value => (
              <ReferenceLine
                y={value}
                stroke={nameToColor[dataToggle[0]]}
                strokeDasharray="6 6"
                label={{
                  value: value,
                  position: 'right',
                  fill: `${nameToColor[dataToggle[0]]}`,
                }}
              />
            ))}
        </LineChart>
        {showImages && (
          <ImagingLegend
            nameToColor={nameToColor}
            isCouchShift={isCouchShift}
          />
        )}
      </Styled.FlexRow>
      <Legend
        dataTypes={dataTypes}
        nameToColor={nameToColor}
        onClick={onClick}
        checked={dataToggle}
        isCouchShift={isCouchShift}
      />
      {optimization.map((optim, index) => (
        <Styled.OptimizationText>
          {showOptimization[index] &&
            `*Changing ${optimizationText[index]} position from ${parseFloat(
              optim?.oldCouchPosition?.toFixed(2)
            )}cm to ${parseFloat(
              optim?.optimalCouchPosition?.toFixed(2)
            )}cm will reduce ${parseFloat(
              optim?.percentageReduced?.toFixed(2)
            )}% of ${optimizationText[index]} overrides (from ${
              optim?.oldOverrides
            } to ${optim?.newOverrides})`}
        </Styled.OptimizationText>
      ))}
    </Styled.FlexColumn>
  );
};

export default Chart;
