import { Route, Routes } from 'react-router-dom';

import Admin from '../../pages/Admin';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import React from 'react';
import Settings from '../../pages/Settings';
import Styled from '../../styles/Styles';
import Treatments from '../../pages/Treatments';
import routes from '../../routes';
import ClinicSelector from '../../pages/ClinicSelector';

type Props = {
  loading: boolean;
};

const RouteComponent = ({ loading }: Props) => {
  return loading ? (
    <Styled.OverlayBox>
      <Styled.Spinner />
    </Styled.OverlayBox>
  ) : (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.selectClinic} element={<ClinicSelector />} />
      <Route path={routes.home} element={<Home startTab={'dashboard'} />} />
      <Route path={routes.patients} element={<Home startTab={'patients'} />} />
      <Route
        path={routes.patientsUnverified}
        element={<Home startTab={'patients'} patientsUnverified={true} />}
      />{' '}
      <Route
        path={routes.patientsReadyForBilling}
        element={<Home startTab={'patients'} billing={true} />}
      />
      <Route path={routes.events} element={<Home startTab={'events'} />} />
      <Route
        path={routes.eventsMachine}
        element={<Home startTab={'events'} />}
      />
      <Route
        path={routes.eventsHighPriority}
        element={<Home startTab={'events'} highPriority />}
      />
      <Route
        path={routes.eventsUnverified}
        element={<Home startTab={'events'} eventsUnverified />}
      />
      <Route path={routes.realTime} element={<Home startTab={'realTime'} />} />
      <Route path={routes.insight} element={<Home startTab={'insight'} />} />
      <Route path={routes.settings} element={<Settings />} />
      <Route path={routes.treatments} element={<Treatments />} />
      <Route path={routes.treatmentsPatients} element={<Treatments />} />
      <Route path={routes.treatmentsPlot} element={<Treatments plot />} />
      <Route path={routes.admin} element={<Admin />} />
      <Route
        path={routes.finalChecklist}
        element={<Home startTab={'finalChecklist'} />}
      />
      <Route path={routes.notes} element={<Home startTab={'notes'} />} />
    </Routes>
  );
};

export default RouteComponent;
