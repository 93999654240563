export default {
  home: '/',

  patients: '/patients',

  patientsUnverified: '/patients/unverified',

  patientsReadyForBilling: '/patients/billing',

  events: '/events',

  eventsMachine: '/events/machine',

  eventsHighPriority: '/events/highPriority',

  eventsUnverified: '/events/unverified',

  treatments: '/treatments/:patientID/:rtPlanID/:eventID',

  treatmentsPatients: '/treatments/:patientID',

  treatmentsPlot: '/treatmentsPlot/:patientID/:rtPlanID/:eventID',

  admin: '/admin',

  realTime: '/realTime',

  insight: '/insight',

  settings: '/settings',

  finalChecklist: '/finalChecklist',

  notes: '/notes',

  login: '/login',

  selectClinic: '/selectClinic',
};
