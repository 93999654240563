import Functions from '../util/UtilityFunctions';
import { NavigateFunction } from 'react-router-dom';
import Styled from '../styles/Styles';
import { toGenerateReportResponse } from '../util/convertToTypes';
import { toast } from 'react-toastify';

export const generateReport = async (
  patientId: number,
  today: Date,
  navigate?: NavigateFunction
) => {
  const state = await Functions.apiCall({
    route: '/report/generate',
    isPostRequest: true,
    paramsOrData: { patientId, today },
    navigate,
  });
  const result = toGenerateReportResponse(state.data);
  if (result.canGenerate) {
    toast.success(
      Styled.SuccessToast({ message: 'generating report...' }),
      Styled.ToastProps
    );
  } else {
    toast.warn(
      Styled.ErrorToast({ message: result.message }),
      Styled.ToastProps
    );
  }
  return result;
};
