import Colors from '../../styles/Colors';
import React from 'react';
import Styled from '../../styles/Styles';
import styled from 'styled-components';

const Symbol = styled(Styled.FlexRow)`
  border-radius: 50%;
  margin-left: 30px;
  border: 1px solid ${Colors.GRAY_FIVE};
  width: 22px;
  height: 22px;
  color: white;
  background-color: ${(props) => props.color || 'white'};
  margin-right: 10px;
`;

const Option = styled(Styled.FlexRow)`
  cursor: pointer;
  margin-right: 5px;
`;

type Props = {
  dataTypes: string[];
  nameToColor: { [key: string]: string };
  onClick: (type: string) => void;
  checked: string[];
  isCouchShift?: boolean;
};

const Legend = ({
  dataTypes,
  nameToColor,
  onClick,
  checked,
  isCouchShift,
}: Props) => {
  return (
    <Styled.FlexRow style={{ marginLeft: '200px' }}>
      {dataTypes.map((type) => {
        const symbolColor = checked.includes(type)
          ? nameToColor[type]
          : 'white';
        return (
          <Option onClick={() => onClick(type)}>
            <Symbol color={symbolColor} />
            {isCouchShift ? `${type.replace('Couch ', '')} Shift` : type}
          </Option>
        );
      })}
    </Styled.FlexRow>
  );
};

export default Legend;
