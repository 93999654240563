import React, { useEffect, useState } from 'react';

// import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Auth } from '@aws-amplify/auth';
import Colors from '../styles/Colors';
import Log from '../Log';
import Styled from '../styles/Styles';
import TypeStyles from '../styles/TypeStyles';
import axios from '../util/api';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Border = styled(Styled.FlexColumn)`
  box-sizing: border-box;
  border: 1px solid ${Colors.GRAY_SIX};
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
`;

const Title = styled(Styled.FlexRow)`
  color: white;
  height: 80px;
  align-items: center;
  background-color: ${Colors.BLUE_ONE};
  justify-content: center;
  ${TypeStyles.HEADING_ONE}
`;

const Entry = styled(Styled.FlexColumn)`
  margin: 40px 0px 20px 0px;
  align-items: center;
`;

const ForgotPassword = styled.a`
  color: blue;
  text-decoration: underline;
  margin: 60px 0px 10px 10px;
`;

const SubmitButton = styled(Styled.FlexRow)`
  border: 1px solid ${Colors.BLUE_TWO};
  border-radius: 4px;
  background-color: ${Colors.BLUE_TWO};
  color: white;
  font-weight: 600;
  ${TypeStyles.LABEL_TEXT}
  cursor: pointer;
  height: 28px;
  width: 60px;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  margin: 10px;
  padding: 0px 2px 0px 2px;

  &:hover {
    background-color: ${Colors.HOVER_PRIMARY};
  }
`;

const Row = styled(Styled.FlexRow)`
  justify-content: center;
  white-space: pre-line;
`;

const BackArrow = styled(Styled.FlexRow)`
  cursor: pointer;
`;

const regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const createWorker = createWorkerFactory(() => import('./apiWorker.js'));

const Login = () => {
  // const worker = useWorker(createWorker);

  const initialIdp = localStorage.getItem('idp');
  const initialEmail = localStorage.getItem('email');

  const [loadingState, setLoading] = useState(false);
  const [email, setEmail] = useState(initialEmail || '');
  const [user, setUser] = useState<any>();
  const [passwordText, setPasswordText] = useState('');
  const [forgot, setForgot] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [password, setPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const onChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPasswordText(event.target.value);
  };
  const onCodeChange = (event) => {
    setCode(event.target.value);
  };

  const federatedLogin = async (idp: string) => {
    setLoading(true);
    localStorage.setItem('cognitoSignInStart', String(Date.now()));
    Auth.federatedSignIn({ customProvider: idp })
      .then((res) => {
        setLoading(false);
        setUser(res);
        localStorage.setItem('currentIdp', idp);
        localStorage.setItem('currentEmail', email);
        if (res) {
          setTimeout(() => navigate('/'), 500);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      })
      .finally(() => {
        localStorage.removeItem('cognitoSignInStart');
      });
  };
  const getIdp = () => {
    setLoading(true);
    axios
      .get('/get_idp', {
        headers: {
          'X-Requested-By': 'chart-alert',
        },
        params: { userEmail: email },
      })
      .then((res) => {
        setLoading(false);
        if (!res?.data?.['provider']) {
          setPassword(true);
        } else if (res?.data?.['provider']) {
          federatedLogin(res?.data?.['provider']);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      });
  };
  const login = () => {
    setLoading(true);
    localStorage.setItem('cognitoSignInStart', String(Date.now()));
    Auth.signIn(email, passwordText)
      .then((res) => {
        console.log(res);
        setLoading(false);
        localStorage.setItem('email', email);
        setUser(res);
        const NEW_PASSWORD_REQUIRED_CHALLENGE = 'NEW_PASSWORD_REQUIRED';
        if (res) {
          if (res.challengeName === NEW_PASSWORD_REQUIRED_CHALLENGE) {
            setFirstLogin(true);
            setPassword(false);
          } else {
            setTimeout(() => navigate('/'), 500);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      })
      .finally(() => {
        localStorage.removeItem('cognitoSignInStart');
      });
  };

  // useEffect(() => {
  //   if (user) {
  //     worker.onmessage(user?.signInUserSession?.idToken?.jwtToken);
  //   }
  // }, [user]);
  const handleFirstLogin = (newPassword: string) => {
    setLoading(true);
    console.log(user);
    Auth.completeNewPassword(user, newPassword)
      .then((result) => {
        setLoading(false);
        console.log(result);
        navigate('/');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      });
  };
  const onContinue = () => {
    if (email.match(regex)) {
      getIdp();
    } else setEmailError(true);
  };

  const [newForgotPassword, setNewForgotPassword] = useState('');
  const onNewForgotPassword = (event) => {
    setNewForgotPassword(event.target.value);
  };
  const [verifyForgotPassword, setVerifyForgotPassword] = useState('');
  const onVerifyForgotPassword = (event) => {
    setVerifyForgotPassword(event.target.value);
  };
  const forgotPasswordsMatch =
    !!newForgotPassword.length &&
    newForgotPassword === verifyForgotPassword &&
    !!code.length;
  const resetPasswordBegin = () => {
    setLoading(true);
    setForgot(true);
    setPassword(false);
    Auth.forgotPassword(email)
      .then((res) => {
        setLoading(false);
        console.log(res);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      });
  };
  const resetPasswordEnd = () => {
    setLoading(true);
    Auth.forgotPasswordSubmit(email, code, newForgotPassword)
      .then((res) => {
        setLoading(false);
        console.log(res);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          React.createElement(Styled.ErrorToast, {
            message: `${error}`,
          }),
          Styled.ToastProps
        );
        Log.error(error, 'Login.tsx');
      });
    setForgot(false);
  };

  const [newPassword, setNewPassword] = useState('');
  const onNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const [verifyNewPassword, setVerifyNewPassword] = useState('');
  const onVerifyNewPassword = (event) => {
    setVerifyNewPassword(event.target.value);
  };
  const newPasswordsMatch =
    !!newPassword.length && newPassword === verifyNewPassword;

  useEffect(() => {
    if (initialEmail && initialIdp) {
      federatedLogin(initialIdp);
    }
    if (initialEmail && !initialIdp) {
      setPassword(true);
    }
  }, []);

  const loading =
    loadingState ||
    Date.now() - Number(localStorage.getItem('cognitoSignInStart')) < 5000;

  return (
    <Wrapper>
      {loading && <Styled.Spinner />}
      {!forgot && !firstLogin && !password && !loading && (
        <Border>
          <Title>Login to Chartalert</Title>
          <Entry>
            <Row style={{ justifyContent: 'center' }}>
              Please enter your email to continue
            </Row>
            {emailError && (
              <>
                <Row style={{ justifyContent: 'center', color: 'red' }}>
                  Please enter a valid email
                </Row>
              </>
            )}
            <Row style={{ justifyContent: 'center' }}>
              <input
                value={email}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onContinue();
                  }
                }}
                onChange={onChange}
                size={45}
                placeholder={'Username'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
              />{' '}
            </Row>
          </Entry>
          <Row>
            <SubmitButton onClick={onContinue}>Continue</SubmitButton>
          </Row>
        </Border>
      )}
      {password && !loading && (
        <Border>
          <Title onClick={() => setForgot(!forgot)}>Login to Chartalert</Title>
          <BackArrow
            onClick={() => {
              setPassword(false);
            }}
          >
            <Styled.BackArrow />
          </BackArrow>
          <Entry>
            <Styled.FlexRow style={{ justifyContent: 'center' }}>
              {email}
            </Styled.FlexRow>
          </Entry>
          <Entry>
            <Row>Please enter your password to login</Row>
            <Row style={{ justifyContent: 'center' }}>
              <input
                size={45}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    login();
                  }
                }}
                value={passwordText}
                onChange={onPasswordChange}
                placeholder={'Password'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
                type="password"
              />{' '}
            </Row>
          </Entry>
          <Row>
            <SubmitButton
              onClick={() => {
                login();
              }}
            >
              Login
            </SubmitButton>
          </Row>
          <ForgotPassword onClick={() => resetPasswordBegin()}>
            Forgot your password?
          </ForgotPassword>{' '}
        </Border>
      )}
      {forgot && !loading && (
        <Border>
          <Title onClick={() => setForgot(!forgot)}>Login to Chartalert</Title>
          <Row>You should receive a code to your email.</Row>
          <Row>Type in the code here along with your new password.</Row>
          <Entry>
            <Row>Code</Row>
            <Row>
              <input
                size={45}
                value={code}
                onChange={onCodeChange}
                placeholder={'Code'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
              />{' '}
            </Row>
          </Entry>
          <Entry>
            <Row>
              {`
            8-character minimum length
            Contains at least 1 number
            Contains at least 1 special character
            Contains at least 1 uppercase letter
            Contains at least 1 lowercase letter`}
            </Row>
            <Row>
              <input
                size={45}
                value={newForgotPassword}
                onChange={onNewForgotPassword}
                placeholder={'New Password'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
                type="password"
              />{' '}
            </Row>
          </Entry>
          <Entry>
            <Row>Verify New Password</Row>
            {!forgotPasswordsMatch ? (
              <>
                <Row style={{ justifyContent: 'center', color: 'red' }}>
                  Passwords don't match or are not entered
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              <input
                size={45}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && forgotPasswordsMatch) {
                    resetPasswordEnd();
                  }
                }}
                value={verifyForgotPassword}
                onChange={onVerifyForgotPassword}
                placeholder={'Verify New Password'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
                type="password"
              />{' '}
            </Row>
          </Entry>
          <Row>
            {forgotPasswordsMatch ? (
              <SubmitButton
                onClick={() => {
                  resetPasswordEnd();
                }}
              >
                Submit
              </SubmitButton>
            ) : (
              <></>
            )}
          </Row>
        </Border>
      )}
      {firstLogin && !loading && (
        <Border>
          <Title onClick={() => setForgot(!forgot)}>Login to Chartalert</Title>
          <Row>This is your first time logging in.</Row>
          <Row>Please type in a new password.</Row>
          <Row>
            {`
            8-character minimum length
            Contains at least 1 number
            Contains at least 1 special character
            Contains at least 1 uppercase letter
            Contains at least 1 lowercase letter`}
          </Row>
          <Entry>
            <Row>New Password</Row>
            <Row>
              <input
                size={45}
                value={newPassword}
                onChange={onNewPassword}
                placeholder={'New Password'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
                type="password"
              />{' '}
            </Row>
          </Entry>
          <Entry>
            <Row>Verify New Password</Row>
            {!newPasswordsMatch ? (
              <>
                <Row style={{ justifyContent: 'center', color: 'red' }}>
                  Passwords don't match or are not entered
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              <input
                size={45}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && newPasswordsMatch) {
                    handleFirstLogin(newPassword);
                  }
                }}
                value={verifyNewPassword}
                onChange={onVerifyNewPassword}
                placeholder={'Verify New Password'}
                style={{
                  borderRadius: '4px',
                  border: `1px solid ${Colors.GRAY_FIVE}`,
                  fontSize: '14px',
                  lineHeight: '19px',
                  height: '34px',
                  fontFamily: 'Open Sans',
                }}
                type="password"
              />{' '}
            </Row>
          </Entry>
          <Row>
            {newPasswordsMatch && (
              <SubmitButton onClick={() => handleFirstLogin(newPassword)}>
                Submit
              </SubmitButton>
            )}
          </Row>
        </Border>
      )}
    </Wrapper>
  );
};

export default Login;
