import React, { useState } from 'react';

import Colors from '../../styles/Colors';
import Styled from '../../styles/Styles';
import TypeStyles from '../../styles/TypeStyles';
import styled from 'styled-components';

const HeaderRow = styled(Styled.FlexRow)`
  color: ${Colors.GRAY_ONE};
  justify-content: space-between;
  margin-bottom: 20px;
  ${TypeStyles.HEADING_ONE}
`;

const CloseIcon = styled.div`
  cursor: pointer;
  height: 26px;

  &:hover {
    background-color: ${Colors.HOVER_TERTIARY};
  }
`;

type Props = {
  currentComment: string;
  onCloseClick: () => void;
  onSaveClick: (comment: string) => void;
};

const CommentModal = ({ currentComment, onCloseClick, onSaveClick }: Props) => {
  const [value, setValue] = useState(currentComment);

  const handleChange = event => {
    setValue(event.target.value);
  };
  return (
    <Styled.ModalOverlay>
      <Styled.Modal>
        <HeaderRow>
          Comment
          <CloseIcon onClick={onCloseClick}>
            <Styled.CloseIcon />
          </CloseIcon>
        </HeaderRow>
        <textarea
          rows={4}
          cols={50}
          placeholder="Enter comment"
          value={value}
          onChange={handleChange}
          style={{
            height: '120px',
            width: '460px',
            boxSizing: 'border-box',
            padding: '10px 12px 10px 12px',
            resize: 'none',
            fontFamily: 'inherit',
            borderRadius: '4px 4px 4px 4px',
            border: `1px solid ${Colors.GRAY_FIVE}`,
            color: `${(value && Colors.GRAY_TWO) || Colors.GRAY_FOUR}`,
            outline: 'none',
          }}
        />
        <Styled.ButtonRow>
          <Styled.CancelButton onClick={onCloseClick}>
            Cancel
          </Styled.CancelButton>
          <Styled.SaveButton onClick={() => onSaveClick(value)}>
            Save
          </Styled.SaveButton>
        </Styled.ButtonRow>
      </Styled.Modal>
    </Styled.ModalOverlay>
  );
};

export default CommentModal;
