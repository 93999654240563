import Colors from '../../styles/Colors';
import React from 'react';
import styled from 'styled-components';

const OuterToggle = styled.div`
  background-color: ${props => props.color || Colors.GRAY_FIVE};
  width: 50px;
  height: 28px;
  border-radius: 14px;
  padding: 4px 4px 4px 4px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Switch = styled.div`
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  float: ${props => props.theme.float || 'none'};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type Props = {
  onClick: () => void;
  checked: boolean;
};

const ToggleSwitch = ({ onClick, checked }: Props) => {
  return (
    <>
      {checked ? (
        <OuterToggle onClick={onClick} color={Colors.GREEN}>
          <HiddenCheckbox checked={checked} />
          <Switch theme={{ float: 'right' }} />
        </OuterToggle>
      ) : (
        <OuterToggle onClick={onClick}>
          <HiddenCheckbox checked={checked} />
          <Switch />
        </OuterToggle>
      )}
    </>
  );
};

export default ToggleSwitch;
