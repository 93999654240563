import React, { useState } from 'react';
import Tooltip, { Position } from '../ui/Tooltip';

import ColorBubble from '../ui/ColorBubble';
import Colors from '../../styles/Colors';
import Functions from '../../util/UtilityFunctions';
import { Patient } from '../../types/Patient';
import { PatientNameAndMrn } from '../../types/PatientInfo';
import PriorityBubble from '../ui/PriorityBubble';
import Styled from '../../styles/Styles';
import { reverse } from 'named-urls';
import routes from '../../routes';
import styled from 'styled-components';

type Props = {
  patient: Patient;
  readyForBilling?: boolean;
  patientInfo?: PatientNameAndMrn;
  disableMappingService?: boolean;
  timeFilter?: number;
};

const CouchPlotButton = styled(Styled.ViewButton)`
  border: 1px solid ${Colors.REAL_TIME_ORANGE};
  background-color: ${Colors.REAL_TIME_ORANGE};
  &:hover {
    background-color: ${Colors.ORANGE_HOVER};
  }
`;

const position: Position = { top: '12px', left: '1px' };

const PatientRow = ({
  patient,
  readyForBilling,
  patientInfo,
  disableMappingService,
  timeFilter,
}: Props) => {
  const [isShown, setIsShown] = useState(false);
  const { 1: high, 2: medium, 3: low } = patient.unverifiedEvents;
  const [couchPlotButton, setCouchPlotButton] = useState(false);

  const paramString = new URLSearchParams();
  paramString.append('timeFilter', `${timeFilter}`);

  return (
    <Styled.StyledLink
      to={
        !couchPlotButton &&
        `${reverse(`${routes.treatmentsPatients}`, {
          patientID: patient.id,
        })}?${paramString.toString()}`
      }
    >
      <Styled.Row
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          minWidth: '1730px',
          justifyContent: 'left',
          cursor: 'pointer',
        }}
      >
        <Styled.ItemType theme={{ flexBasis: '100px' }}>
          <PriorityBubble priority={patient.priority}>
            {patient.priority}
          </PriorityBubble>
        </Styled.ItemType>
        <Styled.ItemType
          theme={{ flexBasis: '200px' }}
          style={{ wordBreak: 'break-all' }}
        >
          {disableMappingService
            ? `${patient.lastName}, ${patient.firstName}`
            : Functions.formatPatientName(patientInfo)}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '80px' }}>
          {disableMappingService
            ? patient.mrn
            : patientInfo
            ? patientInfo.mrn
            : '-'}
        </Styled.ItemType>
        <Styled.ItemType theme={{ flexBasis: '250px' }}>
          {patient.latestTreatment}
        </Styled.ItemType>
        {high + medium + low ? (
          <Styled.ItemType
            theme={{ flexShrink: '1' }}
            style={{ height: '30px', alignItems: 'center' }}
          >
            <ColorBubble color={Colors.RED} number={high} />
            <ColorBubble color={Colors.YELLOW} number={medium} />
            <ColorBubble color={Colors.GREEN} number={low} />
            {patient.hasOtherUnverifiedEvents && (
              <Tooltip position={position}>
                {' '}
                Unverified events outside of the time range
              </Tooltip>
            )}{' '}
          </Styled.ItemType>
        ) : (
          <Styled.ItemType>None</Styled.ItemType>
        )}
        {isShown && (
          <Styled.FlexRow>
            <Styled.FlexRow
              onMouseEnter={() => setCouchPlotButton(true)}
              onMouseLeave={() => setCouchPlotButton(false)}
            >
              <Styled.StyledLink
                to={reverse(`${routes.treatmentsPlot}`, {
                  patientID: patient.id,
                  rtPlanID: patient.latestPlanId,
                  eventID: patient.latestCouchEventId,
                })}
              >
                <CouchPlotButton>Couch Plot</CouchPlotButton>
              </Styled.StyledLink>
            </Styled.FlexRow>
          </Styled.FlexRow>
        )}
        {readyForBilling && (
          <>
            <Styled.CheckCircle />
            <Styled.MonetizationIcon />
          </>
        )}
      </Styled.Row>
    </Styled.StyledLink>
  );
};

export default PatientRow;
